import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from './home';
import About from './about';
import Blog from './blog';
import CAProjectPage from './project-colour-accessibility';
import ARProjectPage from './project-aeropress';
import SQProjectPage from './project-slider-quiz';
import HRProjectPage from './project-house-rules';
import HPProjectPage from './project-hotel-prices';
import PWProjectPage from './project-perfect-weekend';
import ATProjectPage from './project-action-time';
import SPProjectPage from './project-sony-pictures';
import BTProjectPage from './project-big-talk';
import BlogReferences from './blog-references';
import Blog20180827 from './blog-2018-08-27';
import Blog20190904 from './blog-2019-09-04';
import Blog20190910 from './blog-2019-09-10';
import Blog20190913 from './blog-2019-09-13';
import Blog20190924 from './blog-2019-09-24';
import Blog20190930 from './blog-2019-09-30';
import Blog20191006 from './blog-2019-10-06';
import Blog20191016 from './blog-2019-10-16';
import Blog20191103 from './blog-2019-11-03';
import Blog20191117 from './blog-2019-11-17';
import Blog20191202 from './blog-2019-12-02';
import Blog20191209 from './blog-2019-12-09';
import Blog20200129 from './blog-2020-01-29';
import Blog20200204 from './blog-2020-02-04';
import Blog20200216 from './blog-2020-02-16';
import Blog20200225 from './blog-2020-02-25';
import Blog20200306 from './blog-2020-03-06';
import FilterSelected from './selected';
import FilterOther from './other';




const Main = () => (
	<Switch>
		<Route exact path="/" component={Home} />
		<Route path="/about" component={About} />
		<Route path="/blog" component={Blog} />
		<Route path="/project-colour-accessibility" component= {CAProjectPage} />
		<Route path="/project-aeropress" component= {ARProjectPage} />
		<Route path="/project-slider-quiz" component= {SQProjectPage} />
		<Route path="/project-house-rules" component= {HRProjectPage} />
		<Route path="/project-hotel-prices" component= {HPProjectPage} />
		<Route path="/project-perfect-weekend" component= {PWProjectPage} />
		<Route path="/project-action-time" component= {ATProjectPage} />
		<Route path="/project-sony-pictures" component= {SPProjectPage} />
		<Route path="/project-big-talk" component= {BTProjectPage} />
		<Route path="/blog-references" component= {BlogReferences} />
		<Route path="/blog-2018-08-27" component= {Blog20180827} />
		<Route path="/blog-2019-09-04" component= {Blog20190904} />
		<Route path="/blog-2019-09-10" component= {Blog20190910} />
		<Route path="/blog-2019-09-13" component= {Blog20190913} />		
		<Route path="/blog-2019-09-24" component= {Blog20190924} />			
		<Route path="/blog-2019-09-30" component= {Blog20190930} />			
		<Route path="/blog-2019-10-06" component= {Blog20191006} />	
		<Route path="/blog-2019-10-16" component= {Blog20191016} />					
		<Route path="/blog-2019-11-03" component= {Blog20191103} />			
		<Route path="/blog-2019-11-17" component= {Blog20191117} />		
		<Route path="/blog-2019-12-02" component= {Blog20191202} />			
		<Route path="/blog-2019-12-09" component= {Blog20191209} />			
		<Route path="/blog-2020-01-29" component= {Blog20200129} />		
		<Route path="/blog-2020-02-04" component= {Blog20200204} />	
		<Route path="/blog-2020-02-16" component= {Blog20200216} />					
		<Route path="/blog-2020-02-25" component= {Blog20200225} />					
		<Route path="/blog-2020-03-06" component= {Blog20200306} />					
		<Route path="/selected" component={FilterSelected} />
		<Route path="/other" component={FilterOther} />
	</Switch>
)

export default Main;


