import React from 'react';
import { Component } from 'react';




class FooterLight extends Component {

			render () {

				return (
					<div id="footer-light">
						<div className="row">
							<h5 className="footer-column-left">Designed and built by <a href="https://github.com/madspesh" target="_blank" rel="noopener noreferrer">Rebecca Gill Clarke</a> using <a className="plain-link" href="https://reactjs.org/" target="_blank" rel="noopener noreferrer">React.js</a>. Last updated April 2020. &emsp;</h5>
							<h5 className="footer-column-right"><a href="https://www.linkedin.com/in/rebeccagillclarke" target="_blank" rel="noopener noreferrer">LinkedIn</a> / <a href="https://www.instagram.com/madspesh/" target="_blank" rel="noopener noreferrer">Instagram</a> / <a href="https://twitter.com/madspesh/" target="_blank" rel="noopener noreferrer">Twitter</a></h5>
						</div>
		        	</div>
	        	)
			}
		}


export default FooterLight;