import React from 'react';
import Blog20190913MagicLeap from '../img/blog_20190913_magicleap.jpg';
import Blog20190913Audience from '../img/blog_20190913_audience.jpg';








class Blog20190913 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scrolled: 0
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollProgress);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollProgress);
  }

  scrollProgress = () => {
    const scrollPx = document.documentElement.scrollTop;
    const winHeightPx =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrolled = `${scrollPx / winHeightPx * 100}%`;

    this.setState({
      scrolled: scrolled
    });
  };

  render() {
    const progressContainerStyle = {
      background: "transparent",
      height: "2px",
      position: "relative",
      top: 0,
      left: 0,
      width: "100vw",
      zIndex: 99
    };

    const progressBarStyle = {
      height: "2px",
      background: "#000000",
      width: this.state.scrolled
    };

    return (
      <div>
        <div className="project-title">
	        <div id="title">Audience + Community of Practice</div>
	        <div className="progress-container" style={progressContainerStyle}>
	          <div className="progress-bar" style={progressBarStyle} />
	        </div>
	    </div>
        <div id="project-page">
	        <div className="project-text padding-top-3em">
  	        <br />
  	        <br />
  	        	<h3>2019-09-13</h3>
          </div>
          <img className="padding-top-3em" src={Blog20190913Audience} alt="Chairs in an audience" /> 
          <div className="project-text padding-top-2em">
  		        <p className="padding-top-1em">These past few days have been spent finalising my <a href="https://docs.google.com/document/d/1OtifAdQDzeI1BQnSY8bVxgWMwmOwLeJCtNc592K8dv0/edit?usp=sharing" target="_blank" rel="noopener noreferrer">audience worksheet</a> and starting to look into my <a href="https://docs.google.com/document/d/1uKMPeLSM9QO6YrNbGywZM_yookm4WTpLtDW8llsElfw/edit?usp=sharing" target="_blank" rel="noopener noreferrer">community of practice</a>. I want to speak to past students who have done projects in the domain of public speaking, as well as people working in academia who are involved in speech and voice training. I actually found two professors within The New School, from Lang and the Mannes School of Music, so hopefully I'll be able to speak to them in person at some point. I also found some people working in XR for therapy, who would be able to give me some good tips on AR/VR techniques for anxiety.</p>
              <p className="padding-top-2em"> 
                I also got some advice about looking into other areas outside of public speaking such as HR and recruitment, and it got me thinking about a charity event that I used to volunteer at back in London called Crisis at Christmas. Every year around the holiday season, the Crisis centres hold a range of activities geared towards helping homeless people with shelter and comfort at Christmas. The first year I went I was an arts and crafts volunteer, but the second time I volunteered in the IT department which involved helping guests with writing resumes, sending emails, and other computer activities. Now that I'm thinking more about a specific audience, I'm wondering whether a product to help someone prepare for a job interview for example would be helpful. One thing I know is that it would need to be simple to use and relatively low tech, as the equipment at centres like this is usually very old.</p>
              <p className="padding-top-2em">          
                I got some good feedback from my presentation on Friday, and now I'm thinking about ways I can integrate the playful aspects of a game with a more serious method of treatment for public speaking anxiety. I went to the XReality Center at The New School last week, and this week I visited RLab in Brooklyn for my XR in the Real World class - it's given me lots of inspiration about things to experiment with. At the XReality Center I met Maya Georgieva (director of Digital Learning) and got the chance to try out the Magic Leap, which was amazing!</p>
          </div>
          <img className="padding-top-3em" src={Blog20190913MagicLeap} alt="Magic Leap equipment and glasses view of the Create UI" /> 
          <div className="project-text padding-top-2em">
              <p className="padding-top-1em">The program I used was called Create - I've added a screenshot above of how the UI menu looked from inside the glasses. The Magic Leap detected the floor and walls of the center, and I was able to place blocks with gravity which toppled over if nudged. I could also place scenes, stickers and moving objects such as fish and dinosaurs. There was the option to build pipes and connecting objects; I didn’t try that enough though. I could draw around the room using various types of pens with different textures. The controller is a handheld laser pointer which I used to interact with the UI. There were three inputs - a main trigger button to control the laser, another button to bring up submenus and make other selections, and a small button on top to exit/cancel things. I could walk around the drawings I made, seeing them from the front, side and back view.
              </p>
              <p className="padding-top-2em">I noticed that they had some VR programs available to try for the Oculus Gear/Go. One of them looked like it was a kind of interview prep program, so I definitely want to go back soon and check that out.  I know Samsung also have a suite of VR apps for Oculus that are geared towards public speaking, called <a href="https://www.oculus.com/deeplink/?action=view&path=app/1096173157163965&ref=oculus_desktop" target="_blank" rel="noopener noreferrer">#BeFearless</a>. Hopefuly on my next visit I can do some more research into these applications.
              </p>              
          </div>
	        <br />
	        <br />
	        <br />
	    </div>
      </div>
    )
  }
}


export default Blog20190913;







