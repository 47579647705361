import React from 'react';
import Blog20191209Storyboard1 from '../img/blog_20191209_storyboard1.jpg';
import Blog20191209Test from '../img/blog_20191209_test.jpg';
import Blog20191209Storyboard2 from '../img/blog_20191209_storyboard2.jpg';
import Blog20191209Storyboard3 from '../img/blog_20191209_storyboard3.jpg';









class Blog20191209 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scrolled: 0
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollProgress);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollProgress);
  }

  scrollProgress = () => {
    const scrollPx = document.documentElement.scrollTop;
    const winHeightPx =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrolled = `${scrollPx / winHeightPx * 100}%`;

    this.setState({
      scrolled: scrolled
    });
  };

  render() {
    const progressContainerStyle = {
      background: "transparent",
      height: "2px",
      position: "relative",
      top: 0,
      left: 0,
      width: "100vw",
      zIndex: 99
    };

    const progressBarStyle = {
      height: "2px",
      background: "#000000",
      width: this.state.scrolled
    };

    return (
      <div>
        <div className="project-title">
          <div id="title">Speech Analysis + Storyboards</div>
          <div className="progress-container" style={progressContainerStyle}>
            <div className="progress-bar" style={progressBarStyle} />
          </div>
      </div>
        <div id="project-page">
          <div className="project-text padding-top-3em">
            <br />
            <br />
              <h3>2019-12-09</h3>
              <p className="padding-top-1em">This week, we had our end of semester presentations. I spent some time sketching a storyboard and composing a user narrative to accompany the mid-fidelity designs that I was presenting. I also ran a couple of small experiments to test whether it would be helpful to provide my users with real-time feedback about their voice mid-practise, or whether it would be best to give them that information after they'd finished speaking.</p>
              <p className="padding-top-1em">Below is a recording of me demoing the two experiments I ran. Using p5.js, I built two simple interfaces which provide the user with feedback about their pitch (in hz) and their volume (as a decimal out of 1.0). In the real-time version, a simple circle shape grows and shrinks in size whilst speaking, depending on the user's pitch and volume. In the post-performance version, the pitch and volume data is plotted onto a graph, viewable after the user has stopped speaking. Both experiments use Javascript's built-in Speech Recognition functionality to ask the user the question 'Where do you see yourself in five years?', and to transcribe their response from speech to text.</p>
          </div>
          <iframe className="project-text-half padding-top-1em" src="https://player.vimeo.com/video/395861260" width="640" height="1038" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
          <iframe className="project-text-half padding-top-1em" src="https://player.vimeo.com/video/395861294" width="640" height="1038" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
          <div className="project-text-half padding-top-1em">
              <p className="padding-top-1em">I tested both experiments with four different users in order to see which form of feedback they preferred. The results were overwhelmingly in favour of post-performance feedback, although one person mentioned that they would appreciate some kind of real-time voice analysis in addition to post-performance as long as the design was less distracting.</p>
              <p className="padding-top-1em">For my higher fidelity prototyping after the Winter break, I'm thinking about testing a very minimal animation to simply show that the user is speaking, without providing any specific analysis which might distract them from their train of thought.</p>              
          </div>
          <img className="project-text-half padding-top-3em" src={Blog20191209Test} alt="Testing out the two experiments with a user" />
          <div className="project-text padding-top-1em">          
              <p className="padding-top-1em">In addition to these two experiments, I also decided to sketch out a basic storyboard to show how one of my user personas might interact with the Big Talk app. I thought that talking through some sort of visualisation might help with explaining my concept during the final presentation. Below is the storyboard and user narrative told from the perspective of Hannah, an aspiring flight attendant with an important phone interview next Thursday.</p>
          </div>
          <img className="padding-top-3em" src={Blog20191209Storyboard1} alt="Part 1 of a storyboard illustrating the user journey of one of my personas, Hannah" />
          <img className="padding-top-3em" src={Blog20191209Storyboard2} alt="Part 2 of a storyboard illustrating the user journey of one of my personas, Hannah" />
          <img className="padding-top-3em" src={Blog20191209Storyboard3} alt="Part 3 of a storyboard illustrating the user journey of one of my personas, Hannah" />
          <div className="project-text padding-top-1em">          
              <p className="padding-top-1em">The final presentation went really well and I received a lot of good and constructive feedback from both my professors and external critiques. I'll be going back to London for the Winter break but I'm excited to begin designing in high fidelity and integrating my designs into code, in order to have a fully functioning web application to show off during our end of year show. Happy holidays!</p>
          </div>     
          <br />
          <br />
          <br />
      </div>
      </div>
    )
  }
}


export default Blog20191209;







