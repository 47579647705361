import React from 'react';
import { Link } from 'react-router-dom';
import Blog20180827PaperPrototype from '../img/blog_20180827_paperprototype.jpg';
import Blog20190904MindMap from '../img/blog_20190904_mindmap.jpg';
import Blog20190910Storyboard from '../img/blog_20190910_storyboard.jpg';
import Blog20190913Audience from '../img/blog_20190913_audience.jpg';
import Blog20190924PhoneScreenshot from '../img/blog_20190924_phonescreenshot.jpg';
import Blog20190930TectonicLecture from '../img/blog_20190930_tectoniclecture.jpg';
import Blog20191006PulseSensor from '../img/blog_20191006_pulsesensor.jpg';
import Blog20191016Meetup from '../img/blog_20191016_meetup.jpg';
import Blog20191103PreparePrototype from '../img/blog_20191103_prepareprototype.jpg';
import Blog20191117CardType from '../img/blog_20191117_cardtype.jpg';
import Blog20191202Firebase from '../img/blog_20191202_firebase.png';
import Blog20191209Storyboard3 from '../img/blog_20191209_storyboard3.jpg';
import Blog20200129MidFiOverview from '../img/blog_20200129_midfioverview.png';
import Blog20200204Sketch from '../img/blog_20200204_sketch2.jpg';
import Blog20200216PersonaHannah from '../img/blog_20200216_personahannah.jpg';
import Blog20200225StyleTile1 from '../img/blog_20200225_styletile1.jpg';
import Blog20200305Intro3 from '../img/blog_20200306_intro3.jpg';











class Blog extends React.Component {
	render () {
		return (
			<div id="project-page">
	        	<div className="project-text">
			    	<div className="padding-top-2em">
				        <h2 className="padding-top-1em">MFA DT Thesis Studio</h2>
				        <p className="padding-top-05em">Blogging about my thesis journey, culminating in May 2020. Stay tuned for updates!</p>
				    </div>
				    <br />
				    <br />

				    <div className="padding-top-2em">
					    <Link to="/blog-2020-03-06">
					    <img className="blog-list-image" src={Blog20200305Intro3} alt="" />
					    <div className="padding-top-1em blog-list">
							<h3>2020-03-06 / Midterm Reviews + ProtoPie</h3>
							<p className="padding-top-05em">This week we had midterm reviews, where faculty went over our progress so far and offered a last round of feedback before the final slog. I’m currently a little stuck with my web prototype - I’m still trying to...</p>
					    </div>
					    </Link>
					</div>

				    <div className="padding-top-2em">
					    <Link to="/blog-2020-02-25">
					    <img className="blog-list-image" src={Blog20200225StyleTile1} alt="A slide from the midterm review presentation" />
					    <div className="padding-top-1em blog-list">
							<h3>2020-02-25 / User Testing Style Tiles</h3>
							<p className="padding-top-05em">Before jumping right into high fidelity designs, I first wanted to test a couple of polarising prototypes to see which visual direction would be best suited for the app. I created these two style tiles...</p>
					    </div>
					    </Link>
					</div>

				    <div className="padding-top-2em">
					    <Link to="/blog-2020-02-16">
					    <img className="blog-list-image" src={Blog20200216PersonaHannah} alt="The user journey for my persona Hannah" />
					    <div className="padding-top-1em blog-list">
							<h3>2020-02-16 / Personas + User Journeys</h3>
							<p className="padding-top-05em">Over the past few days, I've been working on developing several use cases for my web application. As public speaking is something that almost everybody has to do at some point in their lives, my target audience is...</p>
					    </div>
					    </Link>
					</div>

				    <div className="padding-top-2em">
					    <Link to="/blog-2020-02-04">
					    <img className="blog-list-image" src={Blog20200204Sketch} alt="Sketch of a projection mapping concept" />
					    <div className="padding-top-1em blog-list">
							<h3>2020-02-04 / Final Exhibition Form?</h3>
							<p className="padding-top-05em">Below are some sketches of ideas for my final exhibition form. Ideally, audiences visiting the exhibition will be able to access the beta version of the web app through their phones, but I'd also like there to be some sort of...</p>
					    </div>
					    </Link>
					</div>

				    <div className="padding-top-2em">
					    <Link to="/blog-2020-01-29">
					    <img className="blog-list-image" src={Blog20200129MidFiOverview} alt="Key screens from the mid fidelity design phase" />
					    <div className="padding-top-1em blog-list">
							<h3>2020-01-29 / Design Brief</h3>
							<p className="padding-top-05em">Happy New Year to everyone reading this! I feel like I haven’t updated this blog in forever, but this Wednesday was our first Thesis Studio II class after our very long Winter break. Over the holiday we were asked to write...</p>
					    </div>
					    </Link>
					</div>

				    <div className="padding-top-2em">
					    <Link to="/blog-2019-12-09">
					    <img className="blog-list-image" src={Blog20191209Storyboard3} alt="A glimpse of a storyboard" />
					    <div className="padding-top-1em blog-list">
							<h3>2019-12-09 / Speech Analysis + Storyboards</h3>
							<p className="padding-top-05em">This week, we had our end of semester presentations. I spent some time sketching a storyboard and composing a user narrative to accompany the mid-fidelity designs that I was presenting. I also ran a couple...</p>
					    </div>
					    </Link>
					</div>

				    <div className="padding-top-2em">
					    <Link to="/blog-2019-12-02">
					    <img className="blog-list-image" src={Blog20191202Firebase} alt="The Firebase console" />
					    <div className="padding-top-1em blog-list">
							<h3>2019-12-02 / Figuring out Firebase</h3>
							<p className="padding-top-05em">Last week was Thanksgiving and I had a friend visiting from London, so I didn’t get to do nearly as much work as I would have liked. I’ve also been struggling with the process of saving and retrieving data through Firebase...</p>
					    </div>
					    </Link>
					</div>

				    <div className="padding-top-2em">
					    <Link to="/blog-2019-11-17">
					    <img className="blog-list-image" src={Blog20191117CardType} alt="A screenshot from the hard-coded web app so far" />
					    <div className="padding-top-1em blog-list">
							<h3>2019-11-17 / Mid-Fidelity Design + Code</h3>
							<p className="padding-top-05em">With only four weeks left until the Thesis Pop Up show, I need to make a decision on what to make as a proof of concept prototype. Ideally I’d like to create a mobile app, but in the interest of time, I think a...</p>
					    </div>
					    </Link>
					</div>

				    <div className="padding-top-2em">
					    <Link to="/blog-2019-11-03">
					    <img className="blog-list-image" src={Blog20191103PreparePrototype} alt="One of my paper prototypes" />
					    <div className="padding-top-1em blog-list">
							<h3>2019-11-03 / 7 Prototypes in 7 Days</h3>
							<p className="padding-top-05em">This blog is all about my 7 in 7 assignment, in which we were asked to create a seven prototypes over the course of seven days. Each day’s prototype poses and answers a question concerning a particular assumption I have...</p>
					    </div>
					    </Link>
					</div>

				    <div className="padding-top-2em">
					    <Link to="/blog-2019-10-16">
					    <img className="blog-list-image" src={Blog20191016Meetup} alt="The WebXR Meetup at RLab" />
					    <div className="padding-top-1em blog-list">
							<h3>2019-10-16 / Midterm Presentations + WebXR</h3>
							<p className="padding-top-05em">Last week I presented my midterm thesis proposal to two guest critiques, my professors, and my classmates. I feel that my research was thorough but the actual presentation could have gone better; I was...</p>
					    </div>
					    </Link>
					</div>

				    <div className="padding-top-2em">
					    <Link to="/blog-2019-10-06">
					    <img className="blog-list-image" src={Blog20191006PulseSensor} alt="The Arduino pulse sensor" />
					    <div className="padding-top-1em blog-list">
							<h3>2019-10-06 / Fourth Technical Prototype</h3>
							<p className="padding-top-05em">Last Thursday I had to present an idea in front of two crit judges for one of my class projects. Myself and my team had completely changed the theme/slides of our presentation 30 mins before, and as a result...</p>
					    </div>
					    </Link>
					</div>

				    <div className="padding-top-2em">
					    <Link to="/blog-2019-09-30">
					    <img className="blog-list-image" src={Blog20190930TectonicLecture} alt="Tectonic Theater Project lecture at Cornell Tech" />
					    <div className="padding-top-1em blog-list">
							<h3>2019-09-30 / Questions + Challenges</h3>
							<p className="padding-top-05em">So there’s quite a lot to talk about in this blog! I continued on with my user interviews last week; so far, I’ve spoken to three people - one with a strong fear of public speaking, one with no fear whatsoever...</p>
					    </div>
					    </Link>
					</div>

				    <div className="padding-top-2em">
					    <Link to="/blog-2019-09-24">
					    <img className="blog-list-image" src={Blog20190924PhoneScreenshot} alt="A screenshot of the prototype built to my iPhone" />
					    <div className="padding-top-1em blog-list">
							<h3>2019-09-24 / Third Technical Prototype</h3>
							<p className="padding-top-05em">This weekend I made an AR prototype for Tuesday’s class using Unity and Vuforia. The concept for my AR feature is quite basic, but the technical side of it was more difficult to execute. It's still not fully...</p>
					    </div>
					    </Link>
					</div>

				    <div className="padding-top-2em">
					    <Link to="/blog-2019-09-13">
					    <img className="blog-list-image" src={Blog20190913Audience} alt="Chairs in an audience" />
					    <div className="padding-top-1em blog-list">
							<h3>2019-09-13 / Audience + Community of Practice</h3>
							<p className="padding-top-05em">These past few days have been spent finalising my audience worksheet and starting to look into my community of practice. I want to speak to past students who have done projects in the domain of public speaking...</p>
					    </div>
					    </Link>
				    </div>

				    <div className="padding-top-2em">
					    <Link to="/blog-2019-09-10">
					    <img className="blog-list-image" src={Blog20190910Storyboard} alt="My game storyboard" />
					    <div className="padding-top-1em blog-list">
							<h3>2019-09-10 / Second game prototype</h3>
							<p className="padding-top-05em">This past week, I've been thinking about things to prototype. Glossophobia seems like quite a specific topic to focus on, but in reality there are many ways to illustrate the subject; from service orientated products...</p>
					    </div>
					    </Link>
				    </div>

				    <div className="padding-top-2em">
					    <Link to="/blog-2019-09-04">
					    <img className="blog-list-image" src={Blog20190904MindMap} alt="My glossophobia mind map" />
					    <div className="padding-top-1em blog-list">
							<h3>2019-09-04 / Mind Mapping + Goal Setting</h3>
							<p className="padding-top-05em">Last week in class, I made a mind map around my central theme of glossophobia. The first level associations are based on themes of professional environments (work, school, etc.), social environments...</p>
					    </div>
					    </Link>
				    </div>

				    <div className="padding-top-2em">
					    <Link to="/blog-2018-08-27">
					    <img className="blog-list-image" src={Blog20180827PaperPrototype} alt="A paper prototype of the selfie mode" />
					    <div className="padding-top-1em blog-list">
							<h3>2019-08-27 / First paper prototype</h3>
							<p className="padding-top-05em">This first week of class was spent clarifying the area I want to concentrate on for my thesis. For our getting-to-know-you class presentation, I created this slide illustrating a few of my interests...</p>
					    </div>
					    </Link>
				    </div>

				    <div className="padding-top-2em">
					    <Link to="/blog-references">
					    <div className="padding-top-2em">
							<h3>References</h3>
							<p className="padding-top-1em">Art shows, movies, conversations with potential experts, books, articles, websites, etc.</p>
					    </div>
					    </Link>
				    </div>
				    <br />
        			<br />
        			<br />

				</div>
			</div>
		)
	}
}
export default Blog;