import React from "react";
import PropTypes from "prop-types";
import CCBath from '../img/climate_bath.gif';
import CCSofa from '../img/climate_sofa.gif';
import CCConferenceCall from '../img/climate_conferencecall.gif';
import CCBike from '../img/climate_bike.gif';
import CCWardrobe from '../img/climate_wardrobe.gif';
import CCWaterFood from '../img/climate_waterfood.gif';
import CCNest from '../img/climate_nest.gif';
import CCStandby from '../img/climate_standby.gif';
import CCFoodWaste from '../img/climate_foodwaste.gif';
import CCWashingMachine from '../img/climate_washingmachine.gif';






export default class Modal extends React.Component {
  onClose = e => {
    this.props.onClose && this.props.onClose(e);
    document.getElementById("backdrop").style.display = "none";
    document.getElementById("footer-light").style.display = "block";
  };
  render() {
    if (!this.props.show) {
      return null;
    }
    document.getElementById("backdrop").style.display = "block";
    document.getElementById("footer-light").style.display = "none";
    return (
      <div className="modal" id="modal">
        <div className="content">
        	<div className="project-text padding-top-2em">
        		<h2>Climate Change Week animation for @GoogleDE</h2>
            <a className="close" onClick={this.onClose}></a>
        	</div>
	        <div className="project-text">
		        <p className="padding-top-1em">This series of animated gifs was created for @GoogleDE social media to raise awareness around Climate Action Week in Germany. The animations illustrate various ways in which individuals can help fight climate change. As the motion designer, I worked on bringing the static visuals to life using After Effects. Designed for Google at Battenhall. Team: Rebecca Gill Clarke, Ashley Penney, Charlie Gregory.</p>
	        </div>
          <div className="padding-top-3em" style={{paddingLeft: 2.5 + "em", paddingRight: 2.5 + "em"}}>
  	        <img className="project-text-half padding-top-3em" style={{padding: 0}} src={CCBath} alt="..." />
            <img className="project-text-half padding-top-3em" style={{padding: 0}} src={CCSofa} alt="..." />
            <img className="project-text-half padding-top-3em" style={{padding: 0}} src={CCConferenceCall} alt="..." />
            <img className="project-text-half padding-top-3em" style={{padding: 0}} src={CCWashingMachine} alt="..." />
            <img className="project-text-half padding-top-3em" style={{padding: 0}} src={CCBike} alt="..." />
            <img className="project-text-half padding-top-3em" style={{padding: 0}} src={CCWaterFood} alt="..." />
            <img className="project-text-half padding-top-3em" style={{padding: 0}} src={CCWardrobe} alt="..." />            
            <img className="project-text-half padding-top-3em" style={{padding: 0}} src={CCNest} alt="..." />
            <img className="project-text-half padding-top-3em" style={{padding: 0}} src={CCStandby} alt="..." />
            <img className="project-text-half padding-top-3em" style={{padding: 0}} src={CCFoodWaste} alt="..." />
          </div>
	        <div className="project-text">
            <br />
        		<br />
        		<a className="button" onClick={this.onClose}>Close</a>
        	</div>
        </div>
        <br />
        <br />
      </div>
    );
  }
}
Modal.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired
};


