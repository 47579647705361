import React from 'react';










class BlogReferences extends React.Component {

  render() {

    return (
      <div>
        <div id="project-page">
		    <div className="project-text padding-top-2em">
            <div className="project-caption padding-top-1em">
              <h3>Thesis Papers</h3>
              <h4 className="padding-top-1em"><a href="https://dl.acm.org/citation.cfm?id=2702314" target="_blank" rel="noopener noreferrer">Augmenting social interactions: Realtime behavioural feedback using social signal processing techniques</a></h4>
              <h5 className="padding-top-05em">A 2015 thesis paper by Ionut Damian. Students developed a wearable device named Logue, which highlights the importance of both verbal and non-verbal communication by providing speakers with real-time feedback on speech rate, body energy and openness. Logue displays visual icons within the speaker's focal periphery via a see-through head mounted device. Uses a Kinect depth camera and microphone.</h5>
            </div>
            <div className="project-caption padding-top-2em">
              <h4><a href="https://dl.acm.org/citation.cfm?id=3090092" target="_blank" rel="noopener noreferrer">RoboCOP: A Robotic Coach for Oral Presentations</a></h4>
              <h5 className="padding-top-05em">A 2017 thesis paper by Ha Trinh. This public speaking aid takes the physical form of an anthropomorphic robot head, which acts as a coach who actively listens and provides feedback on content coverage, speaking rate, filler rate, pitch variety, and audience orientation (which is considered a proxy for eye contact). Uses a Kinect depth camera to track the speaker's head orientation, and IBM Watson automatic speech recognition to check whether content in the speaker's slides has been covered.</h5>
            </div>
            <div className="project-caption padding-top-2em">
              <h4><a href="https://dl.acm.org/citation.cfm?id=2701386" target="_blank" rel="noopener noreferrer">Rhema: A Real-Time In-Situ Intelligent Interface to Help People with Public Speaking</a></h4>
              <h5 className="padding-top-05em">A 2015 thesis paper by Iftekhar Tanveer. Similarly to Logue, this device offers real-time feedback through the display of instructional words (eg. 'louder', 'faster') in the speaker's focal periphery. It uses Google Glass to display the interface as well as record the speaker's audio, which is sent to a server for analysis and then returned to the Google Glass.</h5>
            </div>
            <div className="project-caption padding-top-2em">
              <h4><a href="https://dl.acm.org/citation.cfm?id=2856785" target="_blank" rel="noopener noreferrer">AutoManner: An Automated Interface for Making Public Speakers Aware of Their Mannerisms</a></h4>
              <h5 className="padding-top-05em">Another 2016 thesis paper by Iftekhar Tanveer. AutoManner is an interface which automatically extracts and visualises the repetitive patterns of the speaker’s movements during public speaking. It captures and analyses body language using a Kinect depth camera, and implements a subtle feedback technique that involves pinpointing frequent movements and asking questions about their meanings.</h5>
            </div>
            <div className="project-caption padding-top-2em">
              <h4><a href="https://dl.acm.org/citation.cfm?id=3173598" target="_blank" rel="noopener noreferrer">Awe the Audience: How the Narrative Trajectories Affect Audience Perception in Public Speaking</a></h4>
              <h5 className="padding-top-05em">Another 2018 thesis paper by Iftekhar Tanveer. This projects poses the question of whether a narrative trajectory exists in public speaking and if it has any impact on the audience ratings. Using transcripts of TED talks, the algorithm uses IBM Watson Tone Analyzer to extract sentence-wise 'scores' based on Emotion, Language, and Social aspects. These scored are then compared to ratings given by viewers with specific labels (e.g. Beautiful, Inspiring).</h5>
            </div>
            <div className="project-caption padding-top-2em">
              <h4><a href="https://dl.acm.org/citation.cfm?id=3332307" target="_blank" rel="noopener noreferrer">Influence of Interactive Questions on the Sense of Presence and Anxiety in a Virtual-reality Job-interview Simulation</a></h4>
              <h5 className="padding-top-05em">A 2019 thesis paper by Shotaro Shimizu. Uses virtual reality and exposure therapy to simulate a job interview environment. This VR system utilises voice synthesis and recognition, a dialogue knowledge base, and morphological analysis to generate new questions that include nouns from the user’s answers. Built with Unity and the Japanese open-source text segmentation library MeCab.</h5>
            </div>
            <div className="project-caption padding-top-2em">
              <h4><a href="https://dl.acm.org/citation.cfm?id=2493502" target="_blank" rel="noopener noreferrer">MACH: My Automated Conversation coacH</a></h4>
              <h5 className="padding-top-05em">A 2013 thesis paper by Mohammed (Ehsan) Hoque. This system uses Facial Expression Processing (built with the Shore framework and an Adaboost algorithm). It gives users embodied interaction and real-time feedback through communication with a humanlike virtual character, and post-interaction feedback on performance through a visualization interface (including a video recording of their interview).</h5>
            </div>



            <br />
            <div className="project-caption padding-top-3em">
              <h3>Thesis Projects</h3>
              <h4 className="padding-top-1em"><a href="https://www.courtney-snavely.com/empathyautomated" target="_blank" rel="noopener noreferrer">empathyAutomated</a></h4>
              <h5 className="padding-top-05em">A unique and humorous project from MFA DT alumni Courtney Snavely. Empathy Automated is a strategic intervention designed to critique the misuse of empathy in the technology industry and current trends in Artificial Intelligence. The components of the intervention revolve around Courtney's tech industry persona. The CTO and founder of AVITAS Technologies (Courtney) gives TED talks, has a reputable website and business cards, develops novel algorithms for augmenting AI with empathy, and is all completely fabricated.</h5>
            </div>
            <div className="project-caption padding-top-2em">
              <h4><a href="https://mfadt.parsons.edu/2019/page.html#68" target="_blank" rel="noopener noreferrer">Antitox</a></h4>
              <h5 className="padding-top-05em">An interesting AR take on the topic of public speaking from MFA DT alumni Young Tae Yoon. In additional to glossophobia, 'Antitox' also addresses the domain of scopophobia by covering the eyes and providing the wearer a safe, cocooned space, allowing them to speak confidently without the fear of eye contact. Yoon utilises ARKit to detect audience faces and display a script within the wearer’s viewpoint. Using a mirroring technique within the helmet, the wearer is able to read the script and face the audience whilst being protected from glossophobic and scopophobia-related anxiety.</h5>
            </div>
            <div className="project-caption padding-top-2em">
              <h4><a href="https://machinteraction.ur.rochester.edu/rocspeak/index.php" target="_blank" rel="noopener noreferrer">ROC Speak</a></h4>
              <h5 className="padding-top-05em">Another thesis project from public speaking expert Mohammed (Ehsan) Hoque and Raiyan Abdul Baten. Motivated by past work on <a href="https://dl.acm.org/citation.cfm?id=2493502" target="_blank" rel="noopener noreferrer">MACH: My Automated Conversation coacH</a>, the team deployed their system online for anyone to practice using a computer browser. Users can upload a video of their speaking and receive real feedback from the web community, as well as data feedback about their smile intensity, body movement, word content and voice modulation.</h5>
            </div>                           



            <br />
            <div className="project-caption padding-top-3em">
              <h3>Digital Apps</h3>
              <h4 className="padding-top-1em"><a href="https://www.speeko.co/" target="_blank" rel="noopener noreferrer">Speeko</a></h4>
              <h5 className="padding-top-05em">Speeko is the #1 app for public speaking. It allows you to record your voice and view a data visualisation on the pace, eloquence, pausing, intonation, articulation and power of your speech. You can also view more detailed data on your recording's speaking rate for example. You need a paid subscription to unlock other data. The app offers daily lessons on topics such as how to project your voice and how to set pace. It offers achievement badges depending on how much you use the app, and you can set daily reminders to practise.</h5>
            </div>
            <div className="project-caption padding-top-2em">
              <h4><a href="https://joyable.com/" target="_blank" rel="noopener noreferrer">Joyable</a></h4>
              <h5 className="padding-top-05em">Joyable is an app-based digital therapy innovator offering the first comprehensive mental health system designed to address individual needs, ensure access to the right support, and track outcomes rigorously. This is made possible by Joyable’s digital therapeutics, an app that’s as effective as face-to-face Cognitive Behavioural Therapy (CBT). Joyable works with leading employers to improve access through its proven solutions, and has achieved engagement rates that are 10x typical mental health solutions. To date, Joyable has reached more than 500,000 individuals suffering from anxiety or depression.</h5>
            </div>   
            <div className="project-caption padding-top-2em">
                <h4><a href="http://www.astoundthem.com/" target="_blank" rel="noopener noreferrer">Astound</a></h4>
                <h5 className="padding-top-05em">Astound is a voice & speech coaching iOS app that teaches you how to strengthen your voice and become the best communicator you can be. The UI is colourful and engaging with fun illustrations. The app provides 1 minute 'voice boosters' such as simple jaw exercises, 'direct goals' on topics such as articulation and pitch, and 'daily routines' for coffee breaks, commutes, etc. Most of the exercises are demonstrated through video content, but there is also the option to record your voice and view how your vocal pitch changes over time, as well as see how your tempo compares with famous speakers like Oprah and Steve Jobs.</h5>
            </div>                           
            <div className="project-caption padding-top-2em">
                <h4><a href="https://zombiesrungame.com/" target="_blank" rel="noopener noreferrer">Zombies, Run!</a></h4>
                <h5 className="padding-top-05em">Zombies, Run! is a unique running/fitness application which uses audio content and gamification to encourage physical exercise. Its elevator pitch is as follows: 'Walk, jog or run anywhere in the world. Hear your mission and music through your headphones. If you’re chased by zombies, you’ll have to speed up! You’ll automatically collect supplies to build up your base.'</h5>
            </div>  
            <div className="project-caption padding-top-2em">
                <h4><a href="https://help.rehearsal.pro/simple-five-step-beginners-tutorial/" target="_blank" rel="noopener noreferrer">Rehearsal Pro</a></h4>
                <h5 className="padding-top-05em">Rehearsal Pro is the #1 best-selling app for professional actors. It allows users to add and review PDF scripts in portrait or landscape mode, and highlight their own individual lines using their finger. When they tap record they'll hear three ADR beeps; after which the app will record their voice whilst scrolling through the script. The actor is prompted to record two versions of the scene to practice with; the first in which they say their own lines in a flat voice, and the second in which they simply mouth their own lines quietly. They will recite their scene partners lines in the same animated tone that they'd expect to hear in the actual performance. Rehearsal Pro will automatically sync both the actors' voice and their finger scroll movements, and save the file as a unified recording which the user can then name. In playback mode, users are advised to rehearse using the second recording where they mouthed their lines quietly. The recording will loop over and over again, allowing the user to scrub back and forth between different lines and/or practice continuously.</h5>
            </div>   
            


            <br />
            <div className="project-caption padding-top-3em">
			        <h3>Digital Training Programs</h3>
			        <h4 className="padding-top-1em"><a href="https://virtualspeech.com/courses/public-speaking" target="_blank" rel="noopener noreferrer">Virtual Speech</a></h4>
			        <h5 className="padding-top-05em">An online VR course aimed at helping people improve their public speaking skills. Requires a VR headset. Has interesting features such as a heatmap for eye focus when scanning the crowd. Multiple VR environments such as theatres, charity halls, etc.</h5>
		        </div>
            <div className="project-caption padding-top-2em">
              <h4><a href="https://www.ovationvr.com/" target="_blank" rel="noopener noreferrer">Ovation</a></h4>
              <h5 className="padding-top-05em">Ovation is another online VR program targeting public speaking. It offers a subscription model of $20 a month. It's advantage over other software is that users can record and play back their performance in both 3D and traditional video. This allows the user to see themselves from the position of their VR avatar as well as an audience member. 'Gaze'-related features include real-time analysis of attention distribution, triggering the user if they look at one side of the audience or a speech tool (eg. projector, note card or laptop) for too long. It also provides visual targets above audience members heads to casually guide the user's gaze.</h5>
            </div>              
            <div className="project-caption padding-top-2em">
              <h4><a href="https://www.limbix.com/" target="_blank" rel="noopener noreferrer">Limbix</a></h4>
              <h5 className="padding-top-05em">Limbix is a great VR and mental health-focused company geared towards treating anxiety, pain, depression, trauma, and addiction-related disorders. Their VR kits don't need internet access - the product is designed to be used in any environment that is convenient to the patient/provider. In the kit, the user receives a VR headset, a tablet to mirror and select VR content, and a charging/docking station. The VR library provides exposure-therapy based programs which providers can adjust to gradually increase their patient's tolerance.</h5>
            </div> 
            <div className="project-caption padding-top-2em">
              <h4><a href="http://www.limelight-vr.com/" target="_blank" rel="noopener noreferrer">Limelight VR</a></h4>
              <h5 className="padding-top-05em">Limelight is another VR program for public speaking, very similar to Ovation and Virtual Speech. One thing to note is that their UI features (selecting an environment, type of audience, time limit of speech, etc.) seem to be built into the scene rather than being just an overlay on screen - users can touch 3D buttons embedded in tables. They have integrated Google Slides into the program, as well as whiteboards you can actually use, which is useful.</h5>
            </div>             
            <div className="project-caption padding-top-2em">
              <h4><a href="https://www.oculus.com/deeplink/?action=view&path=app/1096173157163965&ref=oculus_desktop" target="_blank" rel="noopener noreferrer">#BeFearless / Launching People</a></h4>
              <h5 className="padding-top-05em">Samsung have a suite of free fear-related VR apps which can be used with the Oculus Gear and Go. The three public speaking apps are geared towards Personal Life (small talk, making suggestions, giving a toast, special speech, and singing at a karaoke), School Life (casual talk, class ice-breaking, opinion sharing, class presentation, and school conference) and Business Life (job interview, business lunch, team meeting, management presentation, and job fair). The apps are voice, gaze and heart-rate focused, with the ability to record your performance. The #BeFearless campaign motto states "At Samsung, we don't just see technology. We see people. The Launching People initiative is a global campaign created in 2013 to help unleash people’s full potential."</h5>
            </div> 
            <div className="project-caption padding-top-2em">
              <h4><a href="https://www.tryhealium.com" target="_blank" rel="noopener noreferrer">Healium</a></h4>
              <h5 className="padding-top-05em">Healium is the world's first virtual and augmented reality platform powered by brainwaves and heart rate via consumer wearables. Their patented technology allows the user to modify VR or AR content with biometric information. A headband worn on your forehead senses electrical activity in your brain, and/or your smart watch detects your heart rate and allows you to see those patterns represented inside virtual reality, mixed or augmented reality.</h5>
            </div> 
            <div className="project-caption padding-top-2em">
              <h4><a href="https://vitadmf.org/" target="_blank" rel="noopener noreferrer">ViTA DMF</a></h4>
              <h5 className="padding-top-05em">This unique product from The Dan Marino Foundation and the University of Southern California's Institute for Creative Technologies uses VR/AR technologies to help people on the autism spectrum secure employment. Inspired by Albert "Skip" Rizzo Ph.D's VR work with returning soldiers suffering from PTSD, ViTA DMF leverages more than 15 years of U.S. Army-funded research into the development of virtual humans for therapeutic and/or teaching applications. In the AR version of the program, ViTA DMF uses the Magic Leap to augment the users reality, placing a virtual interviewer in front of them at a real-life table. Initial results from pilot tests prove that 80% of study participants showed marked improvements in their interviewing skills, and 44 out of 72 acquired and maintained employment.</h5>
            </div> 



            <br />
            <div className="project-caption padding-top-3em">
              <h3>In Person Programs and Groups</h3>
              <h4 className="padding-top-1em"><a href="https://www.toastmasters.org/" target="_blank" rel="noopener noreferrer">Toastmasters</a></h4>
              <h5 className="padding-top-05em">Toastmasters International is a non-profit educational organization that teaches public speaking and leadership skills through a worldwide network of clubs. Headquartered in Englewood, Colo., the organization's membership exceeds 358,000 in more than 16,800 clubs in 143 countries. Since 1924, Toastmasters International has helped people from diverse backgrounds become more confident speakers, communicators, and leaders.</h5>
            </div>
            <div className="project-caption padding-top-2em">
                <h4><a href="https://www.tectonictheaterproject.org/" target="_blank" rel="noopener noreferrer">Tectonic Theater Project</a></h4>
                <h5 className="padding-top-05em">Based in New York City, Tectonic Theater Project develops new plays using the company’s trademarked theater-making method, Moment Work™, and through a rigorous process of research and collaboration in a laboratory environment. They also work with educational institutions such as Cornell Tech to improve student's public speaking skills.</h5>
            </div> 



            <br />
            <div className="project-caption padding-top-3em">
              <h3>Virtual Communication</h3>
              <h4 className="padding-top-1em"><a href="https://www.media.mit.edu/posts/a-few-simple-tips-for-better-online-meetings-covid-19-edition/" target="_blank" rel="noopener noreferrer">A few simple tips for better online meetings (COVID-19 edition)</a></h4>
              <h5 className="padding-top-05em">Tips from the MIT Media Lab on how to conduct meetings and seminars virtually. This is a helpful guide which gives users advice on navigating the role of a virtual presenter to an online audience. Although this article is mostly geared towards teaching, there are some notes than can be useful for everyone. The article also links to a live Google Doc which members of the MIT Media Lab community are updating.</h5>
            </div>
            <div className="project-caption padding-top-2em">
                <h4><a href="https://support.zoom.us/hc/en-us/articles/209743263-Meeting-and-Webinar-Best-Practices-and-Resources" target="_blank" rel="noopener noreferrer">Zoom Online Event Best Practices Guide</a></h4>
                <h5 className="padding-top-05em">This PDF guide from Zoom explains the best practices for conducting an online event. It features tips on aesthetics (ideally lighting should come from the front to avoid casting shadows on the presenters face) and eye contact (presenters should be looking at the webcam instead of the screen). The guide also offers advice on how to plan and prepare content for your event, and how to transition between content delivery, Q&A and final comments.</h5>
            </div> 
            <div className="project-caption padding-top-2em">
                <h4><a href="https://www.around.co/" target="_blank" rel="noopener noreferrer">Around</a></h4>
                <h5 className="padding-top-05em">Around is a new form of video conferencing that, unlike competitors such as Zoom, doesn't overpower the whole screen and lets you communicate with your teammates in a fluid way. It's technology manages to find your face even as you move, cropping out clutter and other background distractions. The application recognizes all common noises like sirens, barking dogs, washing machines, kitchen activity, laptop fans and applies AI-based filtering to suppress them while prioritizing human voice.</h5>
            </div> 



            <br />
            <div className="project-caption padding-top-3em">
              <h3>Film and Animation</h3>
              <h4 className="padding-top-1em"><a href="https://www.amazon.com/Speak-Caite-Upton/dp/B00FTU3ATE/ref=cm_cr_arp_d_product_top?ie=UTF8" target="_blank" rel="noopener noreferrer">SPEAK</a></h4>
              <h5 className="padding-top-05em">SPEAK follows six unforgettable characters as they prepare to compete for the Toastmasters title, "World Champion of Public Speaking." SPEAK is a powerful, inspiring documentary about conquering life's hurdles and finding your voice.</h5>
              <div className="project-caption padding-top-2em">
                <h4><a href="https://www.ted.com/playlists/226/before_public_speaking" target="_blank" rel="noopener noreferrer">TED: Before public speaking</a></h4>
                <h5 className="padding-top-05em">If you’ve got a presentation to give at work or school — or are perhaps getting ready to speak at a TEDx event? — here are a list of recommended video talks to help get you pumped up.</h5>
              </div>
            </div>
              <div className="project-caption padding-top-2em">
                <h4><a href="http://kapwa.co/work/ted-ed-the-science-of-stage-fright/" target="_blank" rel="noopener noreferrer">TED-Ed: The Science of Stage Fright</a></h4>
                <h5 className="padding-top-05em">A lovely animated short-film, directed and produced by Robertino Zambrano at KAPWA. For all of us that sweat, shake or disintegrate at the mere idea of public speaking, Mikael Cho explains the underlying physiological and evolutionary causes of stage fright, and tells us how to conquer them.</h5>
              </div>            



            <br />
            <div className="project-caption padding-top-3em">
              <h3>Websites and Articles</h3>
              <h4 className="padding-top-1em"><a href="https://www.ted.com/" target="_blank" rel="noopener noreferrer">TED</a></h4>
              <h5 className="padding-top-05em">TED is a nonprofit devoted to spreading ideas, usually in the form of short, powerful talks (18 minutes or less). TED began in 1984 as a conference where Technology, Entertainment and Design converged, and today covers almost all topics — from science to business to global issues — in more than 100 languages. Meanwhile, independently run TEDx events help share ideas in communities around the world.</h5>
            </div>
            <div className="project-caption padding-top-2em">
              <h4><a href="https://www.newyorker.com/culture/culture-desk/a-virtual-reality-program-to-conquer-the-fear-of-public-speaking" target="_blank" rel="noopener noreferrer">A Virtual Reality Program to Conquer The Fear of Public Speaking</a></h4>
              <h5 className="padding-top-05em">A humorous article from The New Yorker. It profiles Ovation, a virtual-reality public-speaking program which users can subscribe to for $20 a month. It's designer Jeff Marshall could be an expert to possibly contact? Also mentions other VR public speaking software such as Limbix, Now I Can Do Heights, Limelight and Personal Life.</h5>
            </div>              
            <div className="project-caption padding-top-2em">
              <h4><a href="https://blogs.scientificamerican.com/observations/virtual-reality-might-be-the-next-big-thing-for-mental-health/" target="_blank" rel="noopener noreferrer">Virtual Reality Might Be the Next Big Thing for Mental Health</a></h4>
              <h5 className="padding-top-05em">An article in the Scientific American about the use of VR therapy to treat not just PTSD but numerous other anxiety-related disorders. It also mentions the risks of self-guided VR therapy, noting that 'in a human-guided VR therapy session, therapists monitor a patient’s heart rate, respiration and other vital signs. They can modify scenes or cut the session short if a patient becomes too anxious. AI therapists may not respond as well to these tricky situations.'</h5>
            </div>  



            <br />
            <div className="project-caption padding-top-3em">
              <h3>Exhibitions and Installations</h3>
              <h4 className="padding-top-1em"><a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5510200/" target="_blank" rel="noopener noreferrer">Nacadia Therapy Forest Garden</a></h4>
              <h5 className="padding-top-05em">Seen at the Cooper Hewitt in September 2019. Nacadia is an active research- and therapy-based garden that promotes mental health rehabilitation for patients, specifically individuals living with post-traumatic stress disorder. Designed by landscape architect Ulrika Stigsdotter, with input from researchers in medicine, psychology, and architecture, the 2-acre garden is open year-round and contains 5 distinct outdoor rooms with fluctuating content that relates to a patient’s needs and abilities. Viewers at the Cooper Hewitt are able to scroll around the 360-degree experience on a tablet.</h5>
            </div>
            <div className="project-caption padding-top-2em">
              <h4><a href="https://www.daisyginsberg.com/work/the-substitute" target="_blank" rel="noopener noreferrer">The Substitute</a></h4>
              <h5 className="padding-top-05em">Seen at the Cooper Hewitt in September 2019. Designed by Alexandra Daisy Ginsberg and The Mill studio. Ginsberg 'brings back to life' a nearly extinct rhino using data generated by artificial intelligence, to ask the question “what errors in reproduction may arise as we recreate life artificially?” Confined to a virtual room, as the rhino habituates to its environment it’s form and sound toggle from pixelated to lifelike — reminding us that this rhino, coming to life without its natural context, is entirely artificial.</h5>
            </div>            
            <div className="project-caption padding-top-2em">
              <h4><a href="https://www.media.mit.edu/publications/paint-with-your-voice-an-interactive-sonic-installation/" target="_blank" rel="noopener noreferrer">STIMMMALER: Paint With Your Voice</a></h4>
              <h5 className="padding-top-05em">An MIT Media Lab and Institute for Media Design (Munich) project created in 2014. An interactive, sonic installation that lets visitors paint pictures with their own voice. Through speaking into an augmented megaphone and moving it, visitors control a digital paintbrush that paints onto a projected canvas. Depending on both pitch and volume of the visitor’s voice, the paintbrush changes its stroke and color. Once the painting is finished, a printout is produced as a tangible artifact that completes and transcends the visitor’s interactive experience.</h5>
            </div> 
            <div className="project-caption padding-top-2em">
              <h4><a href="https://www.ideo.com/blog/what-the-ai-products-of-tomorrow-might-look-like" target="_blank" rel="noopener noreferrer">Empathy Writer</a></h4>
              <h5 className="padding-top-05em">This is an interesting speculative design project from IDEO which imagines what the AI products of tomorrow will look like. The Empathy Writer helps you convey what you mean in a way that will be easily understood. Using the character trait tiles, I describe myself and you, then the machine helps me compose a message in a way that reflects who you are. Empathy is amplified.</h5>
            </div> 



            <br />
            <div className="project-caption padding-top-3em">
              <h3>Physical Objects</h3>
              <h4 className="padding-top-1em"><a href="https://dl.acm.org/citation.cfm?id=2890633" target="_blank" rel="noopener noreferrer">AwareMe: Addressing Fear of Public Speech through Awareness</a></h4>
              <h5 className="padding-top-05em">A proof of concept prototype for a handheld public speaking device, outlined in this 2016 thesis paper by Mark Bubel. Explains the full research, design and user testing process of creating a physical object to use whilst speaking. It uses visual colour and haptic vibrations to provide feedback on speaker's voice pitch, filler words, and words per minute.</h5>
            </div>
            <div className="project-caption padding-top-2em">
              <h4><a href="https://www.voiceblox.co.uk/" target="_blank" rel="noopener noreferrer">Voice Blox</a></h4>
              <h5 className="padding-top-05em">An multimodal learning tool which helps users understand the tonal variations of the Mandarin language through touch. It's tactile interface embodies a user's characteristic vocal frequency in a physical shape, enabling them to see, feel and hear exactly how they sound.</h5>
            </div>            
            <div className="project-caption padding-top-2em">
              <h4><a href="http://www.oscardelahera.com/solace" target="_blank" rel="noopener noreferrer">Solace</a></h4>
              <h5 className="padding-top-05em">This interactive, audio visual experience was designed as part of Oscar de la Hera's Master Thesis: Finding North. He designed and developed a system composed of an iPhone app, a wearable, an arduino-powered bluetooth light bulb and a 3D printed lamp with the purpose of transforming breath into light. He explored meditation and resonant breathing — taking one breath approximately every ten seconds — which has shown to greatly decrease depression, stress and anxiety.</h5>
            </div>  


            <br />
            <div className="project-caption padding-top-3em">
              <h3>Books</h3>
              <h4 className="padding-top-1em"><a href="https://www.amazon.com/TED-Talks-Official-Public-Speaking/dp/1328710289/ref=mt_paperback?tag=teco06-20&_encoding=UTF8" target="_blank" rel="noopener noreferrer">TED Talks: The Official TED Guide to Public Speaking</a></h4>
              <h5 className="padding-top-05em">By Chris Anderson, curator of TED. For anyone who has ever been inspired by a TED Talk… this is an insider’s guide to creating talks that are unforgettable.</h5>
            </div>
            <div className="project-caption padding-top-2em">
              <h4><a href="https://www.amazon.com/Moment-Work-Tectonic-Projects-Devising/dp/1101971770" target="_blank" rel="noopener noreferrer">Moment Work: Tectonic Theater Project's Process of Devising Theater</a></h4>
              <h5 className="padding-top-05em">By Moisés Kaufman and Barbara Pitts McAdams with Leigh Fondakowski, Andy Paris, Greg Pierotti, Kelli Simpkins, Jimmy Maize, and Scott Barrow. For more than two decades, the members of Tectonic Theater Project have been rigorously experimenting with the process of theatrical creation. Here they set forth a detailed manual of their devising method and a thorough chronicle of how they wrote some of their best-known works. This book is for all theater artists—actors, writers, designers, and directors—who wish to create work that embraces the unbridled potential of the stage.</h5>
            </div>             
            <br />
            <br />
            <br />
		    </div>
      	</div>
      </div>
    )
  }
}


export default BlogReferences;







