import React from 'react';
import { Link } from 'react-router-dom';
import CADesktopImage from '../img/colouraccessibility_desktop.jpg';
import CADesktopVideo from '../img/colouraccessibility_desktop.mp4';
import CATabletVideo from '../img/colouraccessibility_tablet.mp4';
import CAMobile from '../img/colouraccessibility_mobile.jpg';
import CASuccessfulAPI from '../img/colouraccessibility_successfulapi.gif';
import CABrokenButtons from '../img/colouraccessibility_brokenbuttons.gif';
import CAUnbind from '../img/colouraccessibility_unbindhighlighted.jpg';
import CAFigma from '../img/colouraccessibility_figmadesign.jpg';
import CASetTimeOut from '../img/colouraccessibility_settimeout.jpg';
import CASentimentMessage from '../img/colouraccessibility_sentimentmessage.jpg';










class CAProjectPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scrolled: 0
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollProgress);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollProgress);
  }

  scrollProgress = () => {
    const scrollPx = document.documentElement.scrollTop;
    const winHeightPx =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrolled = `${scrollPx / winHeightPx * 100}%`;

    this.setState({
      scrolled: scrolled
    });
  };

  render() {
    const progressContainerStyle = {
      background: "transparent",
      height: "2px",
      position: "relative",
      top: 0,
      left: 0,
      width: "100vw",
      zIndex: 99
    };

    const progressBarStyle = {
      height: "2px",
      background: "#000000",
      width: this.state.scrolled
    };

    return (
      <div>
      	<div className="project-title">
	        <div id="title">A JavaScript Game About Colour Accessibility</div>
	        <Link to="/"><div className="close-project"></div></Link>
	        <div className="progress-container" style={progressContainerStyle}>
	          <div className="progress-bar" style={progressBarStyle} />
	        </div>
	    </div>
        <div id="project-page">
	    	<div className="project-text padding-top-2em">
		        <h1 className="padding-top-1em">How might we raise awareness about colour accessibility in the digital design community?</h1>
		    </div>
		    <div className="project-text padding-top-2em">
		        <div className="project-caption-half padding-top-1em">
			        <h3>Context</h3>
			        <h5 className="padding-top-1em">A responsive web game played in the browser, designed and developed in 2019 during the Design and Technology: Web Advanced program at Parsons.</h5>
		        </div
		        ><div className="project-caption-half padding-top-1em">
			        <h3>Role</h3>
			        <h5 className="padding-top-1em">UI design and front-end development (JavaScript, CSS and HTML). Accessibility information is pulled from the <a href="https://webaim.org/resources/contrastchecker/" target="_blank" rel="noopener noreferrer">WebAIM Color Contrast Checker API</a>.</h5>
		        </div>
		    </div>
		    <video className="padding-top-3em" playsinline="true" webkit-playsinline="true" autoPlay loop muted>
    			<source src={CADesktopVideo} alt="A screen recording of the game on desktop" type='video/mp4' />
			</video>		    
		    <div className="project-text padding-top-3em">
		        <h2>Why build a game?</h2>
		        <p className="padding-top-1em">Interactive browser games such as <a href="https://cantunsee.space/" target="_blank" rel="noopener noreferrer">Can’t Unsee</a> and <a href="http://pixact.ly/" target="_blank" rel="noopener noreferrer">Pixact.ly</a> are fun, easy to play, and have the potential to go viral amongst digital design communities. Using sites like these as my precedents, I wanted to create a similarly playful experience but with an underlying message; the importance of colour contrast in web accessibility.</p>
		        <p className="padding-top-1em">I also wanted to try my hand at building something a bit trickier using JavaScript than I had tried previously. My initial idea was to just create a website educating users about the issue, but this would have relied mostly on HTML and CSS. Building a game with multiple functions would be more challenging and rewarding.</p>
	        </div>
	        <img className="padding-top-3em" src={CAMobile} alt="Screenshots of the mobile view" />
	        <div className="project-text padding-top-3em">
		        <h2>Colour and contrast</h2>
		        <p className="padding-top-1em">Web accessibility is a huge topic to tackle. It encompasses all disabilities that affect access to the web, including auditory, cognitive, neurological, physical, speech and visual. I decided to concentrate on the visual issue of adequate colour contrast, which is vital to accessibility as all web users should be able to perceive content on the page.</p>
		        <p className="padding-top-1em">The <a href="https://www.w3.org/TR/WCAG20/" target="_blank" rel="noopener noreferrer">Web Content Accessibility Guidelines 2.0</a> measure the difference in perceived "luminance" or brightness between two colors, and provide web content creators with a minimum contrast ratio for legible text. Accessibility is measured by two levels of conformance; AA (average) and AA (above average). To meet the minimum level AA, small text below 18pt must have a ratio of at least 4.5 to 1, and large text above 18pt must have a ratio of at least 3.0 to 1.</p>
	        </div>
	        <img className="padding-top-3em" src={CADesktopImage} alt="Screenshot of the desktop view" />
	        <div className="project-text padding-top-3em">
		        <h2>The big idea</h2>
		        <p className="padding-top-1em">I decided to design and build a quiz game application for fellow creatives to guess whether a particular colour combination’s contrast ratio is a pass or a fail. Pass/fail results of foreground text and background colours are easily accessed through the public WebAIM Color Contrast Checker API. This game has 10 combinations in total, which are loaded into the game via an external JSON file.</p>
		        <p className="padding-top-1em">Users receive feedback after each combination on whether their guess was successful, and at the end of the quiz they receive their final score out of 10 along with a customised message. An integrated scoreboard and timer enhance the gamified experience, and social media sharing links at the end encourage users to spread awareness of the game and issue.</p>
	        </div>
	        <div className="project-text padding-top-3em">
		        <h2>Testing the API</h2>
		        <p className="padding-top-1em">After outlining the concept, I began testing the functionality with a dummy question in order to get the API connected and functioning correctly. My test code below is asking the WebAIM API whether each colour combination passes or fails the accessibility test. The code is then checking the pass/fail result against the users yes/no guess, and either displaying a “Correct!” message or a “Wrong” message. In this test, the background and text colours are hard-coded into my JS file.</p>
	        </div>
	        <img className="padding-top-3em" src={CASuccessfulAPI} alt="Using developer tools to test the functionality" />	        
	        <div className="project-text padding-top-3em">
		        <h2>The learning curve</h2>
		        <p className="padding-top-1em">The next step was to compile multiple question variables - each with their own background and text colour properties - into an external JSON file. My initial plan was to load each question into a div in my HTML file, and use CSS to remove and display each div one by one. However, I had a lot of difficulty in getting the JS checking code to work for multiple questions.</p>
	        </div>
	        <img className="padding-top-3em" src={CABrokenButtons} alt="Screenshot of the console log" />	        
	        <div className="project-text padding-top-3em">
		        <p>I did a lot of debugging and realised the code was displaying the correct message for questions 1 and 2, but advancing to question 3 only displayed the message from the previous question. The code for question 3 definitely worked as the console log showed that it had executed, but the message was being overwritten by question 2. I needed to find a way to execute the code one step at a time only.</p>
	        </div>
	        <div className="project-text padding-top-3em">
		        <h2>My 'Aha!' moment</h2>
		        <p className="padding-top-1em">The biggest revelation I had during this project was my discovery of the 'unbind' method. The reason why my most recent code kept getting overwritten by previous functions was because I was attaching all my click events to the same two buttons. I had to unbind the click event from the Agree and Disagree button IDs after each loadQuestion() function had run, in order to start afresh each time.</p>
	        </div>
	        <img className="padding-top-3em" src={CAUnbind} alt="Screenshot of the unbind method in Sublime" />	  		        
	        <div className="project-text padding-top-3em">
		        <h2>Putting the pieces together</h2>
		        <p className="padding-top-1em">I designed the visual interface of my game using Figma, ensuring that the UI was colourful, engaging, and above all, accessible! Using Figma helped me to structure my CSS code quickly and easily, as it automatically provides you with CSS properties in the sidebar which you can quickly paste into your style sheet.</p>
	        </div>
	        <img className="padding-top-3em" src={CAFigma} alt="Artboards in Figma" />	        
	        <div className="project-text padding-top-3em">
		        <p>To introduce the website I designed an introductory panel which explained what the game was about, and gave users a brief introduction to the colour contrast accessibility topic. At the end of the game I provided links for the user to learn more about the official web accessibility guidelines.</p>
		        <p className="padding-top-1em">In order to increase the gamified aspect of the experience, I integrated a scoreboard and timer by utilising the 'setTimeout' method. This allowed me to display a Correct/Incorrect for a certain period of time, and then advance the user on to the next question.</p>		        
	        </div>	        
	        <img className="padding-top-3em" src={CASetTimeOut} alt="Screenshot of the setTimeout method in Sublime" />
		    <video className="padding-top-3em" playsinline="true" webkit-playsinline="true" autoPlay loop muted>
    			<source src={CATabletVideo} alt="A screen recording of the game on tablet" type='video/mp4' />
			</video>
	        <div className="project-text padding-top-3em">
		        <p>I also upped the game-like intensity by adding a timebar, which would start out as green at 0 seconds and then eventually switch to red as the time was approaching 5 seconds. I used the 'animate' method to increase the CSS width of the bar as time increased.</p>
		        <p className="padding-top-1em">Finally, I added a customised sentiment message at the end of the quiz depending on the user's final score. Users that scored 10 out of 10 would receive a 'perfect score!' message, whilst users that scored less than 1 received a 'did you even try?!' message.</p>	
	        </div>
	        <img className="padding-top-3em" src={CASentimentMessage} alt="Screenshot of the sentiment if/else statements in Sublime" />	        
	        <div className="project-text padding-top-3em">
		        <h2>Future iterations</h2>
		        <p className="padding-top-1em">My next steps are to sort out a bug I'm having with the 'replay' button at the end of the quiz, and to add in the Twitter 'retweet' functionality in order to spread awareness of the game. I would also like to integrate some more animation and perhaps illustrations into the game, in order to make the experience more fun and enjoyable. I think the customised message at the end could perhaps include some sort of fireworks animation if the user scores 10 out of 10.</p>
	        </div>
	        <br />
	        <br />
	        <br />
	        <div className="project-text">
	        	<a className="button" href="https://github.com/madspesh/WebAdvanced_Spring2019_gillr456/tree/master/Finals" target="_blank" rel="noopener noreferrer">View on GitHub</a>
	        </div>
	        <br />
	        <br />
      	</div>
      </div>
    )
  }
}


export default CAProjectPage;







