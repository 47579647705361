import React from 'react';
import { Link } from 'react-router-dom';
import IconUserTesting from '../img/icon_usertesting_dark.png';
import BTTyping from '../img/bigtalk_typing_.mp4';
import BTOrganising from '../img/bigtalk_organising_.mp4';
import BTPracticing from '../img/bigtalk_practicing_.mp4';
import BTDefault from '../img/bigtalk_default.gif';
import BTAdd from '../img/bigtalk_add.png';
import BTPractice from '../img/bigtalk_practice_2.gif';
import BTPerform from '../img/bigtalk_perform_.mp4';
import BTHannah from '../img/blog_20200216_personahannah.jpg';
import BTYe from '../img/blog_20200216_personaye.jpg';
import BTJamal from '../img/blog_20200216_personajamal.jpg';
import BTInterviewSynthesis from '../img/bigtalk_interviewsynthesis.jpg';
import BTInterviewQuotes from '../img/bigtalk_interviewquotes.png';
import BTImprov from '../img/bigtalk_improv.jpg';
import BTCurrentProcess from '../img/bigtalk_currentprocess.png';
import BT7in7 from '../img/bigtalk_7in7.png';
import BTVoiceExperiment from '../img/blog_20190924_voiceexperiment.mp4';
import BTPostPerformance from '../img/bigtalk_postperformance.mp4';
import BTPostPerformanceVideo from '../img/bigtalk_postperformance_video.jpg';
import BTRealTime from '../img/bigtalk_realtime.mp4';
import BTRealTimeVideo from '../img/bigtalk_realtime_video.jpg';
import BTp5Test from '../img/blog_20191209_test.jpg';
import BTGifPrepare1 from '../img/bigtalk_prepare1.gif';
import BTGifPrepare2 from '../img/bigtalk_prepare2.gif';
import BTGifPractice from '../img/bigtalk_practice.gif';
import BTStyleTile1 from '../img/bigtalk_styletile1.png';
import BTStyleTile2 from '../img/bigtalk_styletile2.png';
import BTTaskFlow1 from '../img/bigtalk_taskflow_talkingpoint.png';
import BTSiteMap from '../img/bigtalk_sitemap.png';







class BTProjectPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scrolled: 0
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollProgress);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollProgress);
  }

  scrollProgress = () => {
    const scrollPx = document.documentElement.scrollTop;
    const winHeightPx =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrolled = `${scrollPx / winHeightPx * 100}%`;

    this.setState({
      scrolled: scrolled
    });
  };

  render() {
    const progressContainerStyle = {
      background: "transparent",
      height: "2px",
      position: "relative",
      top: 0,
      left: 0,
      width: "100vw",
      zIndex: 99
    };

    const progressBarStyle = {
      height: "2px",
      background: "#000000",
      width: this.state.scrolled
    };

    return (
      <div>
      	<div className="project-title">
	        <div id="title">A Web Platform for Public Speaking</div>
	        <Link to="/"><div className="close-project"></div></Link>
	        <div className="progress-container" style={progressContainerStyle}>
	          <div className="progress-bar" style={progressBarStyle} />
	        </div>
	    </div>
        <div id="project-page">
        	<div className="project-text-half">
		    	<div className="padding-top-2em">
			        <h1 className="padding-top-1em">How might we use technology to help people with public speaking?</h1>
			    </div>
			    <div className="padding-top-2em">
			        <div className="project-caption padding-top-1em">
				        <h3>Context</h3>
				        <h5 className="padding-top-1em">Big Talk is a web application that helps people improve their memorisation and communication skills, to be used before, during and after public speaking events. Designed and developed during the Design and Technology: Thesis Studio program at Parsons.</h5>
			        </div>
			        <div className="project-caption padding-top-2em">
				        <h3>Role</h3>
				        <h5 className="padding-top-1em">UX research including interviews, field observations, competitive analyses, journey mapping and usability testing. UX/UI development including information architecture, branding and interaction design. Web development using React.js, JavaScript, HTML and CSS.</h5>
			        </div>
			    </div>
			</div> 
		    <video className="project-text-half padding-top-3em" playsinline="true" webkit-playsinline="true" autoPlay loop muted>
    			<source src={BTTyping} alt="" type='video/mp4' />
			</video>
	        <img className="padding-top-3em" src={BTDefault} alt="" />
		    <div className="project-text padding-top-3em">
			    <h3>Background</h3>
		        <h2 className="padding-top-05em">The world's biggest fear</h2>
		        <p className="padding-top-1em">The decision to concentrate my thesis project on the issue of public speaking was mostly driven by a desire for self-improvement. Throughout my academic and professional life, a lack of confidence in speaking publicly about my work, ideas and opinions has always been a big issue for me. I’m definitely not alone in this thinking; in fact, the fear of public speaking (also known as glossophobia, derived from the Greek word glōssa, meaning tongue) is often cited to be the number one fear in the world.</p>
		        <p className="padding-top-1em">Most people envision public speaking as being alone in front of a podium with an audience of people looking up at you. Although this is a common scenario, anxiety around speaking in public can surface in any number of situations. Giving a toast at a wedding, getting on stage at karaoke - the likelihood of having to ‘perform’ in front of an audience at some point in our lives is extremely high. Our ability to communicate effectively in a work environment is of particular importance, as a person’s social performance in interviews, meetings, presentations and networking events can significantly help or hinder their professional growth.</p>
	        </div>
	        <img className="padding-top-3em" src={BTAdd} alt="" />
		    <div className="project-text padding-top-3em">
			    <h3>Opportunity</h3>
		        <h2 className="padding-top-05em">A holistic solution</h2>
		        <p className="padding-top-1em">Very few methods of practicing public speaking exist without attending social groups or using VR platforms. Other digital solutions also tend to target only one specific area, such as voice analysis or body language, and provide no help with actually creating and organising the content of your speech.</p>
		        <p className="padding-top-1em">Big Talk tackles every phase of public speaking, from the preparation stage to the final performance, in a medium which is universally accessible through the web. Fusing speech recognition technology and spaced repetition techniques, the platform is versatile enough to be used to practice for any high-pressure public speaking situation.</p>
	        </div>
	        <img className="padding-top-3em" src={BTPractice} alt="" />
	        <div className="project-text padding-top-3em">
			    <h3>Research</h3>
		        <h2 className="padding-top-05em">Listening and learning</h2>
		    </div>
	        <div className="project-text-half padding-top-1em">
		        <h4>Interviews</h4>
		        <p className="padding-top-1em">In order to identify insights to guide my project, I conducted interviews with 5 potential users who had an interest in improving their public speaking skills, either due to suffering from public speaking anxiety, or to help them professionally in their career.</p>
		        <p className="padding-top-1em">I created an affinity map of all the insights I heard and began to identify some similar patterns. Every person I interviewed said that practising consistently and really knowing your content was the key to success, whether they suffered from public speaking anxiety or not.</p>		
		        <p className="padding-top-1em">Some of the pain points I heard were focused around the mind going blank due to nerves, being unable to speak clearly and coherently in a way that keeps the audience engaged, and being bad at memorising what they had to say. I also got some more specific feedback from non-native English speakers about preferring to practice with a word-for-word script, due to difficulties with translation.</p>		      
	        </div>
	        <img className="project-text-half padding-top-3em" src={BTInterviewSynthesis} alt="" />
	        <img className="padding-top-3em" src={BTInterviewQuotes} alt="" />
		    <div className="project-text-half padding-top-3em">
		        <h4>Field observations</h4>
		        <p className="padding-top-1em">I also attended improv classes, Toastmasters meetings and public speaking workshops to observe first-hand what kind of techniques practitioners in the field were using to improve people's confidence and communication skills. Although the events were quite diverse in terms of industry (comedy, business and acting) and technique, each event seemed to highlight the importance of ‘practice makes perfect’.</p>
		        <p className="padding-top-1em">Toastmasters was particularly stringent when it came to the rules of practice, enforcing specific time limits, content rating systems, and ‘Um’/‘Ah’ counters to monitor the amount of filler words a person says. The improv class prioritised warm-up activities to activate both the mind and body, and to inspire spontaneous ad-libbing whilst speaking. The public speaking workshop taught specific acting-related techniques to keep audiences engaged, such as eye contact and body positioning.</p>
	        </div>
	        <img className="project-text-half padding-top-3em" src={BTImprov} alt="" />
{/*	        <div className="project-text-half padding-top-3em">
		        <h2>Research: Competitive Analysis</h2>
		        <p className="padding-top-1em">I also conducted competitive and comparative analyses to find out which companies were working in a similar space and identify key commonalities/differences between their business models.</p>      
	        </div>	 
	        <img className="project-text-half padding-top-3em" src={BTDefault} alt="" />
*/}		    <div className="project-text padding-top-3em">
			    <h3>Prototyping</h3>
		        <h2 className="padding-top-05em">Initial experiments</h2>
		        <p className="padding-top-1em">From all of my primary and secondary research, I managed to narrow down the process of public speaking into six main stages.</p>
	        </div>
	        <img className="padding-top-2em" src={BTCurrentProcess} alt="" />
		    <div className="project-text padding-top-1em">
		        <p className="padding-top-1em">At this stage of the project, I’d found some general goals that my users might have and identified a rough timeline of the steps they’d follow to get there. But I still wasn’t sure of what form my project should take. Should it be a mobile app? A VR experience? I knew from my competitive analysis that the market was saturated with both of those forms; I’d have to come up with something really advanced to compete with the likes of Speeko or Ovation.</p>
		        <p className="padding-top-1em">During a ‘7 prototypes in 7 days’ assignment, I decided to have some fun building mini public speaking aids using various different mediums. I then tested these experiments out with potential users to see which avenues would be worth exploring. Below are images from two of the most popular prototypes; an improv game to practice asking questions during networking events, and an AR app to memorise flashcard content using spaced repetition.</p>
	        </div>
	        <img className="padding-top-1em" src={BT7in7} alt="" />
		    <div className="project-text">
		        <div className="padding-top-1em">
		        	<div style={{width: 10 + "%", display: "inline-block", verticalAlign: "top"}}>
			        	<img className="small-icon" src={IconUserTesting} alt="Icon of a cog" />
			        </div>
			        <div style={{width: 5 + "%", display: "inline-block", verticalAlign: "top"}}></div>
			        <div style={{width: 85 + "%", display: "inline-block", verticalAlign: "top"}}>
			        	<h5>Users I tested with loved the AR flashcard prototype, and surprisingly not many people had used or heard of existing spaced repetition software such as Anki, Mnemosyne and SuperMemo. Although these programs are incredibly useful, the interfaces tend to not be very visually appealing. This form of memorisation had also never been used in an application for public speaking, so I knew this was an area I needed to pursue further.</h5>
			        </div>		
		        </div>  
	        </div>
		    <div className="project-text padding-top-3em">
			    <h3>Prototyping</h3>
		        <h2 className="padding-top-05em">Interactions</h2>
		        <p className="padding-top-1em">Following on from my initial experiments, I created a few more mini prototypes to test speech recognition and pitch/volume detection. Recording and analysing a user’s speech was always going to be a big part of the project, and I wanted to play around with different APIs such as Google Cloud Speech-To-Text, IBM Watson and JavaScript’s browser-based Web Speech API to see which would be most effective and easiest to integrate.</p>
		    </div>
		    <video className="padding-top-3em" playsinline="true" webkit-playsinline="true" autoPlay loop muted>
    			<source src={BTVoiceExperiment} alt="" type='video/mp4' />
			</video>
		    <div className="project-text padding-top-3em">
	        	<h4>Unity prototype</h4>
		        <p className="padding-top-1em">Above is a screen recording of an experiment I did in Unity. At the top of the game view screen, you can see the IBM Watson API streaming my words from speech to text. I wrote a simple script to trigger text boxes to appear whenever a key word is mentioned.</p>
	        </div>
		    <div className="project-text padding-top-3em">
		        <div style={{width: 10 + "%", display: "inline-block", verticalAlign: "top"}}>
			        <img className="small-icon" src={IconUserTesting} alt="Icon of a cog" />
			    </div>
			    <div style={{width: 5 + "%", display: "inline-block", verticalAlign: "top"}}></div>
			    <div style={{width: 85 + "%", display: "inline-block", verticalAlign: "top"}}>
			        <h5>Users I tested with noted that this prototype could be very useful during phone or video interviews. If the interviewee puts their phone on loudspeaker, the prototype could listen out for specific words that the interviewer mentions and trigger helpful reminders about that topic.</h5>		
			    </div>		
		    </div> 
		    <video className="project-caption-third padding-top-3em" style={{cursor: "pointer"}} playsinline="true" webkit-playsinline="true" controls poster={BTRealTimeVideo}>
    			<source src={BTRealTime} alt="" type='video/mp4' />
			</video>
		    <video className="project-caption-third padding-top-3em" style={{cursor: "pointer"}} playsinline="true" webkit-playsinline="true" controls poster={BTPostPerformanceVideo}>
    			<source src={BTPostPerformance} alt="" type='video/mp4' />
			</video>
	        <img className="project-caption-third padding-top-3em" src={BTp5Test} alt="" />
		    <div className="project-text padding-top-3em">
	        	<h4>p5.js prototype</h4>
		        <p className="padding-top-1em">To test the voice analysis part of my project, I used p5.js to write two programs which would give users feedback on their speech either in real-time or post-performance. In the real-time version, a circle grows and shrinks in size depending on the user's pitch and volume whilst speaking. In the post-performance version, the pitch and volume data is plotted onto a graph, only viewable after the user has stopped speaking.</p>
	        </div>
		    <div className="project-text padding-top-3em">
		        <div style={{width: 10 + "%", display: "inline-block", verticalAlign: "top"}}>
			        <img className="small-icon" src={IconUserTesting} alt="Icon of a cog" />
			    </div>
			    <div style={{width: 5 + "%", display: "inline-block", verticalAlign: "top"}}></div>
			    <div style={{width: 85 + "%", display: "inline-block", verticalAlign: "top"}}>
			        <h5>Users I tested with mostly preferred the post-performance feedback, although one person mentioned the real-time visualisation gave her reassurance that the program was actually working, but that the large size of circle was distracting.</h5>		
			    </div>		
		    </div> 
		    <div className="project-text padding-top-3em">
			    <h3>Architecture</h3>
		        <h2 className="padding-top-05em">Building structure</h2>
		        <p className="padding-top-1em">By this point, I had decided on a web app as the final form for my project. The reason why I chose a browser-based medium over a native app was to make the platform accessible through a range of devices, from mobile to tablet to desktop. Mobile would be the most difficult viewport to design for, as there is the least screen real-estate to take advantage of.</p>
		        <p className="padding-top-1em">In Figma, I created wireframes for every mobile screen in order to figure out the overall system architecture and ensure it all made sense. These GIFs illustrate three of the main task flows of the app; creating a talking point, creating a Big Talk, and practicing that talk.</p>
	        </div>
	        <img className="project-caption-third padding-top-3em" src={BTGifPrepare1} alt="" />
	        <img className="project-caption-third padding-top-3em" src={BTGifPrepare2} alt="" />
	        <img className="project-caption-third padding-top-3em" src={BTGifPractice} alt="" />
{/*		    <div className="project-text padding-top-3em">
	        	<h4>Task flow</h4>
		        <p className="padding-top-1em">Below is a task flow diagram showing how a user would create a talking point. Users have the option to create cards from scratch, or use one of the prewritten prompts available.</p>
	        </div>
	        <img className="padding-top-2em" src={BTTaskFlow1} alt="A diagram illustrating the task flow of creating a talking point" />
*/}		    <div className="project-text padding-top-3em">
	        	<h4>Site map</h4>
		        <p className="padding-top-1em">Below is a site map diagram illustrating the overall structure of the web application. Certain features, such as the talking point prompts, are only available on desktop. I chose to restrict mobile to practice and performance functionality only, as my user research showed that most people preferred to use larger screens when preparing for public speaking events.</p>
	        </div>
	        <img className="padding-top-3em" src={BTSiteMap} alt="A site map illustrating the overall structure of the application" />
		    <div className="project-text padding-top-3em">
			    <h3>Prototyping</h3>
		        <h2 className="padding-top-05em">Branding</h2>
		        <p className="padding-top-1em">Using my mobile wireframes as a guide for text sizing, I created two polarising style tiles illustrating the potential look and feel of the app. By testing out two different options with potential users, I was able to elicit strong opinions about which visual direction people preferred, and the reasons why.</p>
	        </div>
	        <div className="project-text-half padding-top-3em">
	        	<h4>'Mindmap' prototype</h4>
		        <p className="padding-top-1em">This first style tile has a 'mindmap' sort of vibe. The idea is that these sausage-shaped talking point bubbles can easily be linked with other talking points to form a cohesive thought process, which would then become a Big Talk. I used a deep blue/purple gradient as a background, as it felt quite dreamy and inspirational. The typography uses a mix of sans-serif 'Didact Gothic' for body copy and slab-serif 'Scope One' for headings.</p>
		        <p className="padding-top-1em">One of the features I want to include in the app is the ability to switch between light and dark modes, for when you're practising your talk during the day or late at night. The light mode in this style tile simply fades the dark gradient background to 5% opacity, and increases the opacity of the bubbles.</p>			      
	        </div>
	        <img className="project-text-half padding-top-3em" src={BTStyleTile1} alt="" />
	        <div className="project-text-half padding-top-3em">
	        	<h4>'Pill' prototype</h4>
		        <p className="padding-top-1em">The other style tile has a much more modular and functional tone. I chose a neutral shade of greyish-blue as the primary colour scheme, and decided to stick to just the one sans-serif font, 'IBM Plex Sans', in varying weights and character cases. The iconography uses solid shapes in neutral tones, as opposed to the outline stroke icons in the previous style tile.</p>
		        <p className="padding-top-1em">Instead of 'mindmap'-style thought bubbles, this option has a 'pill'-like design. Each Big Talk circle expands when tapped, into segments which represent the talking points within that Big Talk. As the primary greyish-blue colour is a medium toned hue, the distinction between light and dark modes is less apparent than in the previous style tile.</p>		     
	        </div>
	        <img className="project-text-half padding-top-3em" src={BTStyleTile2} alt="" />
		    <div className="project-text padding-top-3em">
		        <div style={{width: 10 + "%", display: "inline-block", verticalAlign: "top"}}>
			        <img className="small-icon" src={IconUserTesting} alt="Icon of a cog" />
			    </div>
			    <div style={{width: 5 + "%", display: "inline-block", verticalAlign: "top"}}></div>
			    <div style={{width: 85 + "%", display: "inline-block", verticalAlign: "top"}}>
			        <h5>Users I tested with didn't think that either colour scheme was exciting or calming enough to help prepare people for the act of public speaking. Out of the two styles of talking point bubbles, the second 'pill'-like option was most popular due to the expanding interaction being the easiest to understand. People seemed to like the 'progress' stroke that I added around the bubbles in the first option, which is designed to indicate how much you've practised that particular talk.</h5>		
			    </div>		
		    </div> 
{/*		    <div className="project-text padding-top-2em">
		        <p className="padding-top-1em">Below is the app's current visual direction. According to a global survey from paper merchant G.F. Smith and psychologists at the University of Sussex, UK, the world’s most relaxing colours are navy blue, closely followed by teal-like turquoise, and soft pastel pink. Users have the choice to select a colour scheme from any one of these three shades during the onboarding phase of the app.</p>
	        </div>
	        <img className="padding-top-3em" src={BTStyleTileNew} alt="" />
*/}	        <div className="project-text padding-top-3em">
			    <h3>Highlights</h3>
		        <h2 className="padding-top-05em">Use cases</h2>
		        <p className="padding-top-1em">To demonstrate some of the main features of the app, I'll explain how three personas could use Big Talk to meet their individual public speaking goals.</p>
		    </div>
	        <img className="padding-top-3em" src={BTHannah} alt="" />
	        <div className="project-text-half padding-top-3em">
	        	<h4>Hannah's interview</h4>
		        <p className="padding-top-1em">Hannah has an upcoming interview for a flight attendant position at Air France. She’s applied for several airlines and is sure to have multiple interviews covering the same sort of topics. The Big Talk app saves Hannah time by allowing her to copy and reuse talking points for as many different events as she wants.</p>		     
		        <p className="padding-top-1em">It’s a short video interview, so Hannah plans to talk through her points in 20 minutes. The Big Talk app has a built-in timer activated during Practice mode which allows her to see how long she’s spending on each talking point.</p>	
		        <p className="padding-top-1em">Interviews are spontaneous, and good interviewees know their content so well that they can answer any question at any time. That’s why when preparing her talking points, Hannah chose to practice at random rather than in a linear order. Big Talk’s spaced repetition system during Practice mode will help Hannah memorise her content and be prepared for impromptu questions.</p>	
	        </div>
		    <video className="project-text-half padding-top-3em" playsinline="true" webkit-playsinline="true" autoPlay loop muted>
    			<source src={BTOrganising} alt="" type='video/mp4' />
			</video>
	        <img className="padding-top-3em" src={BTJamal} alt="" />
	        <div className="project-text-half padding-top-3em">
	        	<h4>Jamal's audition</h4>
		        <p className="padding-top-1em">Jamal has been asked to audition for the gig of a lifetime; a leading role in the musical Hamilton. In preparation for the audition, he’s added various lines from the script word-for-word into the Big Talk app.</p>		     
		        <p className="padding-top-1em">He’s not sure which scene he’ll be asked to perform, so like Hannah, he chose to randomise the order of his talking points. He enters Practice mode by double-tapping the Hamilton Audition talk, reviews his reminder notes, and then hits the Record button to practice.</p>	
		        <p className="padding-top-1em">As an actor, it’s imperative that Jamal can recite his lines without missing a single word. The Big Talk app analyses the content of Jamal’s speech and compares it with his original script, highlighting which words were matched and which were missed. Jamal also gets feedback on how his volume and pitch changed over time, which help him identify specific moments in his speech which were too loud/quiet or too monotonous.</p>	
	        </div>
		    <video className="project-text-half padding-top-3em" playsinline="true" webkit-playsinline="true" autoPlay loop muted>
    			<source src={BTPracticing} alt="" type='video/mp4' />
			</video>
	        <img className="padding-top-3em" src={BTYe} alt="" />
		    <div className="project-text padding-top-3em">
	        	<h4>Ye's presentation</h4>
		        <p className="padding-top-1em">In addition to being naturally shy, Ye finds it difficult to share her work during class presentations due to a language barrier. When preparing for public speaking events, she prefers to review a full script of what she has to say, as reading from bullet points just adds an extra step to the translation process.</p>
		        <p className="padding-top-1em">The Big Talk app acts as a crutch for Ye during her class presentations. By long-pressing on a Big Talk, she enters Perform mode, where she’s able to swipe through her various talking point scripts as and when she needs to.</p>
		        <p className="padding-top-1em">She also has the option to activate the Listening function, which uses her computer microphone to listen out for words that are being mentioned. Once the Big Talk app detects a word that matches one of the tags in her talking points, the carousel will automatically slide to that card. This function can easily be switched on and off at her convenience.</p>
	        </div>
		    <video className="padding-top-1em" playsinline="true" webkit-playsinline="true" autoPlay loop muted>
    			<source src={BTPerform} alt="" type='video/mp4' />
			</video>
	        <div className="project-text padding-top-3em">
			    <h3>Reflection</h3>
		        <h2 className="padding-top-05em">Next steps</h2>
		        <p className="padding-top-1em">Big Talk is currently a work in progress, planned for completion by mid May. Now that the design phase is almost complete, I’ll be putting all of my efforts into developing the web app using React.js and JavaScript. A beta version will be demoed during the Parsons MFA Design and Technology thesis show - please come along if you’d like to check it out! You can also follow my thesis progress on my blog linked below.</p>
	        </div>
	        <br />
	        <br />
	        <br />
	        <div className="project-text">
				<Link to="/blog">
	        		<a className="button" target="_blank" rel="noopener noreferrer">Read the blog</a>
				</Link>
	        </div>
	        <br />
	        <br />
	        <br />
      	</div>
      	<div className="footer-next">
  			<div className="row">
	  			<div className="column">
	  				<h6>Previous project</h6>
	      			<h4 className="padding-top-05em">
		      			<Link to="/project-slider-quiz">The Radial Dial</Link>
	      			</h4>
	      		</div>
      		</div>
      	</div>
      </div>
    )
  }
}


export default BTProjectPage;







