import React from 'react';
import { Link } from 'react-router-dom';
import ARPhoneVideo from '../img/aeropress_phonevideo.mp4';
import ARCoffeeBreakdownVideo from '../img/aeropress_coffeebreakdownvideo.mp4';
import ARPosters from '../img/aeropress_posters.jpg';
import ARProcess from '../img/aeropress_process.gif';
import ARPersona1 from '../img/aeropress_persona1.png';
import ARPersona2 from '../img/aeropress_persona2.png';
import ARMarketingFunnel from '../img/aeropress_marketingfunnel.jpg';
import ARBrainstorm from '../img/aeropress_brainstorm.jpg';
import ARStumptown from '../img/aeropress_stumptown.jpg';
import ARProductStatement from '../img/aeropress_productstatement.jpg';
import ARStoryboard from '../img/aeropress_storyboard.jpg';
import ARLowFiPrototype from '../img/aeropress_lowfiprototype.jpg';
import ARUnityVideo from '../img/aeropress_unityvideo.mp4';
import ARFeaturesVideo from '../img/aeropress_featuresvideo.mp4';
import ARHowToGuide from '../img/aeropress_howtoguide.jpg';









class ARProjectPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scrolled: 0
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollProgress);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollProgress);
  }

  scrollProgress = () => {
    const scrollPx = document.documentElement.scrollTop;
    const winHeightPx =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrolled = `${scrollPx / winHeightPx * 100}%`;

    this.setState({
      scrolled: scrolled
    });
  };

  render() {
    const progressContainerStyle = {
      background: "transparent",
      height: "2px",
      position: "relative",
      top: 0,
      left: 0,
      width: "100vw",
      zIndex: 99
    };

    const progressBarStyle = {
      height: "2px",
      background: "#000000",
      width: this.state.scrolled
    };

    return (
      <div>
      	<div className="project-title">
	        <div id="title">An Augmented Reality Experience for AeroPress</div>
	        <Link to="/"><div className="close-project"></div></Link>
	        <div className="progress-container" style={progressContainerStyle}>
	          <div className="progress-bar" style={progressBarStyle} />
	        </div>
	    </div>
        <div id="project-page">
        	<div className="project-text-half">
		    	<div className="padding-top-2em">
			        <h1 className="padding-top-1em">How might we use AR to introduce AeroPress to new audiences within the café space?</h1>
			    </div>
			    <div className="padding-top-2em">
			        <div className="project-caption padding-top-1em">
				        <h3>Context</h3>
				        <h5 className="padding-top-1em">Proof of concept prototype for a collaborative AR brand experience, designed and developed in 2019 during the Design and Technology: Augmented Realities program at Parsons.</h5>
			        </div>
			        <div className="project-caption padding-top-2em">
				        <h3>Role</h3>
				        <h5 className="padding-top-1em">UX research, UI design and development. Built in Unity using Vuforia Engine. 3D assets created in Blender. 2D graphics created in Adobe Illustrator.</h5>
			        </div>
			    </div>
			</div>
		    <video className="project-text-half padding-top-3em" playsinline="true" webkit-playsinline="true" autoPlay loop muted>
    			<source src={ARPhoneVideo} alt="A video recording of the AR app in use" type='video/mp4' />
			</video>		    
		    <div className="project-text padding-top-3em">
		        <h2>Why AeroPress?</h2>
		        <p className="padding-top-1em">Confession: I am slightly obsessed with coffee. Not just the beverage (although I do drink it daily), but the entire culture. I love visiting cafés and trying different beans and roasts, and I’ve just recently got into concocting my own speciality brews at home. I even built a website just for fun explaining the ingredients in each drink, for those that don’t know their macchiato from their mocha.</p>
	        </div>
	        <video className="padding-top-3em" playsinline="true" webkit-playsinline="true" autoPlay loop muted>
    			<source src={ARCoffeeBreakdownVideo} alt="A screen recording of my coffee breakdown site" type='video/mp4' />
			</video>
			<br />
	        <br />
	        <br />
	        <div className="project-text">
	        	<a className="button" href="https://madspesh.github.io/coffeebreakdown/" target="_blank" rel="noopener noreferrer">View the live demo</a>
	        </div>
	        <br />
	        <div className="project-text padding-top-3em">
		        <p>So when we were given an open brief to design an AR experience for a brand and/or product of our choosing, I chose my current fave coffee brewer, the AeroPress. I spoke to a number of fellow coffee drinkers about how they made coffee at home, and discovered most used machines, instant coffee or paper filters. Very few used a coffee press, mostly due to not really knowing how it worked. This was a great opportunity to highlight an innovative product that people may not even know they want until they see it in action.</p>
	        </div>
	        <div className="project-text padding-top-3em">
		        <h2>The product advantage</h2>
		        <p className="padding-top-1em">AeroPress is not a typical coffee press, but it uses a similar mechanism. Coffee is steeped for 10–50 seconds (depending on the grind and preferred strength) and then forced through a filter by pressing the plunger through the tube. The result is an espresso strength concentration of coffee, which can then be combined with other ingredients to make a variety of drinks.</p>
		        <p className="padding-top-1em">The brand’s target audience are coffee aficionados; people who really know about coffee. As the equipment and process can appear to be quite confusing to those unfamiliar with the product, there is a barrier to entry. But also an area for opportunity! Aeropress is a brand which embraces creativity, as evidenced below in numerous posters for their <a href="https://www.worldaeropresschampionship.com/" target="_blank" rel="noopener noreferrer">World AeroPress Championships</a>. The essence of AeroPress embodies experimentation and innovation, therefore augmented reality could be their perfect tool for brand awareness.</p>		        
	        </div>	        
	        <img className="padding-top-3em" src={ARPosters} alt="World AeroPress Championship posters" />     
	        <div className="project-text-half padding-top-3em">
		        <h2>Defining the challenge</h2>
		        <p className="padding-top-1em">I started out by doing some competitive research into similar brands. The AeroPress is a unique patented device, but there are some related products with similar niche audiences such as Chemex, Rok and Moka pot. There are also traditional French presses made by the likes of Bodum, Grosche and Espro. None of the competitors I researched had ever ventured into XR.</p>
		        <p className="padding-top-1em">I also did some first-hand behavioural research by documenting myself using the AeroPress, as I was initially thinking about designing an AR experience to explain how all the different equipment parts fit together and function. However, after having so much difficulty holding the phone whilst using the AeroPress, I realised that a handheld mobile guide (at least in the touch-powered sense) wouldn’t be helpful to new users at all.</p>
	        </div>
	        <img className="project-text-half padding-top-3em" src={ARProcess} alt="Pictures of the AeroPress brewing process" />     
	        <div className="project-text padding-top-3em">
		        <h2>Visualising personas</h2>
		        <p className="padding-top-1em">Instead of focusing on designing an AR experience solely for home use, I decided to approach the brief from a different angle and target coffee-drinkers in their native environment; the coffee shop. I continued my user research by visiting café’s and talking to the patrons around me as well as interviewing fellow students, and I began to identify some similar personality traits.</p>
		        <p className="padding-top-1em">I designed two coffee-drinking personas that I wanted to target; the first being ‘The Café Hipster’. He lives in New York, is in his late 30’s, and works for a tech startup. He is a Café Hipster because he spends most of his spare time in independent coffee shops, and he turns his nose up at mainstream chains like Starbucks and mainstream products like Nespresso.</p>		
		        <p className="padding-top-1em">He would like to make high-quality coffee at home, and he has heard of the AeroPress but doesn’t currently own one. He has a high level of tech proficiency due to his job, and tries to keep up with the latest products and trends. Augmented reality is something that he would be really interested in.</p>
	        </div>	
	        <img className="padding-top-3em" src={ARPersona1} alt="The ‘Café Hipster’ persona" />    
	        <img src={ARPersona2} alt="The ‘Wannabe Barista’ persona" />     
	        <div className="project-text padding-top-2em">
		        <p>My second persona is ‘The Wannabe Barista’. She is a 19 year old student from Croydon, and like most people her age, she also has a high level of tech proficiency. However, she likes to spend her time online using social media platforms like Instagram.</p>
		        <p className="padding-top-1em">She prefers to visit mainstream chains like Starbucks, as she likes ordering fun seasonal drinks such as Pumpkin Spiced Latte. Her interest in social media also drives her to post pictures of the drinks she purchases, and she loves sharing cool new products and apps with friends. She doesn’t know how to make her own coffee at home, and she can’t afford an expensive machine like Nespresso.</p>
	        </div>
	        <img className="padding-top-3em" src={ARMarketingFunnel} alt="The two personas positioned in the marketing funnel" />   
	        <div className="project-text padding-top-3em">
		        <h2>Discovering opportunities</h2>
		        <p className="padding-top-1em">If we look at the traditional product marketing funnel above, The Wannabe Barista would be placed in the awareness bracket, as she has vaguely heard of AeroPress but does not yet have a strong interest in wanting to find out more. The Café Hipster is aware of and has an interest in AeroPress due to socialising with baristas in his favourite coffee shops, and wanting to keep up with hipster brands, but he needs an incentive to want to actually buy the product.</p>
		        <p className="padding-top-1em">I brainstormed some potential marketing opportunities, focusing on the ways that I could capture my two persona’s in the Awareness and Interest phase. As both visited café’s often, this was a natural space to introduce them to the experience and make them aware of the AeroPress brand.</p>	
		        <p className="padding-top-1em">In terms of generating interest, I thought that showing the potential of the AeroPress, such as its ability to create popular coffee drinks such as Lattes and Cappuccinos, would be the best way to drive users to buy. I also thought about using moments of delight such as animations and visualisations to make my experience more fun and shareable.</p>
	        </div>	
	        <img className="padding-top-3em" src={ARBrainstorm} alt="Post its from the brainstorming session" />   
	        <div className="project-text padding-top-3em">
		        <h2>The café space</h2>
		        <p className="padding-top-1em">The brand space I chose to focus on was Stumptown Coffee Roasters. I chose Stumptown because they already have an affiliation with AeroPress. On their website, the brand has posted a <a href="https://www.stumptowncoffee.com/brew-guides/aeropress" target="_blank" rel="noopener noreferrer">how-to guide</a> showing users how to follow the ‘Stumptown style’ brew recipe they created specifically for AeroPress.</p>
		        <p className="padding-top-1em">I visited both the Greenwich Village NYC location and the Midtown NYC location in the Ace Hotel. Although the flagship Greenwich Village branch sold AeroPress in-store, they did not serve AeroPress-made coffee. I had the idea that, perhaps by adding AeroPress coffee to their menu or creating a specific AeroPress menu, this might drive more sales. However, this would also need to be accompanied by some sort of in-store promotional experience.</p>	
		        <p className="padding-top-1em">I documented the experience of ordering coffee at Stumptown, in order to familiarise myself with the space and amenities available.I noticed that merchandising displays were placed strategically to entice people in the queue to order. The displays showcased Stumptown products such as branded mugs, cups and packages of coffee grounds.</p>
	        </div>	
	        <img className="padding-top-3em" src={ARStumptown} alt="Pictures from the Stumptown Greenwich Village location" />  
	       	<div className="project-text padding-top-3em">
		        <h2>Concept statement</h2>
		        <p className="padding-top-1em">My research into both AeroPress and Stumptown Coffee helped form the concept statement for the project.</p>
		        <h4 className="padding-top-1em">An augmented reality experience with longevity; introduced in store, reactivated at home. A collaborative promotion between AeroPress and Stumptown, the experience is supported by a new in-store AeroPress menu and branded merchandise.</h4>
		        <p className="padding-top-1em">Many AR apps created by brands are used once, in the moment of discovery, and then never opened again. I wanted to create an experience that provided promotion for the brands involved, whilst still being useful enough for people to want to use it again at home. Outlining the product statement below helped me to envision what the final product would look like, and what features and technologies would need to be included.</p>
	        </div> 
	        <img className="padding-top-3em" src={ARProductStatement} alt="The product statement overview" />  
	        <div className="project-text padding-top-3em">
		        <h2>Storyboarding the user journey</h2>
		        <p className="padding-top-1em">I began to envision how the experience would unfold by sketching out the steps that my user persona would take. I made sure to include every scenario, starting from the moment they enter the store, all the way through to the point of purchase, and then finally imagining how the product would continue to be used at home.</p>
	        </div>
	        <img className="padding-top-3em" src={ARStoryboard} alt="Storyboard sketches" />  
	        <div className="project-text-half padding-top-3em">
		        <h2>Low fidelity prototyping</h2>
		        <p className="padding-top-1em">I started to create some low fidelity mockups, using a paper prototype of the interface to test in the cafe. I realised that using an image marker flat to the table, such as a napkin, was not ideal as depending on the height of the chair/stool the user may be required to stand up to scan it. Also, a napkin is not very durable - I needed an image marker that will last a long time in order for the user to be able to reactivate the experience at home.</p>
		        <p className="padding-top-1em">Instead, I decided to use the actual coffee cup to activate the AR experience, using Vuforia’s cylinder image target. For this proof of concept prototype I am just using a recyclable paper cup, but I imagine the real thing to be a ceramic reuseable mug. Forming the pattern for the cup’s design was challenging, as the image target needed to be unique and highly contrasted enough to be easily detected by the Vuforia Engine.</p>
	        </div>
	        <img className="project-text-half padding-top-3em" src={ARLowFiPrototype} alt="Testing the low fidelity prototype in store" />     
	        <div className="project-text padding-top-3em">
		        <h2>Creating the AR experience in Unity</h2>
		        <p className="padding-top-1em">3D assets such as the translucent cup were designed and imported from Blender, but the animated drinks were created directly in Unity using Animator Controllers. I wrote scripts which would trigger the animations when tapping objects within the scene.</p>
		        <p className="padding-top-1em">Within the screen, Unity UI was the main toolset I used to design the buttons and canvas layout of the user interface. Icons and graphics were created in Adobe Illustrator, exported as PNGs and imported into the project to be used in the ingredients and how-to overlay panels.</p>
	        </div>
	        <video className="padding-top-3em" playsinline="true" webkit-playsinline="true" autoPlay loop muted>
    			<source src={ARUnityVideo} alt="Testing the experience in Unity" type='video/mp4' />
			</video>
			<div className="project-text padding-top-3em">
		        <h2>Proof of concept</h2>
		        <p className="padding-top-1em">The final technical prototype can be categorised into three sections; firstly the main scene in which users can see the ingredients breakdown of different coffee drinks by tapping on the coasters, secondly the key screen panel which explains how each ingredient should be prepared, and lastly the full screen overlay which takes the user through the steps to creating the ‘Stumptown style’ AeroPress recipe.</p>
		        <p className="padding-top-1em">The full screen overlay is the final stage of the experience; the reason for users to continue using the app once they’ve made their purchase and left the store. My prior research taught me that any digital devices used with AeroPress should ideally be hands free, so users can either tap through the steps or use voice commands to advance. I also integrated timer functions into a few of the steps to ensure that users are able to recreate the exact Stumptown brew.</p>
	        </div>
	        <video className="padding-top-3em" playsinline="true" webkit-playsinline="true" autoPlay loop muted>
    			<source src={ARFeaturesVideo} alt="Screen recordings of the three main features" type='video/mp4' />
			</video>
			<div className="project-text padding-top-3em">
		        <h2>Future iterations</h2>
		        <p className="padding-top-1em">The proof of concept prototype was deployed onto an iPhone and demonstrated during a class critique with creative technologists from Havas. I received good feedback about the project, some of the most notable being that the experience could be better realised within the browser as a WebAR application. I’m planning to look into this in the future, as well as finish integrating the IBM Watson Speech to Text SDK.</p>
	        </div>
	        <img className="padding-top-3em" src={ARHowToGuide} alt="Steps from the AeroPress How-To Guide overlay" />  
	    </div>
      </div>
    )
  }
}


export default ARProjectPage;







