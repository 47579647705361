import React from 'react';
import Blog20190910Blender1 from '../img/blog_20190910_blender1.jpg';
import Blog20190910Blender2 from '../img/blog_20190910_blender2.jpg';
import Blog20190910Storyboard from '../img/blog_20190910_storyboard.jpg';
import Blog20190910Notes1 from '../img/blog_20190910_notes1.jpg';
import Blog20190910Notes2 from '../img/blog_20190910_notes2.jpg';
import Blog20190910Unity from '../img/blog_20190910_unity.mp4';









class Blog20190910 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scrolled: 0
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollProgress);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollProgress);
  }

  scrollProgress = () => {
    const scrollPx = document.documentElement.scrollTop;
    const winHeightPx =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrolled = `${scrollPx / winHeightPx * 100}%`;

    this.setState({
      scrolled: scrolled
    });
  };

  render() {
    const progressContainerStyle = {
      background: "transparent",
      height: "2px",
      position: "relative",
      top: 0,
      left: 0,
      width: "100vw",
      zIndex: 99
    };

    const progressBarStyle = {
      height: "2px",
      background: "#000000",
      width: this.state.scrolled
    };

    return (
      <div>
        <div className="project-title">
	        <div id="title">Second Game Prototype</div>
	        <div className="progress-container" style={progressContainerStyle}>
	          <div className="progress-bar" style={progressBarStyle} />
	        </div>
	    </div>
        <div id="project-page">
	        <div className="project-text padding-top-3em">
  	        <br />
  	        <br />
  	        	<h3>2019-09-10</h3>
  		        <p className="padding-top-1em">This past week, I've been thinking about things to prototype. Glossophobia seems like quite a specific topic to focus on, but in reality there are many ways to illustrate the subject; from service orientated products to more abstract expressive forms. My first prototype was definitely more service oriented, and my background in UX/UI is pushing me towards a more user-focused, 'helpful' project. However, this is my thesis and I have the time to experiment and try out new things (like games).</p>
          </div>
          <img className="padding-top-3em" src={Blog20190910Storyboard} alt="My game storyboard" />  
          <div className="project-text padding-top-2em">          
              <p className="padding-top-1em"> I've never made a 3D game before, so for my second prototype I'm experimenting with that format to see how I like the process and hear what other people think of this weird freaky eyeball game. The idea is that you are a character who has to give a speech in an auditorium, but in order to get there you have to take a winding, treacherous path. You have a full script to read at the beginning, but the longer it takes to get to the auditorium, the more difficult it is to read. By the time you get inside, you have lost all your words and have nothing to say. Above is a quick storyboard of how the game would progress.</p>   
          </div>
          <img className="padding-top-3em" src={Blog20190910Blender1} alt="Creating a spiral shape in Blender" />  
          <img className="padding-top-3em" src={Blog20190910Blender2} alt="Building the auditorium in Blender" />  
          <div className="project-text padding-top-2em">          
              <p className="padding-top-1em">I used Blender to create the auditorium, and I set up the scene using Unity. I added a first person controller for the player to navigate the scene using their keyboard, but I'm thinking about other forms of controllers - maybe voice commands could be cool? Below is a screen recording of how it looks so far. I still need to add the interactivity - the heat/steam rising, the eyeballs turning to look at you, maybe some scary music. This is a work in progress.</p>
	        </div>
          <video className="padding-top-3em" playsinline="true" webkit-playsinline="true" autoPlay loop muted>
            <source src={Blog20190910Unity} alt="A video recording of the game scene so far" type='video/mp4' />
          </video>
          <div className="project-text padding-top-2em">          
              <p className="padding-top-1em">In other news, I'm happy that I managed to cross off one of my semester goals of visiting the XReality Lab (I will write more about this when I visit again). I also started to write down a list of my project's potential audience types, possible situations and environments where glossophobia could occur, the physical/mental symptoms of glossophobia, and viable technological solutions which could help ease or bring awareness to the issue. This list is really helpful when thinking about questions to ask during interviews, which will be the next part of my research phase.</p>
          </div>
          <img className="padding-top-3em" src={Blog20190910Notes1} alt="Notes on audience/users and situations/environments" /> 
          <img className="padding-top-3em" src={Blog20190910Notes2} alt="Notes on physical/mental symptoms and technological solutions" /> 
          <div className="project-text padding-top-3em">
            <h2>What do I need to do this week?</h2>
            <p className="padding-top-1em">- I need to interview at least one person from one of my defined user/audience sets. I've started compiling my one-on-one interview guide <a href="https://docs.google.com/document/d/1bgT9cAgY0yOitTGJpYh0jiKx6juKu2RJo4FRmY6VHSk/edit?usp=sharing" target="_blank" rel="noopener noreferrer">here</a>.</p>
            <p className="padding-top-1em">- I need to present my second prototype on Thursday and get some feedback on the direction. Right now this is more of an experiment, but if people like it then maybe I might want to continue down this game/immersive experience path.</p>
            <p className="padding-top-1em">- I need to do some more research into artistic ways to approach the subject of glossophobia. 'Siggraph' is a good keyword to search for when looking at research papers.</p>
          </div>
          <div className="project-text padding-top-3em">
            <h2>What do I need to do next week?</h2>
            <p className="padding-top-1em">- I will continue interviewing people and gaining insights into my subject matter. I will also finalise my sketch session guide <a href="https://docs.google.com/document/d/1deur76gg8ejsB18xT6-EUpAx1qwAxp_WTehbt77PmLE/edit?usp=sharing" target="_blank" rel="noopener noreferrer">here</a>, which I can hopefully facilitate in the next few weeks.</p>
            <p className="padding-top-1em">- I need to start creating more prototypes, as there are a lot of areas I'm interested in exploring before narrowing it down to just one direction. I think I will concentrate on data visualisation next. So...</p>
            <p className="padding-top-1em">- I need to look into voice analysis software ASAP, as it seems like this might be an integral part of my project. It's also very useful to know about.</p>
          </div>
          <div className="project-text padding-top-3em">
            <h2>What are my overall semester goals?</h2>
            <p className="padding-top-1em">- Throughout all of my classes, I will take notes on instructor and student presentations - observing their mannerisms, what they spoke about well, what received a good response from the audience, etc. This will help me to identify what makes a good public speaker.</p>
            <p className="padding-top-1em">- I will improve my Unity skills and general knowledge of AR/VR</p>
            <p className="padding-top-1em">- I will learn a 3D modelling program (probably Blender)</p>
            <p className="padding-top-1em">- I will learn a digital data visualisation program (D3.js?)</p>
            <p className="padding-top-1em">- I will become more familiar with a voice to text AI (IBM Watson?)</p>
          </div>          	        
	        <br />
	        <br />
	        <br />
	    </div>
      </div>
    )
  }
}


export default Blog20190910;







