import React from 'react';
import Blog20191202Responsive from '../img/blog_20191202_responsive.mp4';
import Blog20191202Firebase from '../img/blog_20191202_firebase.png';
import Blog20191202Script from '../img/blog_20191202_script.png';









class Blog20191202 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scrolled: 0
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollProgress);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollProgress);
  }

  scrollProgress = () => {
    const scrollPx = document.documentElement.scrollTop;
    const winHeightPx =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrolled = `${scrollPx / winHeightPx * 100}%`;

    this.setState({
      scrolled: scrolled
    });
  };

  render() {
    const progressContainerStyle = {
      background: "transparent",
      height: "2px",
      position: "relative",
      top: 0,
      left: 0,
      width: "100vw",
      zIndex: 99
    };

    const progressBarStyle = {
      height: "2px",
      background: "#000000",
      width: this.state.scrolled
    };

    return (
      <div>
        <div className="project-title">
          <div id="title">Figuring out Firebase</div>
          <div className="progress-container" style={progressContainerStyle}>
            <div className="progress-bar" style={progressBarStyle} />
          </div>
      </div>
        <div id="project-page">
          <div className="project-text padding-top-3em">
            <br />
            <br />
              <h3>2019-12-02</h3>
              <p className="padding-top-1em">Last week was Thanksgiving and I had a friend visiting from London, so I didn’t get to do nearly as much work as I would have liked. I’ve also been struggling with the process of saving and retrieving data through Firebase, but I finally managed to find a solution this morning after reading through the documentation and discovering the ‘child added’ event. Using ‘child added’ to retrieve the data instead of ‘value’ allows the web app to update automatically whenever a new flashcard is created.</p>
          </div>
          <img className="padding-top-3em" src={Blog20191202Script} alt="The code for saving and retrieving data" />
          <div className="project-text padding-top-1em">
              <p className="padding-top-1em">As you can see below, in the Firebase console each flashcard entry has a unique key with a string of letters. I was having a lot of trouble trying to attach this key to a specific ID in the HTML, but by using ‘child added’ I was able to access the ‘snapshot.key’ and save it as a variable which could then be inserted dynamically into the DOM. When the user clicks on a mini talking point card, the data associated with that key will render in the saved card popup.</p>              
          </div>
          <img className="padding-top-3em" src={Blog20191202Firebase} alt="The Firebase console" />
          <div className="project-text padding-top-1em">          
              <p className="padding-top-1em">Below is a quick screen recording of the web app so far. Being able to save and retrieve data is one of the key parts of my project (aside from the voice stuff), so I’m happy that I managed to get this functionality working. The layout is also now responsive and working on both desktop (mouse click) and mobile (touch). I have integrated the jQuery UI library into the app, which allows me to drag and drop these mini cards around the page. One of my next steps will be figuring out how to merge these talking points into a ‘big talk’ series of cards.</p>
          </div>
          <video className="padding-top-3em" playsinline="true" webkit-playsinline="true" autoPlay loop muted>
            <source src={Blog20191202Responsive} alt="The web app scaling responsively" type='video/mp4' />
          </video>
          <div className="project-text padding-top-1em">          
              <p className="padding-top-1em">My three week goal for my thesis is to create a working web application which will allow a user to create their own flashcards, review them in a specific order, and practise their speaking. My next big hurdle will be integrating the voice recognition and analysis, but as I have already found some APIs which could help me, I’m hoping it won’t be too difficult. I’ve found that it’s impossible for me to get my head into the coding stuff if I have less than 2 hours to spare, so I’m planning to work solely on my thesis on Fridays and Saturdays, and complete my assignments for other classes outside of this time. I’ll also make a start on my final presentation slides this weekend.</p>
          </div>     
          <br />
          <br />
          <br />
      </div>
      </div>
    )
  }
}


export default Blog20191202;







