import React, { Component } from 'react';
/* import PropTypes from 'prop-types'; */
import './index.css';
import Main from './components/main';
import { Link } from 'react-router-dom';




/*     NAVBAR     */
class NavBar extends React.Component {
  render() {
    return (
      <div>
        <ul id="nav">
          <li id="nav-top"><Link to="/">HOME</Link></li>
          <li id="nav-right"><a href="https://drive.google.com/file/d/1bHubMT_LvbtVJ-omPe6ZWoEvlOWQkkpJ/view?usp=sharing" target="_blank" rel="noopener noreferrer">RESUME</a></li>
          <li id="nav-bottom"><Link to="/about">ABOUT</Link></li>
          <li id="nav-left"><Link to="/blog">BLOG</Link></li>
        </ul>
      </div>
    );
  }
}





/*     FULL APP     */
class App extends Component {
  render () {
    return (
      <div>
        <NavBar/>
        <Main/>
      </div>
    );
  }
}

export default App;
