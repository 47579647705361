import React from 'react';
import Blog20191117Figma from '../img/blog_20191117_figma.jpg';
import Blog20191117Flashcard from '../img/blog_20191117_cardflip.mp4';










class Blog20191117 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scrolled: 0
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollProgress);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollProgress);
  }

  scrollProgress = () => {
    const scrollPx = document.documentElement.scrollTop;
    const winHeightPx =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrolled = `${scrollPx / winHeightPx * 100}%`;

    this.setState({
      scrolled: scrolled
    });
  };

  render() {
    const progressContainerStyle = {
      background: "transparent",
      height: "2px",
      position: "relative",
      top: 0,
      left: 0,
      width: "100vw",
      zIndex: 99
    };

    const progressBarStyle = {
      height: "2px",
      background: "#000000",
      width: this.state.scrolled
    };

    return (
      <div>
        <div className="project-title">
          <div id="title">Mid-Fidelity Design + Code</div>
          <div className="progress-container" style={progressContainerStyle}>
            <div className="progress-bar" style={progressBarStyle} />
          </div>
      </div>
        <div id="project-page">
          <div className="project-text padding-top-3em">
            <br />
            <br />
              <h3>2019-11-17</h3>
              <p className="padding-top-1em">With only four weeks left until the Thesis Pop Up show, I need to make a decision on what to make as a proof of concept prototype. Ideally I’d like to create a mobile app, but in the interest of time, I think a responsive web app is more doable. I’d also like to experiment with either WebVR or Unity 3D, but it’ll take me longer than a month to figure out the voice integration part of my project in these mediums. And, while my final product will hopefully encompass all of the six areas of public speaking I’d previously identified, for this December 10th deadline I’m just going to focus on three.</p>
          </div>
          <div className="project-text padding-top-1em">
              <p className="padding-top-1em">So, here is my goal for the next four weeks: I want to help my audience with public speaking by providing them with a mobile friendly platform to Prepare, Practise and Perform their content. They will be able to prepare flashcards on their talking points of choice, use the cards to practise reciting their big speech, as well as refer to the cards as a visual aid whilst performing. Voice recognition technology will power this platform, allowing users to create flashcards on the go by dictating into their device’s microphone. Whilst practicing their content, the website will compare the users speech to their original script, providing feedback on content accuracy as well as vocal volume and pitch. During the final performance the user can choose to either scroll through their flashcards manually, or activate the ‘listen’ mode, where the platform will automatically show flashcards based on specific keywords it hears.</p>              
          </div>
          <img className="padding-top-3em" src={Blog20191117Figma} alt="Creating wireframes in Figma" />
          <div className="project-text padding-top-1em">          
              <p className="padding-top-1em">Last weekend I started creating some wireframes in Figma to illustrate how my platform, tentatively named ‘Big Talk’, will actually work. I’m still reworking some parts; particularly the feedback screen where the user is able to review how their voice sounded and how accurate their content was. I had initially chosen to represent vocal pitch on a scale ranging from ‘monotone’ to ‘exciting’, but after speaking to my professor I realised that it should be up to the user to define how their varied their pitch is. So now I’m thinking about finding a way to visualise the pitch data on a graph instead. I’m still looking for Javascript libraries that could help me with this; so far I’ve found a platform called <a href="https://www.deepaffects.com/" target="_blank" rel="noopener noreferrer">SpeechAffects</a> which has a Paralinguistic Feature Extraction API, and there’s also a library called <a href="https://ml5js.org/" target="_blank" rel="noopener noreferrer">ml5.js</a> which offers a PitchDetection algorithm. I plan to experiment with both of these next week.</p>
          </div>
          <video className="padding-top-3em"autoPlay loop muted playsinline>
            <source src={Blog20191117Flashcard} alt="The basic web app framework" type='video/mp4' />
          </video>
          <div className="project-text padding-top-1em">          
              <p className="padding-top-1em">This weekend I managed to create a basic framework for the responsive web app based on my original Figma wireframes. I am using CSS and Javascript/jQuery for the flashcard flipping interaction, and Firebase to store the data that the user inputs. I’ve also managed to figure out how to hook up Javascript’s built-in Web Speech API, but I haven’t actually implemented it yet. I want to get the general ‘create a flashcard’ and ‘load a flashcard’ functionality sorted out first. I’m somewhat confident that I’ll be able to get things working in sync by next Sunday. I have a friend visiting for a week over Thanksgiving and I doubt I’ll be able to get much work done, so I’d like to get this major stuff out of the way as soon as possible!</p>
          </div>
          <div className="project-text padding-top-1em">          
              <p className="padding-top-1em">Although I’m keen to start thinking about the branding for this project (colour scheme, logo, typography, animations, etc.) - I’m not sure I’ll have enough time to go too deep into it. But if we’re having an end of the year show, it might be nice to show something a bit prettier than grey wireframes. I’ll see how things go... I think the main goal at the moment is to just get something working.</p>
          </div>        
          <br />
          <br />
          <br />
      </div>
      </div>
    )
  }
}


export default Blog20191117;







