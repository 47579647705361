import React from 'react';
import { Link } from 'react-router-dom';
import PWMacbook from '../img/perfectweekend_macbook.mp4';
import PWEntryProcess from '../img/perfectweekend_entryprocess.mp4';
import PWUserGeneratedContent from '../img/perfectweekend_usergeneratedcontent.mp4';
import PWSubmission from '../img/perfectweekend_submission.mp4';
import PWHeader from '../img/perfectweekend_header.mp4';
import PWLearnMore from '../img/perfectweekend_learnmore.mp4';
import PWVoting from '../img/perfectweekend_voting.mp4';
import PWHighlight1 from '../img/perfectweekend_highlight_1.png';
import PWHighlight2 from '../img/perfectweekend_highlight_2.png';
import PWHighlight3 from '../img/perfectweekend_highlight_3.png';
import PWStyleGuide1 from '../img/perfectweekend_styleguide_1.png';
import PWStyleGuide2 from '../img/perfectweekend_styleguide_2.png';










class PWProjectPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scrolled: 0
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollProgress);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollProgress);
  }

  scrollProgress = () => {
    const scrollPx = document.documentElement.scrollTop;
    const winHeightPx =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrolled = `${scrollPx / winHeightPx * 100}%`;

    this.setState({
      scrolled: scrolled
    });
  };

  render() {
    const progressContainerStyle = {
      background: "transparent",
      height: "2px",
      position: "relative",
      top: 0,
      left: 0,
      width: "100vw",
      zIndex: 99
    };

    const progressBarStyle = {
      height: "2px",
      background: "#000000",
      width: this.state.scrolled
    };

    return (
      <div>
      	<div className="project-title">
	        <div id="title">A Competition Microsite for the Financial Times</div>
	        <Link to="/"><div className="close-project"></div></Link>
	        <div className="progress-container" style={progressContainerStyle}>
	          <div className="progress-bar" style={progressBarStyle} />
	        </div>
	    </div>
        <div id="project-page">
	    	<div className="project-text padding-top-2em">
		        <h1 className="padding-top-1em">How might we promote user generated content as a form of competition entry?</h1>
		    </div>
		    <div className="project-text padding-top-2em">
		        <div className="project-caption-half padding-top-1em">
			        <h3>Context</h3>
			        <h5 className="padding-top-1em">An entry platform for the annual My Perfect FT Weekend competition, designed for the Financial Times at Battenhall.</h5>
		        </div
		        ><div className="project-caption-half padding-top-1em">
			        <h3>Role</h3>
			        <h5 className="padding-top-1em">UX/UI and interaction design. Design team: Rebecca Gill Clarke, Aaron Griffiths, Andrea Esteban Martín.</h5>
		        </div>
		    </div>
		    <video className="padding-top-3em" playsinline="true" webkit-playsinline="true" autoPlay loop muted>
    			<source src={PWMacbook} alt="The homepage of the microsite" type='video/mp4' />
			</video>		    
		    <div className="project-text padding-top-3em">
		    	<h3>Design brief</h3>
		        <h2 className="padding-top-05em">Calling for entries</h2>
		        <p className="padding-top-1em">My Perfect FT Weekend is a user-generated competition platform for mobile, tablet and desktop, which allows users to upload a video, image or text depiction of their ideal weekend with the chance of actually winning the experience they described.</p>
		        <p className="padding-top-1em">This annual competition is run by FT Weekend, a supplement to the weekly edition of the Financial Times newspaper. Our challenge was to design and build a microsite to receive, house and display all of the competition entries.</p>
		        <p className="padding-top-1em">The main functions of the site were to allow users to submit their entry, share via social media, explore other user entries and vote for their favourite. Unlike previous years, we wanted to accomodate videos and images in addition to text to make the site more visually engaging.</p>
	        </div>
	    </div>
		<div className="project-highlight" style={{backgroundColor: "#FCF2E1"}}>
				<div className="highlight-caption-third padding-top-3em">
				    <h3>Highlight #1</h3>
					<h2 className="padding-top-05em">Get inspired</h2>
					<h5 className="padding-top-1em">Browse a dynamic feed of FT Weekend and user generated content. Users can vote for their favourite entries directly from the grid tiles, or tap/click to see more.</h5>
				</div>
	        <img className="highlight-image-two-thirds" src={PWHighlight1} alt="A tablet screenshot of the submissions grid" />
				<div className="highlight-caption-third padding-top-3em">
				    <h3>Highlight #2</h3>
					<h2 className="padding-top-05em">Vote and share</h2>
					<h5 className="padding-top-1em">Each tile expands within the submissions grid, giving users more detail about the entry as well as the ability to vote or share on social media.</h5>
				</div>
	        <img className="highlight-image-two-thirds" src={PWHighlight2} alt="A desktop screenshot of an entry tile expanded" />
				<div className="highlight-caption-third padding-top-3em">
				    <h3>Highlight #3</h3>
					<h2 className="padding-top-05em">Submit your own</h2>
					<h5 className="padding-top-1em">Describe your own perfect weekend to be in with a chance of winning. Users are encouraged to upload an accompanying image or video to garner more votes.</h5>
				</div>
	        <img className="highlight-image-two-thirds" src={PWHighlight3} alt="A mobile screenshot of an entry being typed" />
		</div>
		<div id="project-page">
	        <div className="project-text-half padding-top-3em">
			    <h3>Interaction design</h3>
		        <h2 className="padding-top-05em">The entry process</h2>
		        <p className="padding-top-1em">To enter the competition we asked users to summarise their perfect FT Weekend in 140 characters or less, which allowed entries to be easily shared within Twitter and Facebook's character limit.</p>
		        <p className="padding-top-1em">Subtle transitions throughout the entry process made the experience less boring for the user, and each entry question was isolated and displayed within the same section of the page, eliminating any unnecessary scrolling.</p>
	        </div>
		    <video className="project-text-half padding-top-3em" playsinline="true" webkit-playsinline="true" autoPlay loop muted>
    			<source src={PWEntryProcess} alt="Screen recording of a user typing in their competition entry" type='video/mp4' />
			</video>	
	        <div className="project-text-half padding-top-3em">
			    <h3>Interaction design</h3>
		        <h2 className="padding-top-05em">User generated content</h2>
		        <p className="padding-top-1em">Once users had written their description, they could opt to add a photo, video or Snapchat/Instagram story to their entry. We encouraged entrants to experiment with filters, stickers and lenses to make their imagery more dynamic.</p>
		        <p className="padding-top-1em">Uploading content took seconds thanks to a considered file size limit, and all visuals were stored on the server ready for our review. Once approved, entries could be viewed and voted for directly within a submissions grid.</p>
	        </div>
		    <video className="project-text-half padding-top-3em" autoPlay loop muted playsinline>
    			<source src={PWUserGeneratedContent} alt="Screen recording of the user uploading an accompanying image" type='video/mp4' />
			</video>	
	        <div className="project-text-half padding-top-3em">
			    <h3>Interaction design</h3>
		        <h2 className="padding-top-05em">Entry submission</h2>
		        <p className="padding-top-1em">After all steps had been completed, users had the chance to review their entry and edit if needed. Clicking submit activated a pop-up window alerting the user that their entry was under consideration, and they then received regular emails on the status of their submission.</p>
		        <p className="padding-top-1em">Users could then either log out of their account, edit their account details (such as the email address, username and password they signed up with) or continue exploring the site and viewing/voting for other entries.</p>
	        </div>
		    <video className="project-text-half padding-top-3em" playsinline="true" webkit-playsinline="true" autoPlay loop muted>
    			<source src={PWSubmission} alt="Screen recording of the user finally submitting their entry" type='video/mp4' />
			</video>	
	        <div className="project-text-half padding-top-3em">
			    <h3>User experience</h3>
		        <h2 className="padding-top-05em">Above the fold</h2>
		        <p className="padding-top-1em">One of the most distinctive features of the site was the unique header carousel, which rotated carefully selected visuals relating to the Life, Arts and Culture sectors of the FT Weekend brand.</p>
		        <p className="padding-top-1em">On hover/click, the images inside each individual container would change according to the category selected.</p>
	        </div>
		    <video className="project-text-half padding-top-3em" playsinline="true" webkit-playsinline="true" autoPlay loop muted>
    			<source src={PWHeader} alt="A wireframe mockup of the user hovering over different header visuals" type='video/mp4' />
			</video>		
	        <div className="project-text-half padding-top-3em">
			    <h3>User experience</h3>
		        <h2 className="padding-top-05em">The voting process</h2>
		        <p className="padding-top-1em">Users were able to vote directly from the submissions grid, or click on an entry box to view its full description, to vote for the entry, and to share it on social media.</p>
		        <p className="padding-top-1em">The vote button had a number of conditions, including a call to action state (“Vote”), a post-action state (“Voted!"), and a number of votes state (eg. “24” with a thumbs-up icon).</p>
	        </div>
		    <video className="project-text-half padding-top-3em" playsinline="true" webkit-playsinline="true" autoPlay loop muted>
    			<source src={PWVoting} alt="A wireframe mockup of the user voting for a submission" type='video/mp4' />
			</video>	
	        <div className="project-text-half padding-top-3em">
			    <h3>User experience</h3>
		        <h2 className="padding-top-05em">One page layout</h2>
		        <p className="padding-top-1em">As the header, entry process and submissions grid were all integrated into one page, we wanted to ensure that secondary information (terms and conditions, privacy policies, etc.) wouldn’t distract from the more important elements of the site.</p>
		        <p className="padding-top-1em">This hidden ‘Learn More’ panel with details of the competition doesn’t take up prime real estate, but it has a clear CTA button inviting user interaction.</p>
	        </div>
		    <video className="project-text-half padding-top-3em" playsinline="true" webkit-playsinline="true" autoPlay loop muted>
    			<source src={PWLearnMore} alt="A wireframe mockup of the user expanding a hidden information panel" type='video/mp4' />
			</video>
	        <img className="padding-top-3em" src={PWStyleGuide1} alt="Selected elements from the comprehensive design guide" />
	        <div className="project-text padding-top-3em">
			    <h3>Visual design</h3>
		        <h2 className="padding-top-05em">Style guide</h2>
		        <p className="padding-top-1em">Working with such a large and well branded publication, it was really important that the design language we created for the website closely adhered to the Financial Times’ own digital guidelines.</p>
		        <p className="padding-top-1em">An intensive style guide was developed throughout the design process for our developer to reference, and it was continuously updated following feedback from the client and during the user testing stage.</p>
	        </div>
	        <img className="padding-top-3em" src={PWStyleGuide2} alt="Selected elements from the comprehensive design guide" />
	        <div className="project-text padding-top-3em">
			    <h3>Results</h3>
		        <h2 className="padding-top-05em">1,000+ entries</h2>
		        <p className="padding-top-1em">Going beyond the initial brief, we essentially created a mini social network built almost solely out of user generated content, intertwined with beautiful imagery and relevant articles from FT.com for inspiration. The website reached 20,000 people with over 1,000+ entries, boosting the readership of FT Weekend by 2% as a whole over the competition’s 2 month period.</p>	
	        </div>
	        <br />
	        <br />
	        <br />
      	</div>
      	<div className="footer-next">
  			<div className="row">
	  			<div className="column">
	  				<h6>Previous project</h6>
	      			<h4 className="padding-top-05em">
		      			<Link to="/project-hotel-prices">Hotel Prices Around the World</Link>
	      			</h4>
	      		</div>
	  			<div className="column">
	      			<h6>Next project</h6>
	      		    <h4 className="padding-top-05em">
		      			<Link to="/project-action-time">Action Time</Link>
	      		    </h4>
	      		</div>
      		</div>
      	</div>
      </div>
    )
  }
}


export default PWProjectPage;







