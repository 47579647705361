import React from 'react';
import Blog20190924VoiceExperiment from '../img/blog_20190924_voiceexperiment.mp4';
import Blog20190924Code from '../img/blog_20190924_code.jpg';
import Blog20190924PhoneScreenshot from '../img/blog_20190924_phonescreenshot.jpg';









class Blog20190924 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scrolled: 0
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollProgress);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollProgress);
  }

  scrollProgress = () => {
    const scrollPx = document.documentElement.scrollTop;
    const winHeightPx =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrolled = `${scrollPx / winHeightPx * 100}%`;

    this.setState({
      scrolled: scrolled
    });
  };

  render() {
    const progressContainerStyle = {
      background: "transparent",
      height: "2px",
      position: "relative",
      top: 0,
      left: 0,
      width: "100vw",
      zIndex: 99
    };

    const progressBarStyle = {
      height: "2px",
      background: "#000000",
      width: this.state.scrolled
    };

    return (
      <div>
        <div className="project-title">
	        <div id="title">Third Technical Prototype</div>
	        <div className="progress-container" style={progressContainerStyle}>
	          <div className="progress-bar" style={progressBarStyle} />
	        </div>
	    </div>
        <div id="project-page">
	        <div className="project-text padding-top-3em">
  	        <br />
  	        <br />
  	        	<h3>2019-09-24</h3>
  		        <p className="padding-top-1em">This weekend I made an AR prototype for Tuesday’s class using Unity and Vuforia. The concept for my AR feature is quite basic, but the technical side of it was more difficult to execute. It's still not fully working on my iPhone, but it seems to be useable on my laptop. I am testing the functionality of using my voice to control a text object in augmented reality. Sometimes the speech recogniser has trouble identifying my words. There also seems to be a bug which is causing the text to animate onto the screen twice. I’m wondering if other AI models might have better results with the speech recognition issue - I’d like to try Google Cloud Speech-to-Text next. Below is a summary of this third prototype.</p>
          </div>
          <img className="padding-top-3em" src={Blog20190924PhoneScreenshot} alt="A screenshot of the prototype built to my iPhone" />  
          <div className="project-text padding-top-2em">          
              <h2 className="padding-top-1em">What is it?</h2>   
              <p className="padding-top-1em">A technical prototype to test audio commands on augmented reality objects.</p>   
              <h2 className="padding-top-1em">Why should people care?</h2>   
              <p className="padding-top-1em">Remembering what to say during important conversations, such as presentations and interviews, is a common pain point amongst people who suffer from anxiety about public speaking.</p>               
              <h2 className="padding-top-1em">What goals does this prototype serve?</h2>   
              <p className="padding-top-1em">- Notify the user that an important topic has been mentioned</p>
              <p className="padding-top-1em">- Remind the user about related topics they need to talk about</p>
              <h2 className="padding-top-1em">What should the audience know?</h2>   
              <p className="padding-top-1em">This is a feature that can be used before an event (eg. when practising a public speech), during an event (eg. whilst doing a phone interview), and after an event (eg. when reviewing a recording of your performance).</p>     
              <h2 className="padding-top-1em">What are the constraints and/or specifications?</h2>   
              <p className="padding-top-1em">This prototype uses IBM Watson for speech-to-text recognition and Vuforia for augmented reality, but any other AI/AR technologies will do. Ideally, it will work across all devices (desktop/tablet/phone) and be accessible through a simple web browser (no need to download an app).</p>              
          </div>
          <img className="padding-top-3em" src={Blog20190924Code} alt="The IBM Watson Example Streaming code" />  
          <div className="project-text padding-top-2em">          
              <p className="padding-top-1em">Above is the IBM Watson Speech To Text streaming code which I edited to trigger text animations. As you can see below, the prototype identifies when certain keywords are spoken (ie. ‘animation’, ‘design’ and ‘illustration’), and brings up a text overlay with some bullet points about that keyword (eg. ‘animation’ brings up bullet points such as ‘storyboarding’ and ‘After Effects’). Ideally, the flow before this screen would ask the user to either upload a job description or manually enter certain keywords that they want to talk about. The user would then write some bullet points to riff upon when that keyword is mentioned (either by the users or a potential interviewer on the phone).</p>
          </div>
          <video className="padding-top-3em" playsinline="true" webkit-playsinline="true" autoPlay loop muted>
            <source src={Blog20190924VoiceExperiment} alt="A video recording of my technical prototype" type='video/mp4' />
          </video>
          <div className="project-text padding-top-2em">          
              <p className="padding-top-1em">In additional to the prototype, I worked on creating <a href="https://docs.google.com/document/d/1disKj1ETAeeBrUfqPk5BGRoDZ9TuNNqi1aYMC_UliCQ/edit?usp=sharing" target="_blank" rel="noopener noreferrer">a list of 35 people I’d like to interview</a> based upon my community of practise that I identified last week. Hopefully once I start doing interviews, I’ll have more insight into which public speaking environment or audience that I want to target (eg. big speeches, smaller presentations, one-on-one interviews, etc.).</p>
          </div>
          <div className="project-text padding-top-2em">          
              <p className="padding-top-1em">I also managed to interview one of my teammates from Cornell Tech this week about his experience with public speaking. Although he doesn’t have glossophobia, he gave me some interesting things to think about. He mentioned that, as an MBA student, talking to a lot of people isn’t a big deal for him; it’s smaller groups that scare him. He hates having to do small talk. Also, being an international student from India, he found networking events very difficult as he often didn’t have any context on topics that people often casually talk about (eg. TV shows like Seinfeld, ‘trendy’ areas in New York like Williamsburg). I found this interesting as I had always thought of good public speakers being confident in all areas of socialising. I’d like to continue talking to a range of people to get some more diverse insights on the subject.</p>
          </div>                 	        
	        <br />
	        <br />
	        <br />
	    </div>
      </div>
    )
  }
}


export default Blog20190924;







