import React from 'react';
import IconResearch from '../img/icon_research.png';
import IconInsights from '../img/icon_insights.png';
import IconArchitecture from '../img/icon_architecture.png';
import IconLowFidelity from '../img/icon_lowfidelity.png';
import IconHighFidelity from '../img/icon_highfidelity.png';
import IconProduction from '../img/icon_production.png';
import IconUserTesting from '../img/icon_usertesting.png';
import IconUp from '../img/icon_up.png';
import IconDown from '../img/icon_down.png';





class Process extends React.Component {

  			componentDidMount() {

				var descriptionTitle = document.querySelector('#descriptionTitle');
				var description1 = document.querySelector('#description1');
				var description2 = document.querySelector('#description2');
				var description3 = document.querySelector('#description3');

				document.getElementById("research").addEventListener("click", function(){
					descriptionTitle.innerHTML = "The Research Stage";
					description1.innerHTML = "<h4>Examining</h4><h5 className='padding-top-05em'>Interviewing users and experts, making field observations, and conducting competitor analyses</h5><br>";
					description2.innerHTML = "<h4>Understanding</h4><h5 className='padding-top-05em'>Creating user journey maps, user behaviour diagrams, and generating user personas</h5><br>";
					description3.innerHTML = "<h4>Empathising</h4><h5 className='padding-top-05em'>Figuring out pain points through empathy mapping and value proposition canvas exercises</h5><br>";
					this.style.opacity = "1";
					var notResearch = ['insights', 'architecture', 'testing-architecture', 'low-fidelity', 'testing-low-fidelity', 'high-fidelity', 'testing-high-fidelity', 'production'];
					for (var i = 0; i < notResearch.length; i++) {
					   document.getElementById(notResearch[i]).style.opacity = "0.3";
					}
				});

				document.getElementById("insights").addEventListener("click", function(){
					descriptionTitle.innerHTML = "The Insights Stage";
					description1.innerHTML = "<h4>Synthesising</h4><h5 className='padding-top-05em'>Generating insights and ‘How Might We’ prompts by affinity mapping the research outcomes</h5><br>";
					description2.innerHTML = "<h4>Ideating</h4><h5 className='padding-top-05em'>Facilitating participatory workshops and brainstorming sessions based on those ‘How Might We’ prompts</h5><br>";
					description3.innerHTML = "";
					this.style.opacity = "1";
					var notInsights = ['research', 'architecture', 'testing-architecture', 'low-fidelity', 'testing-low-fidelity', 'high-fidelity', 'testing-high-fidelity', 'production'];
					for (var i = 0; i < notInsights.length; i++) {
					   document.getElementById(notInsights[i]).style.opacity = "0.3";
					}
				});

				document.getElementById("architecture").addEventListener("click", function(){
					descriptionTitle.innerHTML = "The Architecture Stage";
					description1.innerHTML = "<h4>Storyboarding</h4><h5 className='padding-top-05em'>Building a product narrative and explaining concepts through text and image</h5><br>";
					description2.innerHTML = "<h4>Constructing</h4><h5 className='padding-top-05em'>Translating concepts into site maps, high-level user flows and specific task flows</h5><br>";
					description3.innerHTML = "";
					this.style.opacity = "1";
					var notArchitecture = ['research', 'insights', 'testing-architecture', 'low-fidelity', 'testing-low-fidelity', 'high-fidelity', 'testing-high-fidelity', 'production'];
					for (var i = 0; i < notArchitecture.length; i++) {
					   document.getElementById(notArchitecture[i]).style.opacity = "0.3";
					}
				});

				document.getElementById("low-fidelity").addEventListener("click", function(){
					descriptionTitle.innerHTML = "The Low-Fidelity Design Stage";
					description1.innerHTML = "<h4>Paper prototyping</h4><h5 className='padding-top-05em'>Sketching solutions and representing forms with paper</h5><br>";
					description2.innerHTML = "<h4>Wireframing</h4><h5 className='padding-top-05em'>Digitising those sketches, from low fidelity (Keynote) to mid fidelity (Figma/Sketch)</h5><br>";
					description3.innerHTML = "";
					this.style.opacity = "1";
					var notLowFidelity = ['research', 'insights', 'architecture', 'testing-architecture', 'testing-low-fidelity', 'high-fidelity', 'testing-high-fidelity', 'production'];
					for (var i = 0; i < notLowFidelity.length; i++) {
					   document.getElementById(notLowFidelity[i]).style.opacity = "0.3";
					}
				});

				document.getElementById("high-fidelity").addEventListener("click", function(){
					descriptionTitle.innerHTML = "The High-Fidelity Design Stage";
					description1.innerHTML = "<h4>Branding</h4><h5 className='padding-top-05em'>Utilising design systems and/or creating new visual guidelines from scratch</h5><br>";
					description2.innerHTML = "<h4>Animating</h4><h5 className='padding-top-05em'>Visualising movement, from transitions to microinteractions (Framer/Principle/After Effects)</h5><br>";
					description3.innerHTML = "<h4>Coding</h4><h5 className='padding-top-05em'>Building high fidelity prototypes using languages such as HTML, CSS, JavaScript and React</h5><br><br>";
					this.style.opacity = "1";
					var notHighFidelity = ['research', 'insights', 'architecture', 'testing-architecture', 'low-fidelity', 'testing-low-fidelity', 'testing-high-fidelity', 'production'];
					for (var i = 0; i < notHighFidelity.length; i++) {
					   document.getElementById(notHighFidelity[i]).style.opacity = "0.3";
					}
				});

				document.getElementById("production").addEventListener("click", function(){
					descriptionTitle.innerHTML = "The Production Stage";
					description1.innerHTML = "<h4>Exporting</h4><h5 className='padding-top-05em'>Organising all of the assets and design specs needed for build (Zeplin)</h5><br>";
					description2.innerHTML = "<h4>Iterating</h4><h5 className='padding-top-05em'>Working with engineers to make further iterations based on data and performance analytics</h5><br>";
					description3.innerHTML = "";
					this.style.opacity = "1";
					var notProduction = ['research', 'insights', 'architecture', 'testing-architecture', 'low-fidelity', 'testing-low-fidelity', 'high-fidelity', 'testing-high-fidelity'];
					for (var i = 0; i < notProduction.length; i++) {
					   document.getElementById(notProduction[i]).style.opacity = "0.3";
					}
				});
			}

			render () {

				return (

					<div className="design-process padding-top-2em">

						<div id="project-page">

							<div className="project-text">
								<h2 className="white">About my design process</h2>
								<p className="padding-top-1em white">I've found the framework below to be my preferred approach to new design challenges. I aim to integrate as many stages as possible, depending on the type of project and time/budget constraints.</p>
							</div>

							<div className="process-container padding-top-3em">
								<div className="process-flex">
									<br />
									<div className="process-row">
										<div className="spacer-118px"></div>
										<div className="spacer-80px"></div>
										<div className="spacer-118px"></div>
										<div className="spacer-80px"></div>
										<div className="spacer-118px"></div>
										<div className="spacer-80px"></div>
										<div className="process-icon" id="architecture" style={{opacity: '0.3'}}>
											<img className="icon" src={IconArchitecture} alt="Icon of a site map" />
											<h5 className="white">Architecture</h5>
										</div>
										<div className="spacer-80px"></div>
										<div className="process-icon" id="testing-architecture" style={{opacity: '0.3'}}>
											<div className="tooltip">
												<img className="icon" src={IconUserTesting} alt="Icon of a cog" />
												<span className="tooltip-text">
													<h4>User testing the navigation flow</h4>
													<h5 className="padding-top-05em">Interactive clickable prototypes to test the system architecture</h5>
												</span>
											</div>
										</div>
										<div className="spacer-80px"></div>
										<div className="spacer-118px"></div>
										<div className="spacer-80px"></div>
										<div className="spacer-118px"></div>
										<div className="spacer-80px"></div>
										<div className="spacer-118px"></div>
										<div className="spacer-80px"></div>
										<div className="spacer-118px"></div>
									</div>
									<div className="process-row">
										<div className="process-icon" id="research" style={{opacity: '1'}}>
											<img className="icon" src={IconResearch} alt="Icon of a conversation" />
											<h5 className="white">Research</h5>
										</div>
										<div className="spacer-80px"></div>
										<div className="process-icon" id="insights" style={{opacity: '0.3'}}>
											<img className="icon" src={IconInsights} alt="Icon of a magnifying glass" />
											<h5 className="white">Insights</h5>
										</div>
										<div className="spacer-80px"></div>
										<div className="process-icon" style={{opacity: '0.3'}}>
											<img className="icon" src={IconUp} alt="Icon of an arrow pointing up" />
										</div>
										<div className="spacer-80px"></div>
										<div className="spacer-118px"></div>
										<div className="spacer-80px"></div>
										<div className="spacer-118px"></div>
										<div className="spacer-80px"></div>
										<div className="process-icon" style={{opacity: '0.3'}}>
											<img className="icon" src={IconDown} alt="Icon of an arrow pointing down" />
										</div>
										<div className="spacer-80px"></div>
										<div className="process-icon" id="high-fidelity" style={{opacity: '0.3'}}>
											<img className="icon" src={IconHighFidelity} alt="Icon of a mouse cursor on a screen" />
											<h5 className="white">Hi-fi</h5>
										</div>
										<div className="spacer-80px"></div>
										<div className="process-icon" id="testing-high-fidelity" style={{opacity: '0.3'}}>
											<div className="tooltip">
												<img className="icon" src={IconUserTesting} alt="Icon of a cog" />
												<span className="tooltip-text">
													<h4>User testing in high fidelity</h4>
													<h5 className="padding-top-05em">Moderated and unmoderated tasks to test the overall experience</h5>
												</span>
											</div>
										</div>
										<div className="spacer-80px"></div>
										<div className="process-icon" id="production" style={{opacity: '0.3'}}>
											<img className="icon" src={IconProduction} alt="Icon of a cardboard box" />
											<h5 className="white">Production</h5>
										</div>
									</div>
									<div className="process-row">
										<div className="spacer-118px"></div>
										<div className="spacer-80px"></div>
										<div className="spacer-118px"></div>
										<div className="spacer-80px"></div>
										<div className="process-icon" style={{opacity: '0.3'}}>
											<img className="icon" src={IconDown} alt="Icon of an arrow pointing down" />
										</div>
										<div className="spacer-80px"></div>
										<div className="process-icon" id="low-fidelity" style={{opacity: '0.3'}}>
											<img className="icon" src={IconLowFidelity} alt="Icon of a pencil drawing on paper" />
											<h5 className="white">Lo-fi</h5>
										</div>
										<div className="spacer-80px"></div>
										<div className="process-icon" id="testing-low-fidelity" style={{opacity: '0.3'}}>
											<div className="tooltip">
												<img className="icon" src={IconUserTesting} alt="Icon of a cog" />
												<span className="tooltip-text">
													<h4>User testing in low fidelity</h4>
													<h5 className="padding-top-05em">Static polarising prototypes to test user preferences</h5>
												</span>
											</div>
										</div>
										<div className="spacer-80px"></div>
										<div className="process-icon" style={{opacity: '0.3'}}>
											<img className="icon" src={IconUp} alt="Icon of an arrow pointing up" />
										</div>
										<div className="spacer-80px"></div>
										<div className="spacer-118px"></div>
										<div className="spacer-80px"></div>
										<div className="spacer-118px"></div>
										<div className="spacer-80px"></div>
										<div className="spacer-118px"></div>
									</div>
									<br />
								</div>
							</div>

							<div className="project-text">
								<div className="padding-top-3em">
									<h3 className="white" id="descriptionTitle">The Research Stage</h3>
									<br />
									<div className="project-caption-third white" id="description1">
										<h4>Examining</h4>
										<h5 className="padding-top-05em">Interviewing users and experts, making field observations, and conducting competitor analyses</h5>
										<br />
									</div>
									<div className="project-caption-third white" id="description2">
										<h4>Understanding</h4>
										<h5 className="padding-top-05em">Creating user journey maps, user behaviour diagrams, and generating user personas</h5>
										<br />
									</div>
										<div className="project-caption-third white" id="description3">
										<h4>Empathising</h4>
										<h5 className="padding-top-05em">Figuring out pain points through empathy mapping and value proposition canvas exercises</h5>
										<br />
									</div>
								</div>
							</div>

							<br />
							<br />

		        		</div>

		        	</div>

	        	)

			}
		}
export default Process;



