import React from 'react';
import Blog20200129MidFiOverview from '../img/blog_20200129_midfioverview.png';









class Blog20200129 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scrolled: 0
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollProgress);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollProgress);
  }

  scrollProgress = () => {
    const scrollPx = document.documentElement.scrollTop;
    const winHeightPx =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrolled = `${scrollPx / winHeightPx * 100}%`;

    this.setState({
      scrolled: scrolled
    });
  };

  render() {
    const progressContainerStyle = {
      background: "transparent",
      height: "2px",
      position: "relative",
      top: 0,
      left: 0,
      width: "100vw",
      zIndex: 99
    };

    const progressBarStyle = {
      height: "2px",
      background: "#000000",
      width: this.state.scrolled
    };

    return (
      <div>
        <div className="project-title">
          <div id="title">Design Brief</div>
          <div className="progress-container" style={progressContainerStyle}>
            <div className="progress-bar" style={progressBarStyle} />
          </div>
      </div>
        <div id="project-page">
          <div className="project-text padding-top-3em">
            <br />
            <br />
              <h3>2020-01-29</h3>
              <p className="padding-top-1em">Happy New Year to everyone reading this! I feel like I haven’t updated this blog in forever, but this Wednesday was our first Thesis Studio II class after our very long Winter break. Over the holiday we were asked to write a design brief illustrating what our thesis concept is, and what challenges remain for the rest of the semester. Below is a summary of the writing and designs that I shared.</p>
          </div>
          <div className="project-text padding-top-2em">
              <h3 className="padding-top-1em">Guiding Question</h3>   
              <p className="padding-top-1em">How might we use technology to aid us with public speaking? Can we create a tool which is universally accessible to all, as well as flexible enough to be used for multiple situations?</p>              
          </div>
          <div className="project-text padding-top-2em">
              <h3 className="padding-top-1em">Mid Fidelity Design</h3>   
              <p className="padding-top-1em">My final proof of concept prototype from last semester included a storyboard of a potential product use case, a Figma prototype detailing the basic UX process, and a low-fidelity web application showcasing the core functionality of creating and reviewing flashcards. Here are some visuals from the mid fidelity Figma prototype:</p>              
          </div>
          <img className="padding-top-3em" src={Blog20200129MidFiOverview} alt="An overview of key screens from my mid fidelity design phase" />
          <div className="project-text padding-top-2em">
              <h3 className="padding-top-1em">Driving Concept</h3>   
              <p className="padding-top-1em">I am building a digital application which tackles the key phases of public speaking, from the preparation stage to the final performance. The application will provide a holistic solution that can be applied to any high-pressure public speaking situation, such as job interviews, presentations, even wedding speeches.</p>
              <p className="padding-top-1em">Users will be able to prepare flashcards on their talking points of choice and use their reminder notes to practise reciting their big speech, with the option to review their cards whilst actually performing.</p>
              <p className="padding-top-1em">Voice recognition technology will power this platform, allowing users to create flashcards on the go by dictating into their device’s microphone. Whilst practicing their content, the platform will compare the users speech to their original script, providing feedback on content accuracy as well as vocal volume and pitch.</p>
              <p className="padding-top-1em">During the final performance the user can choose to either scroll through their flashcards manually, or activate the ‘listen’ mode, where the platform will automatically show flashcards based on specific keywords it hears.</p>              
          </div>
          <div className="project-text padding-top-2em">
              <h3 className="padding-top-1em">Remaining Challenges</h3>   
              <p className="padding-top-1em">The primary challenges that I will need to address are mostly related to the technical implementation of the voice recognition, and what additional features I should include.</p>
              <p className="padding-top-1em">I am hoping to include volume, pitch and pace analysis of the users voice, and currently I have only created prototypes for volume and pitch using p5.js and ml5.js. I’m having trouble getting the prototypes to work outside of the p5 Web Editor so I will need to figure that out, as well as find a way to integrate pace analysis. I’ve identified a library called DeepAffects which may be helpful for this, but I’m planning to do some more research into alternatives.</p>
              <p className="padding-top-1em">In addition to tackling the main ‘Prepare’, ‘Practice’ and ‘Perform’ phases of public speaking, my user research showed that people who suffer from public speaking anxiety could also benefit from ‘Warm Up’ exercises (such as vocal training to exercise their voice, and improv games to get them used to impromptu questions), and ‘Calm Down’ exercises (such as guided meditation, and breathing techniques). I’m unsure about whether I will have time to implement these features, but I would ideally like to explore them further.</p>           
          </div>
          <br />
          <br />
          <br />
      </div>
      </div>
    )
  }
}


export default Blog20200129;







