import React from 'react';
import Blog20200216PersonaHannah from '../img/blog_20200216_personahannah.jpg';
import Blog20200216PersonaYe from '../img/blog_20200216_personaye.jpg';
import Blog20200216PersonaAdam from '../img/blog_20200216_personaadam.jpg';
import Blog20200216PersonaMike from '../img/blog_20200216_personamike.jpg';
import Blog20200216PersonaJamal from '../img/blog_20200216_personajamal.jpg';
import Blog20200216PersonaIrina from '../img/blog_20200216_personairina.jpg';
import Blog20200216PrioritisationMatrix from '../img/blog_20200216_prioritisationmatrix.jpg';










class Blog20200216 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scrolled: 0
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollProgress);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollProgress);
  }

  scrollProgress = () => {
    const scrollPx = document.documentElement.scrollTop;
    const winHeightPx =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrolled = `${scrollPx / winHeightPx * 100}%`;

    this.setState({
      scrolled: scrolled
    });
  };

  render() {
    const progressContainerStyle = {
      background: "transparent",
      height: "2px",
      position: "relative",
      top: 0,
      left: 0,
      width: "100vw",
      zIndex: 99
    };

    const progressBarStyle = {
      height: "2px",
      background: "#000000",
      width: this.state.scrolled
    };

    return (
      <div>
        <div className="project-title">
          <div id="title">Personas + User Journeys</div>
          <div className="progress-container" style={progressContainerStyle}>
            <div className="progress-bar" style={progressBarStyle} />
          </div>
      </div>
        <div id="project-page">
          <div className="project-text padding-top-3em">
            <br />
            <br />
              <h3>2020-02-16</h3>
              <p className="padding-top-1em">Over the past few days, I've been working on developing several use cases for my web application. As public speaking is something that almost everybody has to do at some point in their lives, my target audience is incredibly broad. I managed to narrow my 'ideal' user sets down to six specific personas, and using the six stages of public speaking that I'd previously defined, I started to map out specific actions and thoughts that the user persona would have at each stage. I also tried to summarise the user persona's primary goals, needs and challenges.</p>
          </div>
          <img className="padding-top-3em" src={Blog20200216PersonaHannah} alt="The user journey for my persona Hannah" />
          <img className="padding-top-3em" src={Blog20200216PersonaYe} alt="The user journey for my persona Ye" />
          <img className="padding-top-3em" src={Blog20200216PersonaAdam} alt="The user journey for my persona Adam" />
          <img className="padding-top-3em" src={Blog20200216PersonaMike} alt="The user journey for my persona Mike" />
          <img className="padding-top-3em" src={Blog20200216PersonaJamal} alt="The user journey for my persona Jamal" />
          <img className="padding-top-3em" src={Blog20200216PersonaIrina} alt="The user journey for my persona Irina" />          
          <div className="project-text padding-top-2em">
              <p className="padding-top-1em">Creating these different use cases was really helpful in allowing me to see what kind of features I definitely need to include in the app, and which features are perhaps too specific to a particular audience. I created a prioritisation matrix using the MOSCOW method to define what the beta version of my app will hopefully include (the 'must have' section), and what my high fidelity prototype will hopefully include (the 'should have' section). The 'could have' and 'won't have' sections are for features that would be really great to include, but that I most likely will not have the time to integrate before May.</p>           
          </div>
          <img className="padding-top-3em" src={Blog20200216PrioritisationMatrix} alt="The prioritisation matrix" />
          <br />
          <br />
          <br />
      </div>
      </div>
    )
  }
}


export default Blog20200216;







