import React from 'react';
import ImageRebecca from '../img/rebecca.jpg';
import CurrentAlbum from '../img/album.jpg';
import CurrentBook from '../img/book.jpg';
import Process from './process';
import FooterDark from './footer-dark';





class About extends React.Component {
	render () {
		return (
			<div>
				<div id="project-page">


		        	<div className="project-text-half">
				    	<div className="padding-top-2em">
				    		<h2 className="padding-top-1em">About me</h2>
	 				        <p className="padding-top-1em">I'm a multidisciplinary designer interested in exploring ways in which technology can improve people’s lives, from enhancing everyday tasks and interactions to tackling bigger social issues. By constantly learning and experimenting with emerging tech, my goal is to create playful experiences that can both engage and inspire.</p>
							<p className="padding-top-1em">Born and raised in London with a British and Caribbean background, I moved to New York in 2018 to pursue my master's degree in Design and Technology at Parsons. Prior to this, I earned my bachelor's degree in Graphic and Media Design from UAL and worked internationally as a designer in agencies within the advertising, technology and social media space.</p>
							<p className="padding-top-1em">If you'd like to get in touch, please feel free to email me at <a href="mailto:rgillclarke@gmail.com">rgillclarke@gmail.com</a> or message me on <a href="https://www.linkedin.com/in/rebeccagillclarke" target="_blank" rel="noopener noreferrer">LinkedIn</a>.</p>
					    </div>
				        <br />
				        <br />
				        <br />
						<a className="button" href="https://drive.google.com/file/d/1bHubMT_LvbtVJ-omPe6ZWoEvlOWQkkpJ/view?usp=sharing" target="_blank" rel="noopener noreferrer">View my resume</a>
					</div>
					<div className="project-text-half padding-top-3em">
						<img src={ImageRebecca} alt="Rebecca at the New York Hall of Science" style={{"padding": 0}} />
						<div className="padding-top-2em">
				        	<h4>Things I'm currently listening to:</h4>
				        	<div className="padding-top-1em">
					        	<img src={CurrentAlbum} alt="" style={{"padding": 0, width: 15 + '%', display: 'inline-block'}} />
					        	<div style={{'paddingLeft': 2 + 'em', 'boxSizing': 'border-box', width: 60 + '%', display: 'inline-block', 'verticalAlign': 'bottom'}}>
						        	<p>How I Got Over by The Roots on <a href="spotify:user:rgillclarke" target="_blank" rel="noopener noreferrer">Spotify</a></p>
						        </div>
						    </div>
				        	<div className="padding-top-1em">
					        	<img src={CurrentBook} alt="" style={{"padding": 0, width: 15 + '%', display: 'inline-block'}} />
					        	<div style={{'paddingLeft': 2 + 'em', 'boxSizing': 'border-box', width: 60 + '%', display: 'inline-block', 'verticalAlign': 'bottom'}}>
						        	<p>Outliers by Malcolm Gladwell on <a href="https://www.audible.com/listener/A3K5W0RYXU5Z2L" target="_blank" rel="noopener noreferrer">Audible</a></p>
						        </div>
						    </div>
				        </div>
					</div>
	        		<br />
	        		<br />
	        		<br />
				</div>

				<Process/>

				<FooterDark/>

			</div>
		)
	}
}
export default About;