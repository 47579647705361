import React from 'react';
import { Link, HashRouter } from "react-router-dom";
import { withRouter } from "react-router";
import { Component } from 'react';
import WMModal from './modal-workmode';
import MAModal from './modal-mariah';
import CCModal from './modal-climate';
import CKModal from './modal-coinkiller';
import TPModal from './modal-toiletprojection';








class FilterOther extends Component {

	state = {
	    showMAModal: false,
	    showWMModal: false,
	   	showCCModal: false,
	   	showCKModal: false
	  };
	  showMAModal = e => {
	    this.setState({
	      showMAModal: !this.state.showMAModal
	    });
	  };
	  showWMModal = e => {
	    this.setState({
	      showWMModal: !this.state.showWMModal
	    });
	  };
	  showCCModal = e => {
	    this.setState({
	      showCCModal: !this.state.showCCModal
	    });
	  };
	  showCKModal = e => {
	    this.setState({
	      showCKModal: !this.state.showCKModal,
	    });
	  };
	  showTPModal = e => {
	    this.setState({
	      showTPModal: !this.state.showTPModal
	    });
	  };


			render () {

				return (
					<HashRouter>
					<div className="grid-container">


						<div className='row 1'>
							<div className='column padding-top-2em'>
								<div className='thumbnail'>
									<div className='coinkiller-thumbnail' style={{cursor: 'pointer'}} onClick={e => {
							            this.showCKModal(e);
							          }}
							        >
									</div>
								</div>
								<div className="padding-top-1em" style={{width: '90%'}}>
									<a onClick={e => {
							            this.showCKModal(e);
							          }}
							        >
										<h4 style={{color: "black"}}>Coin Killer</h4>
									</a>
									<h5 className="padding-top-05em">An arcade game for Google Cardboard from an unusual point of view</h5>
								</div>
								<h6 className="padding-top-1em" style={{display: "flex", alignItems: "center"}}>
									<span className="discipline">Virtual reality</span>
								</h6>
								<CKModal onClose={this.showCKModal} show={this.state.showCKModal}>
								</CKModal>
							</div>


						    <div className='column padding-top-2em'>
								<Link to="/project-house-rules">
									<div className='thumbnail'>
										<div className='houserules-thumbnail'></div>
									</div>
								</Link>
								<div className="padding-top-1em" style={{width: '90%'}}>
									<Link to="/project-house-rules">
										<h4 style={{color: "black"}}>House Rules</h4>
									</Link>
									<h5 className="padding-top-05em">A mobile app to help roommates plan their day-to-day living</h5>
								</div>
								<h6 className="padding-top-1em" style={{display: "flex", alignItems: "center"}}>
									<span className="discipline">UX research</span>
								</h6>
							</div>


							<div className='column padding-top-2em'>
								<div className='thumbnail'>
									<div className='climate-thumbnail' style={{cursor: 'pointer'}} onClick={e => {
							            this.showCCModal(e);
							          }}
							        >
									</div>
								</div>
								<div className="padding-top-1em" style={{width: '90%'}}>
									<a onClick={e => {
							            this.showCCModal(e);
							          }}
							        >
										<h4 style={{color: "black"}}>Climate Change Week</h4>
									</a>
									<h5 className="padding-top-05em">Helping Google raise awareness on social media</h5>
								</div>
								<h6 className="padding-top-1em">
									<span className="discipline">Motion design</span>
								</h6>
								<CCModal onClose={this.showCCModal} show={this.state.showCCModal}>
								</CCModal>
							</div>
						</div>


						<div className='row 2'>
							<div className='column padding-top-2em'>
								<div className='thumbnail'>
									<div className='mariah-thumbnail' style={{cursor: 'pointer'}} onClick={e => {
							            this.showMAModal(e);
							          }}
							        >
									</div>
								</div>
								<div className="padding-top-1em" style={{width: '90%'}}>
									<a onClick={e => {
							            this.showMAModal(e);
							          }}
							        >
										<h4 style={{color: "black"}}>Can you sing like Mariah?</h4>
									</a>
									<h5 className="padding-top-05em">A fun and festive voice analysis tool using p5.js</h5>
								</div>
								<h6 className="padding-top-1em">
									<span className="discipline">Web development</span>
								</h6>
								<MAModal onClose={this.showMAModal} show={this.state.showMAModal}>
								</MAModal>
							</div>


							<div className='column padding-top-2em'>
								<Link to="/project-aeropress">
									<div className='thumbnail'>
										<div className='aeropress-thumbnail'></div>
									</div>
								</Link>
								<div className="padding-top-1em" style={{width: '90%'}}>
									<Link to="/project-aeropress">
										<h4 style={{color: "black"}}>ARopress</h4>
									</Link>
									<h5 className="padding-top-05em">Reimagining the AeroPress coffee-making experience for mobile</h5>
								</div>
								<h6 className="padding-top-1em" style={{display: "flex", alignItems: "center"}}>
									<span className="discipline">Augmented reality</span>
								</h6>
							</div>


							<div className='column padding-top-2em'>
								<Link to="/project-colour-accessibility">
									<div className='thumbnail'>
										<div className='colouraccessibility-thumbnail'></div>
									</div>
								</Link>
								<div className="padding-top-1em" style={{width: '90%'}}>
									<Link to="/project-colour-accessibility">
										<h4 style={{color: "black"}}>Is it accessible?</h4>
									</Link>
									<h5 className="padding-top-05em">A JavaScript game about colour accessibility in web design</h5>
								</div>
								<h6 className="padding-top-1em" style={{display: "flex", alignItems: "center"}}>
									<span className="discipline">Web development</span>
								</h6>
							</div>

						</div>		

						<div className='row 3'>
							<div className='column padding-top-2em'>
								<div className='thumbnail'>
									<div className='workmode-thumbnail' style={{cursor: 'pointer'}} onClick={e => {
							            this.showWMModal(e);
							          }}
							        >
									</div>
								</div>
								<div className="padding-top-1em" style={{width: '90%'}}>
									<a onClick={e => {
							            this.showWMModal(e);
							          }}
							        >
										<h4 style={{color: "black"}}>Workmode</h4>
									</a>
									<h5 className="padding-top-05em">Prototyping a productivity aid for open-plan offices</h5>
								</div>
								<h6 className="padding-top-1em" style={{display: "flex", alignItems: "center"}}>
									<span className="discipline">Physical computing</span>
								</h6>
								<WMModal onClose={this.showWMModal} show={this.state.showWMModal}>
								</WMModal>
							</div>	


							<div className='column padding-top-2em'>
								<Link to="/project-sony-pictures">
									<div className='thumbnail'>
										<div className='sonypictures-thumbnail'></div>
									</div>
								</Link>
								<div className="padding-top-1em" style={{width: '90%'}}>
									<Link to="/project-sony-pictures">
										<h4 style={{color: "black"}}>Sausage Party + Ghostbusters</h4>
									</Link>
									<h5 className="padding-top-05em">Helping Sony Pictures go viral on social media</h5>
								</div>
								<h6 className="padding-top-1em" style={{display: "flex", alignItems: "center"}}>
									<span className="discipline">Motion Design</span>
								</h6>
							</div>


							<div className='column padding-top-2em'>
								<div className='thumbnail'>
									<div className='toiletprojection-thumbnail' style={{cursor: 'pointer'}} onClick={e => {
							            this.showTPModal(e);
							          }}
							        >
									</div>
								</div>
								<div className="padding-top-1em" style={{width: '90%'}}>
									<a onClick={e => {
							            this.showTPModal(e);
							          }}
							        >
										<h4 style={{color: "black"}}>Toilet Disco</h4>
									</a>
									<h5 className="padding-top-05em">Turning my bathroom into a surface for video projection</h5>
								</div>
								<h6 className="padding-top-1em" style={{display: "flex", alignItems: "center"}}>
									<span className="discipline">Projection mapping</span>
								</h6>
								<TPModal onClose={this.showTPModal} show={this.state.showTPModal}>
								</TPModal>
							</div>
						</div>


					</div>
					</HashRouter>
	        	)
			}
		}


export default withRouter(FilterOther);