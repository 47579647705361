import React from 'react';
import Blog20200204Sketch1 from '../img/blog_20200204_sketch1.jpg';
import Blog20200204Sketch2 from '../img/blog_20200204_sketch2.jpg';
import Blog20200204Sketch3 from '../img/blog_20200204_sketch3.jpg';









class Blog20200204 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scrolled: 0
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollProgress);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollProgress);
  }

  scrollProgress = () => {
    const scrollPx = document.documentElement.scrollTop;
    const winHeightPx =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrolled = `${scrollPx / winHeightPx * 100}%`;

    this.setState({
      scrolled: scrolled
    });
  };

  render() {
    const progressContainerStyle = {
      background: "transparent",
      height: "2px",
      position: "relative",
      top: 0,
      left: 0,
      width: "100vw",
      zIndex: 99
    };

    const progressBarStyle = {
      height: "2px",
      background: "#000000",
      width: this.state.scrolled
    };

    return (
      <div>
        <div className="project-title">
          <div id="title">Final Exhibition Form?</div>
          <div className="progress-container" style={progressContainerStyle}>
            <div className="progress-bar" style={progressBarStyle} />
          </div>
      </div>
        <div id="project-page">
          <div className="project-text padding-top-3em">
            <br />
            <br />
              <h3>2020-02-04</h3>
              <p className="padding-top-1em">Below are some sketches of ideas for my final exhibition form. Ideally, audiences visiting the exhibition will be able to access the beta version of the web app through their phones, but I'd also like there to be some sort of physical display for people to look at and/or interact with. It would also be nice to show the app on a bigger screen to prove that it's responsive. These are my initial ideas - some of the concepts are a little 'out there' in terms of budget! But I'd definitely like to have some sort of projection or monitor displaying screens and interactions from the app.</p>
          </div>
          <img className="padding-top-3em" src={Blog20200204Sketch1} alt="Sketch of a projection mapping concept" />
          <img className="padding-top-3em" src={Blog20200204Sketch2} alt="Sketch of a Science Fair-esque concept" />
          <img className="padding-top-3em" src={Blog20200204Sketch3} alt="Sketch of a soundproof glass room concept" />
          <br />
          <br />
          <br />
      </div>
      </div>
    )
  }
}


export default Blog20200204;







