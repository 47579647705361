import React from 'react';
import { Link } from 'react-router-dom';
import HPFlatlay from '../img/hotelprices_flatlay.jpg';
import HPCompare from '../img/hotelprices_compare.mp4';
import HPTransitions from '../img/hotelprices_transitions.mp4';
import HPInteractions from '../img/hotelprices_interactions.mp4';
import HPStyleGuide from '../img/hotelprices_styleguide.png';
import HPTaskFlow from '../img/hotelprices_taskflow.png';
import HPGrid from '../img/hotelprices_grid.png';
import HPCards from '../img/hotelprices_cards.mp4';
import HPHighlight1 from '../img/hotelprices_highlight_1.mp4';
import HPHighlight2 from '../img/hotelprices_highlight_2.mp4';
import HPHighlight3 from '../img/hotelprices_highlight_3.mp4';
import HPHighlight4 from '../img/hotelprices_highlight_4.mp4';










class HPProjectPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scrolled: 0
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollProgress);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollProgress);
  }

  scrollProgress = () => {
    const scrollPx = document.documentElement.scrollTop;
    const winHeightPx =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrolled = `${scrollPx / winHeightPx * 100}%`;

    this.setState({
      scrolled: scrolled
    });
  };

  render() {
    const progressContainerStyle = {
      background: "transparent",
      height: "2px",
      position: "relative",
      top: 0,
      left: 0,
      width: "100vw",
      zIndex: 99
    };

    const progressBarStyle = {
      height: "2px",
      background: "#000000",
      width: this.state.scrolled
    };

    return (
      <div>
      	<div className="project-title">
	        <div id="title">A Travel Data Tool for Hotels.com</div>
	        <Link to="/"><div className="close-project"></div></Link>
	        <div className="progress-container" style={progressContainerStyle}>
	          <div className="progress-bar" style={progressBarStyle} />
	        </div>
	    </div>
        <div id="project-page">
	    	<div className="project-text padding-top-2em">
		        <h1 className="padding-top-1em">How might we transform historical travel data into an interactive visualisation tool?</h1>
		    </div>
		    <div className="project-text padding-top-2em">
		        <div className="project-caption-half padding-top-1em">
			        <h3>Context</h3>
			        <h5 className="padding-top-1em">An interactive data visualisation tool for travellers, designed for Hotels.com at Battenhall.</h5>
		        </div
		        ><div className="project-caption-half padding-top-1em">
			        <h3>Role</h3>
			        <h5 className="padding-top-1em">UX/UI and interaction design. Design team: Rebecca Gill Clarke, Aaron Griffiths, Andrea Esteban Martín.</h5>
		        </div>
		    </div>
	        <img className="padding-top-3em" src={HPFlatlay} alt="..." />		    
		    <div className="project-text padding-top-3em">
		    	<h3>Design brief</h3>
		        <h2 className="padding-top-05em">Simplifying historical data</h2>
		        <p className="padding-top-1em">Hotel Prices Around the World is an interactive tool for mobile and desktop which can help you to make the most of your travel budget and provide inspiration for your next trip. It was developed as the result of a brief from Hotels.com, who wanted to create a data visualisation with a clear call to action that could be easily shared on social media to drive sales. Historical data was pulled from their Hotel Price Index site, which houses information about hotel prices from over 30 popular destinations.</p>
		        <p className="padding-top-1em">Our challenge was to find a creative way of showcasing all of this information in a way that would engage rather than overwhelm the user. The tool needed to be functional across all devices and able to be easily updated by the Hotels.com team. It also needed to be localised for thirteen different countries, taking into account the varying data reports, language translations and currency exchange of each country.</p>
	        </div>
	    </div>
	    <div className="project-highlight" style={{backgroundColor: "#deeff5"}}>
			<div className="highlight-caption-third padding-top-3em">
			    <h3>Highlight #1</h3>
				<h2 className="padding-top-05em">Explore cities around the world</h2>
				<h5 className="padding-top-1em">An interactive city grid allows users to endlessly scroll and dig deeper into the cities that interest them. Alternatively, users can tap one of the five key links to filter the grid by a particular region.</h5>
			</div>
		    <video className="highlight-image-two-thirds" playsinline="true" webkit-playsinline="true" autoPlay loop muted>
			    <source src={HPHighlight1} alt="A screen recording of a user exploring cities on desktop" type='video/mp4' />
			</video>
			<div className="highlight-caption-third padding-top-3em">
			    <h3>Highlight #2</h3>
				<h2 className="padding-top-05em">Weigh up the options</h2>
				<h5 className="padding-top-1em">To dig deeper into multiple cities at the same time, users can add up to five destinations to their comparison bar. The comparison feature enables users to swipe or scroll through information about each city simultaneously.</h5>
			</div>
		    <video className="highlight-image-half" playsinline="true" webkit-playsinline="true" autoPlay loop muted>
			    <source src={HPHighlight2} alt="A screen recording of a user comparing cities on mobile" type='video/mp4' />
			</video>	
			<div className="highlight-caption-third padding-top-3em">
			    <h3>Highlight #3</h3>
				<h2 className="padding-top-05em">Discover nearby destinations</h2>
				<h5 className="padding-top-1em">On the back of each city data card is more information about the country as a whole. Users are able to easily explore other nearby cities from a country map (on desktop) or a dropdown menu (on mobile or tablet).</h5>
			</div>
		    <video className="highlight-image-two-thirds" playsinline="true" webkit-playsinline="true" autoPlay loop muted>
			    <source src={HPHighlight3} alt="A screen recording of a user interacting with a country map on desktop" type='video/mp4' />
			</video>
			<div className="highlight-caption-third padding-top-3em">
			    <h3>Highlight #4</h3>
				<h2 className="padding-top-05em">Make a decision</h2>
				<h5 className="padding-top-1em">Mobile and tablet users are able to easily flip cards from country side to city side, and swipe through their selected destinations. Once they've made their decision, the Book Now CTA guides them towards the appropriate Hotels.com booking page.</h5>
			</div>
		    <video className="highlight-image-half" playsinline="true" webkit-playsinline="true" autoPlay loop muted>
			    <source src={HPHighlight4} alt="A screen recording of a user swiping though cities on mobile" type='video/mp4' />
			</video>	
	    </div>
	    <div id="project-page">
			    <h3>Task Flow</h3>
		        <h2 className="padding-top-05em">Using the tool</h2>
	        <img className="padding-top-3em" src={HPTaskFlow} alt="A diagram showing the task flow of exloring hotel data using the tool" />
	        <div className="project-text-half padding-top-3em">
			    <h3>User Experience</h3>
		        <h2 className="padding-top-05em">Interactive city grid</h2>
		        <p className="padding-top-1em">On page load, users are presented with a colour-coded key which they can use to filter cities by regions such as Asia, Europe and North America. A comparison toolbar allows users to weigh up the price differences between up to four cities. Cities are added to the toolbar by clicking the add icon.</p>
		        <p className="padding-top-1em">Next to the add icon is an expand symbol which activates each city’s individual data card. The city squares are organised by average hotel price, with the most expensive city being the first and largest square.</p>
	        </div>
		    <img className="project-text-half padding-top-3em" src={HPGrid} alt="A wireframe showing the interactive city grid" />	
	        <div className="project-text-half padding-top-3em">
			    <h3>User Experience</h3>
		        <h2 className="padding-top-05em">Double-sided cards</h2>
		        <p className="padding-top-1em">The expanded city data cards are actually double-sided, with city info on one side and country info on the other. The city side features useful stats such as average hotel price, average prices by star rating, and annual percentage rises/falls.</p>
		        <p className="padding-top-1em">The country side highlights hotel prices and percentage rises/falls of the overall country, but the desktop version also incorporates a map which users can hover over to explore other cities in that country. On mobile, the map is simplified to a dropdown menu.</p>
	        </div>
		    <video className="project-text-half padding-top-3em" playsinline="true" webkit-playsinline="true" autoPlay loop muted>
    			<source src={HPCards} alt="A comparison overlay wireframe switching between city and country data" type='video/mp4' />
			</video>	
	        <div className="project-text-half padding-top-3em">
			    <h3>User Experience</h3>
		        <h2 className="padding-top-05em">Comparing destinations</h2>
		        <p className="padding-top-1em">The comparison bar allows users to view their chosen city cards in a scrollable list format. Individual cards can be removed from the list by clicking the X icon. Additionally, users can flip to the country side of their cards whilst still in comparison mode, and use the map to continue exploring other cities.</p>
		        <p className="padding-top-1em">To start over, the user can delete cards one-by-one or click the trash icon to return to the bar’s original state. The bar is preloaded with empty segments so the user knows how many cities they can compare.</p>
	        </div>
		    <video className="project-text-half padding-top-3em" playsinline="true" webkit-playsinline="true" autoPlay loop muted>
    			<source src={HPCompare} alt="Comparing hotel prices in New York, Amsterdam and Mexico City" type='video/mp4' />
			</video>	
		    <video className="padding-top-3em" playsinline="true" webkit-playsinline="true" autoPlay loop muted>
    			<source src={HPTransitions} alt="Transitioning through city and country cards" type='video/mp4' />
			</video>	
	        <div className="project-text padding-top-3em">
			    <h3>Interaction design</h3>
		        <h2 className="padding-top-05em">Swiping and flipping</h2>
		        <p className="padding-top-1em">The data cards feature some playful transitions; on desktop, the city side of the card swipes to the left and the country card slides in from the right, whilst on mobile the card flips over to reveal the country data on the other side.</p>
		        <p className="padding-top-1em">In comparison mode on mobile, we integrated a swiping transition which allows users to navigate left and right through their selection of cities, whilst still being able to flip cards over to view the country side if needed.</p>
	        </div>
		    <video className="padding-top-3em" playsinline="true" webkit-playsinline="true" autoPlay loop muted>
    			<source src={HPInteractions} alt="Mobile screen recording of the comparison bar interaction" type='video/mp4' />
			</video>	
	        <div className="project-text padding-top-3em">
			    <h3>Interaction design</h3>
		        <h2 className="padding-top-05em">Sliding and fading</h2>
		        <p className="padding-top-1em">The comparison bar also uses sliding and fading interactions when adding cities, comparing and clearing the bar. These subtle animations help to enhance basic interactions and makes the user experience feel smoother.</p>
		        <p className="padding-top-1em">Classic bar charts were used to represent hotel price stats as well as more unusual visualisations, such as the circular graphic used for the ‘average hotel price by star rating’ data.</p>
	        </div>
	        <img className="padding-top-3em" src={HPStyleGuide} alt="Showcasing the colour-coded design system" />
	        <div className="project-text padding-top-3em">
			    <h3>Visual design</h3>
		        <h2 className="padding-top-05em">Branded assets</h2>
		        <p className="padding-top-1em">We selected five colours from the Hotels.com brand palette to create the regional colour-coded key. Each region’s colour is used boldly across the interactive, from the individual city squares to the data cards, in order to create an easily understood visual system.</p>
		        <p className="padding-top-1em">The city squares use a diagonal gradient to allow cities from the same region to sit next to each other without blending into one block. We adhered to brand guidelines by using Roboto as our chosen typeface, but we designed a unique icon set and vector maps for each city.</p>
	        </div>
	        <br />
	        <br />
	        <br />
      	</div>
      	<div className="footer-next">
  			<div className="row">
	  			<div className="column">
	      			<h6>Next project</h6>
	      		    <h4 className="padding-top-05em">
		      			<Link to="/project-perfect-weekend">My Perfect FT Weekend</Link>
	      		    </h4>
	      		</div>
      		</div>
      	</div>
      </div>
    )
  }
}


export default HPProjectPage;







