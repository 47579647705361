import React from 'react';
import Blog20190904MindMap from '../img/blog_20190904_mindmap.jpg';









class Blog20190904 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scrolled: 0
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollProgress);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollProgress);
  }

  scrollProgress = () => {
    const scrollPx = document.documentElement.scrollTop;
    const winHeightPx =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrolled = `${scrollPx / winHeightPx * 100}%`;

    this.setState({
      scrolled: scrolled
    });
  };

  render() {
    const progressContainerStyle = {
      background: "transparent",
      height: "2px",
      position: "relative",
      top: 0,
      left: 0,
      width: "100vw",
      zIndex: 99
    };

    const progressBarStyle = {
      height: "2px",
      background: "#000000",
      width: this.state.scrolled
    };

    return (
      <div>
        <div className="project-title">
	        <div id="title">Mind Mapping + Goal Setting</div>
	        <div className="progress-container" style={progressContainerStyle}>
	          <div className="progress-bar" style={progressBarStyle} />
	        </div>
	    </div>
        <div id="project-page">
	        <div className="project-text padding-top-3em">
  	        <br />
  	        <br />
  	        	<h3>2019-09-04</h3>
  		        <p className="padding-top-1em">Last week in class, I made a mind map around my central theme of glossophobia. The first level associations are based on themes of professional environments (work, school, etc.), social environments (parties, weddings, etc.), mental states and physical states when experiencing the fear of public speaking. Mapping out my thoughts related to these areas helped me to think about which area I might want to focus on for my first prototype, as it will be difficult to create something that encompasses everything.</p>
	        </div>
	        <img className="padding-top-3em" src={Blog20190904MindMap} alt="My glossophobia mind map" />  
	        <div className="project-text padding-top-2em">
		        <p className="padding-top-1em">This week, I started to set some goals for myself, past and present, in order to ensure that my project is progressing at a steady rate. Here’s what I have so far:</p>
	        </div>
	        <div className="project-text padding-top-3em">
            <h2>What did I accomplish last week?</h2>
		        <p className="padding-top-1em">- I decided to focus my thesis on the topic of glossophobia (the fear of public speaking).</p>
		        <p className="padding-top-1em">- I did some research into existing digital products and HCI experiments done to help people with their fears and anxieties, and <a href="https://docs.google.com/document/d/1upzDMcaoCcI1lW_OM5JKR0Ni0wY1oxFQPf40FpGlHeY/edit?usp=sharing" target="_blank" rel="noopener noreferrer">started a research paper about it</a>.</p>
		        <p className="padding-top-1em">- I started to think about solutions that are not yet in existence, using technologies which are not typically associated with speaking (augmented reality).</p>
            <p className="padding-top-1em">- I created a mind map to get all my thoughts down about this topic so far.</p>
	        </div>
          <div className="project-text padding-top-3em">
            <h2>What do I need to do this week?</h2>
            <p className="padding-top-1em">- I need to define my audience (eg. people that suffer from glossophobia, people that are already confident with public speaking, etc.) and the situations I want to explore (public speaking to large groups, phone interviews, social introductions at parties, etc). If I don’t define my audience quickly, it will be difficult to start conducting interviews and ideating solutions to potential problems.</p>
            <p className="padding-top-1em">- I need to create an interview guide with questions that I want to ask my defined user/audience sets.</p>
            <p className="padding-top-1em">- I need to start listing the potential technologies that I might want to use, so that I can figure out which methods would be best suited for each problem I’m trying to solve.</p>
          </div>
          <div className="project-text padding-top-3em">
            <h2>What do I need to do next week?</h2>
            <p className="padding-top-1em">- I will interview at least one person from one of my defined user/audience sets.</p>
            <p className="padding-top-1em">- Based on feedback from this interview/s, I need to define at least one problem to address with a prototype.</p>
            <p className="padding-top-1em">- I will start sketching possible solutions to this problem, perhaps storyboarding an experience.</p>
            <p className="padding-top-1em">- I will pick which technology from my list is best suited to address this problem, and begin actually creating a higher definition or technical prototype.</p>
          </div>
          <div className="project-text padding-top-3em">
            <h2>What are my overall semester goals?</h2>
            <p className="padding-top-1em">- Throughout all of my classes, I will take notes on instructor and student presentations - observing their mannerisms, what they spoke about well, what received a good response from the audience, etc. This will help me to identify what makes a good public speaker.</p>
            <p className="padding-top-1em">- I will improve my Unity skills and general knowledge of AR/VR</p>
            <p className="padding-top-1em">- I will learn a 3D modelling program (probably Blender)</p>
            <p className="padding-top-1em">- I will learn a digital data visualisation program (D3.js?)</p>
            <p className="padding-top-1em">- I will become more familiar with a voice to text AI (IBM Watson?)</p>
            <p className="padding-top-1em">- I will visit the XReality Center, as I haven’t been yet <span role="img" aria-label="grimace">😬</span></p>
          </div>          
	        <div className="project-text padding-top-3em">
		        <p className="padding-top-1em">I’d like to try and check in with these goals on a weekly basis using this blog. If I don’t manage to accomplish everything I planned for that week, those goals will carry over to the next week. As for the overall semester goals, most of them (Unity, data visualisation and visiting the XReality Center) will be handled through my elective classes and assignments. I started an online intro class to Blender this Labor Day, so I’ll try and continue with that on the weekends. It would be good to try modeling something in 3D that is related to my project.. Hopefully soon I’ll have some ideas about what to model!</p>
	        </div>		        
	        <br />
	        <br />
	        <br />
	    </div>
      </div>
    )
  }
}


export default Blog20190904;







