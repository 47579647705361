import React from 'react';
import Blog20200305Intro1 from '../img/blog_20200306_intro1.jpg';
import Blog20200305Intro2 from '../img/blog_20200306_intro2.jpg';
import Blog20200305Intro3 from '../img/blog_20200306_intro3.jpg';
import Blog20200305Moodboard from '../img/blog_20200306_moodboard.jpg';
import Blog20200305StyleTile from '../img/blog_20200306_styletile.gif';











class Blog20200306 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scrolled: 0
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollProgress);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollProgress);
  }

  scrollProgress = () => {
    const scrollPx = document.documentElement.scrollTop;
    const winHeightPx =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrolled = `${scrollPx / winHeightPx * 100}%`;

    this.setState({
      scrolled: scrolled
    });
  };

  render() {
    const progressContainerStyle = {
      background: "transparent",
      height: "2px",
      position: "relative",
      top: 0,
      left: 0,
      width: "100vw",
      zIndex: 99
    };

    const progressBarStyle = {
      height: "2px",
      background: "#000000",
      width: this.state.scrolled
    };

    return (
      <div>
        <div className="project-title">
          <div id="title">Midterm Reviews + ProtoPie</div>
          <div className="progress-container" style={progressContainerStyle}>
            <div className="progress-bar" style={progressBarStyle} />
          </div>
      </div>
        <div id="project-page">
          <div className="project-text padding-top-3em">
            <br />
            <br />
              <h3>2020-03-06</h3>
              <p className="padding-top-1em">This week we had midterm reviews, where faculty went over our progress so far and offered a last round of feedback before the final slog. I’m currently a little stuck with my web prototype - I’m still trying to figure out a new way of plotting the volume and pitch graphs, as p5 doesn’t seem to be working for me outside of the editor. So for midterm, I decided to concentrate on perfecting the high fidelity designs.</p>
              <p className="padding-top-1em">I chose to present the different features of the app through the eyes of three of my personas; Hannah, Jamal and Ye. I decided to try out the prototyping tool ProtoPie for mocking up my interactions, and I really liked it - the interface is really easy to use, and it took me less than a day to figure out most of the functions. I particularly liked the formulas!</p>    
              <p className="padding-top-1em">Here are some excerpts from my presentation below.</p>    
          </div>      
          <img className="padding-top-3em" src={Blog20200305Intro1} alt="A slide showing the desktop version of the app with a description" />
          <img className="padding-top-1em" src={Blog20200305Intro2} alt="A slide showing the mobile default screen of the app with a description" />
          <img className="padding-top-1em" src={Blog20200305Intro3} alt="A slide showing the mobile voice analysis part of the app with a description" />
          <img className="padding-top-1em" src={Blog20200305Moodboard} alt="A slide showing a moodboard of typography choices, colour scheme and inspirational images" />
          <img className="padding-top-1em" src={Blog20200305StyleTile} alt="A style tile gif showing the light and dark modes of the app" />
          <br />
          <br />
          <br />
      </div>
      </div>
    )
  }
}


export default Blog20200306;







