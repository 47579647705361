import React from 'react';
import Blog20191103PrepareResearch from '../img/blog_20191103_prepareresearch.jpg';
import Blog20191103PreparePrototype from '../img/blog_20191103_prepareprototype.jpg';
import Blog20191103PrepareTest from '../img/blog_20191103_preparetest.jpg';
import Blog20191103PractiseResearch from '../img/blog_20191103_practiseresearch.jpg';
import Blog20191103PractisePrototype from '../img/blog_20191103_practiseprototype.jpg';
import Blog20191103WarmUpResearch from '../img/blog_20191103_warmupresearch.jpg';
import Blog20191103WarmUpPrototype from '../img/blog_20191103_warmupprototype.jpg';
import Blog20191103WarmUpTest from '../img/blog_20191103_warmuptest.jpg';
import Blog20191103CalmDownResearch from '../img/blog_20191103_calmdownresearch.jpg';
import Blog20191103CalmDownPrototype from '../img/blog_20191103_calmdownprototype.gif';
import Blog20191103CalmDownTest from '../img/blog_20191103_calmdowntest.jpg';
import Blog20191103PerformResearch from '../img/blog_20191103_performresearch.jpg';
import Blog20191103PerformPrototype from '../img/blog_20191103_performprototype.jpg';
import Blog20191103PerformTest from '../img/blog_20191103_performtest.jpg';
import Blog20191103ReflectResearch from '../img/blog_20191103_reflectresearch.jpg';
import Blog20191103ReflectPrototype from '../img/blog_20191103_reflectprototype.jpg';
import Blog20191103WildcardResearch from '../img/blog_20191103_wildcardresearch.jpg';
import Blog20191103WildcardPrototype from '../img/blog_20191103_wildcardprototype.jpg';
import Blog20191103WildcardTest from '../img/blog_20191103_wildcardtest.jpg';










class Blog20191103 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scrolled: 0
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollProgress);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollProgress);
  }

  scrollProgress = () => {
    const scrollPx = document.documentElement.scrollTop;
    const winHeightPx =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrolled = `${scrollPx / winHeightPx * 100}%`;

    this.setState({
      scrolled: scrolled
    });
  };

  render() {
    const progressContainerStyle = {
      background: "transparent",
      height: "2px",
      position: "relative",
      top: 0,
      left: 0,
      width: "100vw",
      zIndex: 99
    };

    const progressBarStyle = {
      height: "2px",
      background: "#000000",
      width: this.state.scrolled
    };

    return (
      <div>
        <div className="project-title">
	        <div id="title">7 Prototypes in 7 Days</div>
	        <div className="progress-container" style={progressContainerStyle}>
	          <div className="progress-bar" style={progressBarStyle} />
	        </div>
	    </div>
        <div id="project-page">
	        <div className="project-text padding-top-3em">
  	        <br />
  	        <br />
  	        	<h3>2019-11-03</h3>
  		        <p className="padding-top-1em">This blog is all about my 7 in 7 assignment, in which we were asked to create a seven prototypes over the course of seven days. Each day’s prototype poses and answers a question concerning a particular assumption I have about my thesis project. The goal was to start and finish each prototype in a single day, so as a result, most of the prototypes are quite low fidelity. I decided to approach my prototypes in terms of the six key stages of public speaking which I had previously identified; Prepare, Practice, Warm up, Calm down, Perform and Reflect. The seventh prototype was a wildcard option which I wanted to try out.</p>
              <p className="padding-top-1em">Although I haven’t tested all of my prototypes with a wide audience, the one that seemed to gain the most interest so far was the memorization game. I was surprised that people seemed to understand the concept, as the Leitner system is not something that I personally was familiar with before this project. The prototype that gained the least engagement was the first UI paper prototype exercise, which coincidentally was one of the most time consuming prototypes to make. In hindsight I think I probably should have made a wider variety of screens for people to give their feedback on, instead of just the two directions I presented. This also could have easily been an on-screen exercise instead of paper, which was very fiddly and tedious to make.</p>
          </div>



          <div className="project-text padding-top-2em">          
              <h2 className="padding-top-1em">1. 'Prepare' prototype</h2> 
              <h4 className="padding-top-1em">How should my audience set their public speaking goals?</h4>     
          </div>              
          <img className="padding-top-3em" src={Blog20191103PrepareResearch} alt="Researching into existing apps" />
          <div className="project-text padding-top-1em">
              <p className="padding-top-1em">This prototype addresses the preparation stage of public speaking. I wanted to try and find the best UI onboarding experience for a mobile app. As public speaking spans many situations, the user would need to input their specific goal (eg. presenting slides, networking, etc.). The user might also want to specify how big their audience will be, as the experience of giving a speech in front of 5 or 6 people is very different to 100+ people. Finally, the user would need to input the specific time and date of their public speaking event, so that the app can notify them to practice in time. Above is my research into existing onboarding screens from some of my favourite apps.</p>
          </div>
          <img className="padding-top-3em" src={Blog20191103PreparePrototype} alt="The paper prototypes" />
          <div className="project-text padding-top-2em">          
              <p className="padding-top-1em">I decided to create paper prototypes with various UI elements for my user testers to play with. I had two specific flows in mind; the first being a textual design in which users edit pre filled fields using dropdown menus and sliders, and the second being a visual design in which users swipe through illustrations of their goals and adjust the details on later screens. My user testers seemed to create similar designs to the ones I’d initially thought of, but this was probably because there wasn’t much opportunity to create something new! Overall, I didn’t really learn that much from this prototype. However when asked which design they preferred, most people said that the visual option was more appealing. In moving forward with my final concept, I’ll probably try to make these onboarding screens more visual.</p>
          </div>   
          <img className="padding-top-3em" src={Blog20191103PrepareTest} alt="Testing the paper prototype exercise with a friend" />



          <div className="project-text padding-top-2em">          
              <h2 className="padding-top-1em">2. 'Practise' prototype</h2> 
              <h4 className="padding-top-1em">How can I help my audience memorise their content?</h4>                   
              <p className="padding-top-1em">I’ve been doing some research into various memorization techniques, and one style that stood out to me was the Leitner System. There are a few old-school software programs that follow this style of revision, such as Anki, Mnemosyne, and SuperMemo. These programs allow you to create digital flashcards and rate how well you know the content of the cards. Based on this rating, the program’s algorithm will generate the perfect pattern of cards to test you with. Lower rated cards will be shown more frequently, as these are the cards that need the most practise. Higher rated cards will still be shown but at less frequent intervals, as you already know the content pretty well. I’ve personally never used programs like these but apparently they are widely used by medical and language students. I wanted to emulate these techniques of spaced repetition and active recall testing in an AR environment, as the act of somatic learning (moving and doing) is a method of study that many of these programs do not incorporate.</p>
          </div>
          <img className="padding-top-3em" src={Blog20191103PractiseResearch} alt="Researching different methods of memorisation" />
          <div className="project-text padding-top-2em">          
              <p className="padding-top-1em">I created some fake flashcards for this prototype, with the front showing bullet points of what the user wants to talk about, and the back showing the actual script of exactly what they want to say. I decided to include the script element as many of the non-native English speakers I interviewed said how difficult it was to speak with the correct grammar. My idea is that these cards would not be actually printed, but would be made out of a whiteboard-like material that you write on with a marker. The AR part of the experience is activated using image targets printed onto the card boxes. Scanning the targets using the app will recreate the scene in front of you in augmented reality. Instead of moving the card in real-life, the user would drag the digital card into the AR box of their choosing.</p>
              <p className="padding-top-1em">The principle behind doing this digitally is that the app’s algorithm would be able to ‘shuffle’ the cards into a specific order, and provide the user with a prioritised deck of cards based on the time/date of their event. The app could also notify users with reminders to practice, and adjust the prioritised card deck based on how frequently they actually practised (eg. if the user ends up practising only twice, then it makes sense that during that last session the lower rated cards are shown over and over again in order to ‘cram’ the information in a short space of time).</p>              
          </div>   
          <img className="padding-top-3em" src={Blog20191103PractisePrototype} alt="The AR Leitner System prototype" />
          <div className="project-text padding-top-2em">          
              <p className="padding-top-1em">I received a lot of positive engagement from this prototype; many users hadn’t tried this method of study before, and thought that it would be quite effective. They also liked the gamified elements that I added into the prototype, such as the points score and the money sound when a card is dropped into a box. However, a few people noted that the AR part of it was redundant - the same technique could work just as well as a regular app or even a physical game. This final attempt at AR will probably be my last, as I’m realising now that I need to stop forcing this technology into all of my prototypes! For my final concept, I will design a similar practise experience without the use of augmented reality.</p>           
          </div>            



          <div className="project-text padding-top-2em">          
              <h2 className="padding-top-1em">3. 'Warm up' prototype</h2> 
              <h4 className="padding-top-1em">Could improv exercises help my audience feel more comfortable at networking events?</h4>
          </div>
          <img className="padding-top-3em" src={Blog20191103WarmUpResearch} alt="Pictures from the Improv NOW! Meetup" />
          <div className="project-text padding-top-1em">
              <p className="padding-top-1em">When getting ready to speak at an event, sometimes it’s helpful to loosen up both your voice and your inhibitions in order to get into the right frame of mind for public speaking. In the case of a networking event, a person suffering from public speaking anxiety might find it difficult to spark up a conversation with a stranger. I recently attended a Meetup event called Improv NOW! which focuses on long-form improv workshops for people who are looking to become more comfortable and spontaneous with public speaking. Above are some pictures from the event, and some of the many activities that we did throughout the evening.</p>
          </div>
          <img className="padding-top-3em" src={Blog20191103WarmUpPrototype} alt="The improv game prototype" />
          <div className="project-text padding-top-2em">          
              <p className="padding-top-1em">Although I was a bit apprehensive about attending, the workshop was actually a lot of fun and it inspired me to create this fun 2-player improv game, geared towards shy audiences who are attending networking events. To start the game, both players will choose a card stating a random person’s name. Each player must then take on the character of this imaginary person, and have a conversation based on a specific networking scenario (for example, A might be interested in working at B’s company). As time goes on, more cards are given to the players stating their character’s job, hometown and passion. The players must adapt the conversation to relay this new information to their partner in a natural way. The main rule of the game is that players should respond positively to whatever is said to them, as during real improv, literally anything goes and nothing is wrong or silly.</p>
          </div>   
          <img className="padding-top-3em" src={Blog20191103WarmUpTest} alt="Testing the game with two friends" />          
	        <div className="project-text padding-top-2em">          
              <p className="padding-top-1em">I tested the game with two of my friends right before we attended a university networking event. Both thought that the game was fun to play, but neither thought that it made much of a difference to the way they acted during the actual event. One reason for that might be that the game asks both players to take on the role of a character - perhaps only one person should be acting, and the other person should just play themselves. Another comment I got was to add a personality card into the mix (although perhaps this might take away from the improvisation aspect of the game), as well as introducing some ‘random’ events during the scenario such as a dog passing by or a loud noise. Overall people seemed to react positively to this prototype, so I might consider including improv as a warm up exercise as I move forward with my final concept.</p>
          </div>  



          <div className="project-text padding-top-2em">          
              <h2 className="padding-top-1em">4. 'Calm down' prototype</h2> 
              <h4 className="padding-top-1em">Can breathing techniques help to calm my audience’s nerves?</h4>                   
              <p className="padding-top-1em">Since the midterm presentations, I’ve been thinking about ways to incorporate mindfulness and meditation into my concept somehow. I did some research into different methods of meditation, and I found out about various deep breathing techniques that are commonly used to ease anxiety. I wanted to try and recreate one of these breathing exercises using Google Cardboard, as VR is a medium that I haven’t really explored yet and Google Cardboard is an affordable alternative to more expensive VR headsets. This concept is building upon a previous prototype I created which connects an Arduino pulse sensor with an augmented reality object, showing the users heart rate in beats per minute. In this prototype, I am simulating a similar scene but this time in virtual reality, using a smartphone camera instead of an Arduino to measure heart rate whilst guiding the user through a breathing exercise. The BPM rating isn’t actually real, but I wanted to simulate the heart rate going down in order to gauge whether it would have an actual effect on the user experience.</p>
          </div>
          <img className="padding-top-3em" src={Blog20191103CalmDownResearch} alt="Research into breathing techniques" />
          <div className="project-text padding-top-2em">          
              <p className="padding-top-1em">I tested this prototype with a couple of friends, and both seemed to enjoy being in this calming virtual environment. Surprisingly, both said that the heart rate going down wasn’t very important to them, and one commented that she’d prefer to see this data after the breathing meditation had ended (perhaps as some sort of data visualisation). Some additional comments I got were to add customisable music depending on what the person finds relaxing, and to have different types of meditation to choose from based on the person’s anxiety level. Both noted that the VR aspect of the prototype isn’t very convenient for someone feeling anxious right before an event, but perhaps the same experience could be recreated in a mobile app with headphones. Overall, this prototype received good engagement and I would like to integrate it into my final concept.</p>
          </div>   
          <img className="project-text-half padding-top-3em" src={Blog20191103CalmDownPrototype} alt="The virtual visualisation" />
          <img className="project-text-half padding-top-3em" src={Blog20191103CalmDownTest} alt="Testing the VR prototype" />



          <div className="project-text padding-top-2em">          
              <h2 className="padding-top-1em">5. 'Perform' prototype</h2> 
              <h4 className="padding-top-1em">What kind of tool could help my audience in the moment?</h4>    
          </div>
          <img className="padding-top-3em" src={Blog20191103PerformResearch} alt="Researching tactile presentation props" />
          <div className="project-text padding-top-1em">                                       
              <p className="padding-top-1em">For this prototype, I was thinking about various objects that could possibly calm people’s anxiety whilst also playing a role during the physical act of public speaking. Clicker remotes are commonly used to change slides during presentations, but their hardware and functionality hasn’t really evolved much over the years (although there are some interesting ring designs on the market). In terms of reducing anxiety, many people use fidget toys as a distraction and a way to release nervous energy. Fidget spinners were huge a few years ago, and there are many other fun little gadgets on the market with various knobs, buttons and switches for people to fiddle with. In this prototype, I decided to fuse the two and design a fidget clicker for presentations.</p>
          </div>
          <img className="padding-top-3em" src={Blog20191103PerformPrototype} alt="The fidget clicker prototype" />
          <div className="project-text padding-top-2em">          
              <p className="padding-top-1em">The clicker has a double sided design with two handy LED screens; one displays the upcoming and previous slides so users are aware of what’s coming next, and the other displays the users bullet point speaker notes in case they forget what they’ve planned to say. There is a laser pointer to allow users to highlight specific areas on screen. Along the sides of the remote, there are sliders, buttons, rollerballs and joysticks for users to twiddle with whilst presenting to ease their nerves. They have no functionality, but are specifically positioned on the sides for easy access and so as not to compete with the actual functional buttons on the front and back of the clicker. I shared this prototype with some friends and it received a good response, although the ‘fidget’ features were not immediately recognised due to them being flat 2D drawings instead of real 3D components. I’m not sure if a physical device is something that I want to pursue for my final concept, but I think the idea is interesting and I would like to get some more opinions on it.</p>
          </div>   
          <img className="padding-top-3em" src={Blog20191103PerformTest} alt="Testing the clicker with friends" />




          <div className="project-text padding-top-2em">          
              <h2 className="padding-top-1em">6. 'Reflect' prototype</h2> 
              <h4 className="padding-top-1em">How can my audience track their progress with public speaking?</h4>                   
              <p className="padding-top-1em">In thinking about ways to track progress, I decided to map out all of the possible data points that a public speaking app could track and analyse. I found that the practise and performance stages would most likely be tracking similar things, such as the pace/pitch of your voice, and how accurate your spoken content is compared to your written notes. I also recently attended a Toastmasters meeting, and I discovered that keeping to a specific time limit is a big pain point for this particular audience. Keeping these things in mind, I decided to design a screen which allows users to view data about their progress with public speaking.</p>
          </div>
          <img className="padding-top-3em" src={Blog20191103ReflectResearch} alt="Mapping out possible data points" />
          <div className="project-text padding-top-2em">          
              <p className="padding-top-1em">Below is the screen prototype so far. At the top, the user can choose which ‘big talk’ they want to view, as some people might practice for multiple public speaking goals within the same time frame. Once the user has selected which goal they want to view, they can either access the data from their practice sessions or from the actual event. Being able to record and review how their actual performance went could be helpful for future improvements. If the user hasn’t yet ‘performed’ their big talk, then they can view their overall progress during all practice sessions or choose to focus on one particular session. At the bottom I’ve included some stats on the user’s overall progress since using the app, such as the total number of hours they’ve practised and their favourite in-app exercises. I haven’t tested this prototype with anyone yet, but I’m curious to hear people’s feedback. I’m thinking that there could be a gamified aspect to this progress screen, such as a community leaderboard and/or progress badges.</p>
          </div>   
          <img className="padding-top-3em" src={Blog20191103ReflectPrototype} alt="The screen prototype" />




          <div className="project-text padding-top-2em">          
              <h2 className="padding-top-1em">7. The Wildcard</h2> 
              <h4 className="padding-top-1em">Can I replicate the anxiety that my audience feels on stage?</h4>    
          </div>
          <img className="padding-top-3em" src={Blog20191103WildcardResearch} alt="Other technology helmet precedents" />
          <div className="project-text padding-top-1em">                                       
              <p className="padding-top-1em">This final prototype doesn’t fit into any of the categories, but is instead addressing the issue of my audience not feeling the same anxiety whilst practising their public speaking as they do when actually performing in the moment. I wanted to try and find a way to make people feel anxious during or right before practice, so that they are able to adjust to performing with that nervous energy and perhaps find a way to use it to their advantage. I had the idea of creating some kind of anxiety helmet, so I began looking at similar precedents from artists such as Ani Liu and Joshua Smith. I was also inspired by the sliding covers that many people use on their phones and laptops to block their camera from seeing them.</p>
          </div>
          <img className="padding-top-3em" src={Blog20191103WildcardPrototype} alt="Making the helmet" />
          <div className="project-text padding-top-2em">          
              <p className="padding-top-1em">The concept behind this prototype is that when the user is alone, the sliding eye panel stays closed, but when it senses that other people are nearby or that someone is looking directly at the person wearing this weird helmet thing, the sliding panel will automatically open, leaving the user feeling exposed and vulnerable. I chose pink because it’s a loud colour that might hopefully draw attention from passers by, and I drew squiggles on the inside of the eye panel to try and make the user feel uncomfortable even whilst in the privacy of the helmet. I tested this prototype out with a friend standing in the middle of Fifth Ave, but even in this busy area the helmet didn’t seem to draw much attention from passers by. She commented that although she did feel a bit strange wearing it, she was able to see through a gap in the panel that nobody was really looking at her. If I were to redo this prototype, I would create a more robust helmet with no way of seeing through it, and I would perhaps decorate the outside a bit more to grab people’s attention. Although I probably won’t continue with this idea for my final concept, it was a fun prototype to make and try out.</p>
          </div>   
          <img className="padding-top-3em" src={Blog20191103WildcardTest} alt="Testing the helmet with a friend" />



          <br />
	        <br />
	        <br />
	    </div>
      </div>
    )
  }
}


export default Blog20191103;







