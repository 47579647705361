import React from "react";
import PropTypes from "prop-types";
import WMPhysicalPrototype from '../img/workmode_physicalprototype.jpg';
import WMVideo from '../img/workmode_video.mp4';
import WMJavaScript from '../img/workmode_js.jpg';
import WMProduction1 from '../img/workmode_production1.jpg';
import WMProduction2 from '../img/workmode_production2.jpg';






export default class Modal extends React.Component {
  onClose = e => {
    this.props.onClose && this.props.onClose(e);
    document.getElementById("backdrop").style.display = "none";
    document.getElementById("footer-light").style.display = "block";
  };
  render() {
    if (!this.props.show) {
      return null;
    }
    document.getElementById("backdrop").style.display = "block";
    document.getElementById("footer-light").style.display = "none";
    return (
      <div className="modal" id="modal">
        <div className="content">
        	<div className="project-text padding-top-2em">
        		<h2>Prototyping a productivity aid</h2>
            <a className="close" onClick={this.onClose}></a>
        	</div>
	        <div className="project-text">
		        <p className="padding-top-1em">Workmode is a productivity system for people who have a list of tasks to do, and just need some quiet time in order to get everything done. It's ideal for social working environments such as open-plan offices, where employees are likely to get easily distracted by coworkers.</p>
		        <p className="padding-top-1em">The system is composed of two elements; a task management website and a modern desk light. The website has a to-do list of tasks which users can add to, cross off and delete. As their to-do list shrinks, the desk light gradually changes from red (do not disturb) to green (happy to chat). The user can also change this manually if they wish.</p>
		        <p className="padding-top-1em">The user can access Workmode through their browser. The desk light functionality is built from an RGB LED connected to an Arduino. The light is powered by USB connected to the user’s computer, and is controlled using serial communication with Node.js.</p>
	        </div>
	        <img className="padding-top-3em" src={WMProduction1} alt="Sketching/building the Arduino functionality" />
          <img className="padding-top-3em" src={WMProduction2} alt="3D printing the light pyramid and crafting the box" />
	        <img className="padding-top-3em" src={WMJavaScript} alt="Coding the website in vanilla JavaScript" />
	         <video className="padding-top-3em" playsinline="true" webkit-playsinline="true" autoPlay loop muted>
    			   <source src={WMVideo} alt="Screen recording of the website" type='video/mp4' />
			     </video>
	        <img className="padding-top-3em" src={WMPhysicalPrototype} alt="The Workmode desk light and website" />
	        <div className="project-text">
	            <br />
        		<br />
        		<a className="button" onClick={this.onClose}>Close</a>
        	</div>
        </div>
        <br />
        <br />
      </div>
    );
  }
}
Modal.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired
};


