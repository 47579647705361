import React from 'react';
import Tabs from './tabs';
import FooterLight from './footer-light';







class Home extends React.Component {

	state = {
	    showMAModal: false,
	    showWMModal: false,
	   	showCCModal: false,
	   	showCKModal: false
	  };
	  showMAModal = e => {
	    this.setState({
	      showMAModal: !this.state.showMAModal
	    });
	  };
	  showWMModal = e => {
	    this.setState({
	      showWMModal: !this.state.showWMModal
	    });
	  };
	  showCCModal = e => {
	    this.setState({
	      showCCModal: !this.state.showCCModal
	    });
	  };
	  showCKModal = e => {
	    this.setState({
	      showCKModal: !this.state.showCKModal,
	    });
	  };


	render () {
		
		return (
			<div class="footer-container">

				<div id="intro">
					<div id="backdrop"></div>
		        	<div className="project-text">
				    	<div className="padding-top-2em">
					        <h1 className="padding-top-1em">Hello! I'm Rebecca.</h1>
					        <p className="padding-top-1em">Most recently a product designer for <a className="plain-link" href="https://actionbutton.org/" target="_blank" rel="noopener noreferrer">Action Button</a>, I'm now finishing up my Masters degree in Design and Technology at Parsons. Currently based in New York.</p>
					    </div>
					</div>

				</div>

				<Tabs/>

				<FooterLight/>

			</div>
		)
	}
}
export default Home;




