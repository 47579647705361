import React from 'react';
import { Link, HashRouter } from "react-router-dom";
import { Component } from 'react';





class FilterSelected extends Component {

	state = {
	   	showCCModal: false
	  };
	  showCCModal = e => {
	    this.setState({
	      showCCModal: !this.state.showCCModal
	    });
	  };

			render () {

				return (
					<HashRouter>
					<div className="grid-container">
						<div className='row'>
						    <div className='column padding-top-2em'>
								<Link to="/project-hotel-prices">
									<div className='thumbnail'>
										<div className='hotelprices-thumbnail'></div>
									</div>
								</Link>
								<div className="padding-top-1em" style={{width: '75%'}}>
									<Link to="/project-hotel-prices">
										<h4 style={{color: "black"}}>Hotel Prices Around the World</h4>
									</Link>
									<p className="padding-top-05em">How might we transform historical travel data into an interactive visualisation tool?</p>
									<h6 className="padding-top-1em">Client: Hotels.com</h6>
								</div>
							</div>


							<div className='column padding-top-2em'>
								<Link exact to="/project-perfect-weekend">
									<div className='thumbnail'>
										<div className='perfectweekend-thumbnail'></div>
									</div>
								</Link>
								<div className="padding-top-1em" style={{width: '75%'}}>
									<Link to="/project-perfect-weekend">
										<h4 style={{color: "black"}}>My Perfect FT Weekend</h4>
									</Link>
									<p className="padding-top-05em">How might we promote user generated content as a form of competition entry?</p>
									<h6 className="padding-top-1em">Client: Financial Times</h6>
								</div>
							</div>
						</div>


						<div className='row'>
							<div className='column padding-top-2em'>
								<Link to="/project-action-time">
									<div className='thumbnail'>
										<div className='actiontime-thumbnail'></div>
									</div>
								</Link>
								<div className="padding-top-1em" style={{width: '75%'}}>
									<Link to="/project-action-time">
										<h4 style={{color: "black"}}>Action Time</h4>
									</Link>
									<p className="padding-top-05em">How might we inspire slacktivists to take action on causes they care about?</p>
									<h6 className="padding-top-1em">Client: Speakable</h6>
								</div>
							</div>


						    <div className='column padding-top-2em'>
								<Link to="/project-slider-quiz">
									<div className='thumbnail'>
										<div className='sliderquiz-thumbnail'></div>
									</div>
								</Link>
								<div className="padding-top-1em" style={{width: '75%'}}>
									<Link to="/project-slider-quiz">
										<h4 style={{color: "black"}}>The Radial Dial</h4>
									</Link>
									<p className="padding-top-05em">How might we engage and educate users through playful interaction design?</p>
									<h6 className="padding-top-1em">Client: Action Button</h6>
								</div>
							</div>
						</div>


						<div className='row 2'>
						    <div className='column padding-top-2em'>
								<Link to="/project-big-talk">
									<div className='thumbnail'>
										<div className='bigtalk-thumbnail'></div>
									</div>
								</Link>
								<div className="padding-top-1em" style={{width: '75%'}}>
									<Link to="/project-big-talk">
										<h4 style={{color: "black"}}>Big Talk<span className="description"> / work in progress</span></h4>
									</Link>
									<p className="padding-top-05em">How might we use technology to help people with public speaking?</p>
									<h6 className="padding-top-1em" style={{display: "flex", alignItems: "center"}}>
										<span className="discipline">Interaction Design</span>
									</h6>
								</div>
							</div>


						    <div className='column padding-top-2em'>
									<div className='thumbnail'>
										<div className='spotify-thumbnail'></div>
									</div>
								<div className="padding-top-1em" style={{width: '75%'}}>
									<h4 style={{color: "black"}}>Spotify Groups<span className="description"> / coming soon</span></h4>
									<p className="padding-top-05em">How might we create a collaborative environment for friends to discover new audio content?</p>
									<h6 className="padding-top-1em" style={{display: "flex", alignItems: "center"}}>
										<span className="discipline">Interaction Design</span>
									</h6>
								</div>
							</div>
						</div>
					</div>
					</HashRouter>
	        	)
			}
		}


export default FilterSelected;