import React from 'react';
import { Route, NavLink, Switch } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
/*
import FilterAll from './all';
import FilterUserExperience from './user-experience';
import FilterInteractionDesign from './interaction-design';
import FilterCreativeTechnology from './web-development.js';
import FilterExtendedReality from './extended-reality';
import FilterMotionGraphics from './motion-graphics';
*/
import FilterSelected from './selected';
import FilterOther from './other';






class Tabs extends React.Component {

  render() {
    return (
		<div>
			<Router basename={process.env.PUBLIC_URL}>

				<div id="grid-nav">


					<div className="project-text">
						<ul className="tabs row">
							<li className=""><NavLink exact to="/">Selected projects</NavLink></li>
							<span>&emsp;</span>
							<li className=""><NavLink to="/all">Other work</NavLink></li>
						</ul>
					</div>

					<Route render={({ location }) => {
					  const { pathname, key } = location

					  return (
					    <TransitionGroup>
					      <CSSTransition
					        key={location.key}
							timeout={{ enter: 600, exit: 0 }}
							classNames={'fade'}
					      >
					        <Switch location={location}>
					        	<Route exact path="/" component={FilterSelected} />
								<Route path="/all" component={FilterOther} />
					        </Switch>
					      </CSSTransition>
					    </TransitionGroup>
					  )
					}}/>


					{/*
				    <ul className="tabs grid-container">
				    	<div className="row">
				        	<li className="column"><NavLink exact to="/">Interaction Design</NavLink></li>
				        	<li className="column"><NavLink to="/motion-graphics">Motion Graphics</NavLink></li>
				        	<li className="column"><NavLink to="/web-development">Web Development</NavLink></li>
			        	</div>
				    	<div className="row">
				        	<li className="column"><NavLink to="/user-experience">User Experience</NavLink></li>
				        	<li className="column"><NavLink to="/extended-reality">AR/VR</NavLink></li>
					    	<li className="column"><NavLink to="/all">All</NavLink></li>
				    	</div>
			        </ul>

					<Switch>
						<Route exact path="/" component={FilterSelected} />
			         	<Route exact path="/" component={FilterInteractionDesign} />
			        	<Route path="/user-experience" component={FilterUserExperience} />
			         	<Route path="/web-development" component={FilterCreativeTechnology} />
			         	<Route path="/extended-reality" component={FilterExtendedReality} />
			         	<Route path="/motion-graphics" component={FilterMotionGraphics} />
			        	<Route path="/all" component={FilterAll} />
					</Switch>
					*/}
			    </div>
			</Router>
		</div>

	);
  }
}

export default Tabs;




