import React from 'react';
import Blog20190930CloudSpeechToText from '../img/blog_20190930_cloudspeechtotext.mp4';
import Blog20190930InterviewSynthesis from '../img/blog_20190930_interviewsynthesis.jpg';
import Blog20190930PostItSnapshot from '../img/blog_20190930_postitsnapshot.jpg';
import Blog20190930QuestionsMindMap from '../img/blog_20190930_questionsmindmap.jpg';
import Blog20190930TectonicLecture from '../img/blog_20190930_tectoniclecture.jpg';









class Blog20190930 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scrolled: 0
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollProgress);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollProgress);
  }

  scrollProgress = () => {
    const scrollPx = document.documentElement.scrollTop;
    const winHeightPx =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrolled = `${scrollPx / winHeightPx * 100}%`;

    this.setState({
      scrolled: scrolled
    });
  };

  render() {
    const progressContainerStyle = {
      background: "transparent",
      height: "2px",
      position: "relative",
      top: 0,
      left: 0,
      width: "100vw",
      zIndex: 99
    };

    const progressBarStyle = {
      height: "2px",
      background: "#000000",
      width: this.state.scrolled
    };

    return (
      <div>
        <div className="project-title">
	        <div id="title">Questions + Challenges</div>
	        <div className="progress-container" style={progressContainerStyle}>
	          <div className="progress-bar" style={progressBarStyle} />
	        </div>
	    </div>
        <div id="project-page">
	        <div className="project-text padding-top-3em">
  	        <br />
  	        <br />
  	        	<h3>2019-09-30</h3>
  		        <p className="padding-top-1em">So there’s quite a lot to talk about in this blog! I continued on with my user interviews last week; so far, I’ve spoken to three people - one with a strong fear of public speaking, one with no fear whatsoever, and one who is on the fence (only fearful when she’s unprepared). The questions I’ve been asking are based on my interviewee’s personal opinions on the topic, the speaking they’ve done in their professional lives, and the speaking they’ve done in their personal lives. I’m currently trying to get all of my notes up onto the wall, grouping them based on these three areas.</p>
          </div>
          <img className="padding-top-3em" src={Blog20190930InterviewSynthesis} alt="Trying to synthesise my interview notes" />  
          <div className="project-text padding-top-2em">
              <p className="padding-top-1em">I’d like to talk to a couple more people if possible before drawing any conclusions, but so far I have identified some similar themes:</p>    
              <br />
              <p className="padding-top-1em">- Physical responses to fear includes leg/hands shaking involuntarily, unconsciously touching the face/neck, heart racing, increased body heat - the typical symptoms you’d expect from anxiety</p>               
              <p className="padding-top-1em">- Opinions on audience eye contact while speaking is mixed - some prefer no eye contact as it makes them more nervous, some feel that no eye contact means that their speech is boring</p>              
              <p className="padding-top-1em">- There is a common theme of ‘blacking-out’ - forgetting what you’re talking about mid-sentence and having to refer back to slides/notes or in worst cases fleeing the scene entirely</p>               
              <p className="padding-top-1em">- Those comfortable with English preferred to look at certain keywords they had written down in their notes, while those who were less comfortable with English preferred to be able to see the full sentence/script of what they should say</p>    
              <p className="padding-top-1em">- All of the people I spoke to said they feel most confident and comfortable performing when they have practiced their speaking over and over again and know their subject matter very well</p>               
              <p className="padding-top-1em">- Most of them had done some public speaking outside of work/school, ranging from anchoring an event to hosting a workshop with friends</p>    
              <p className="padding-top-1em">- None of them displayed any strong social anxiety at non work-related events such as parties, although they all said they disliked ‘small talk’ and found it difficult to come up with things to say</p>
              <br />
              <p className="padding-top-1em">Once I’ve finished all of my user interviews, I’ll try to synthesise everything somehow and try to identify some key areas to address in my project.</p>              
          </div>
          <img className="padding-top-3em" src={Blog20190930PostItSnapshot} alt="Learnings from one of my interviews" />  
          <div className="project-text padding-top-2em">          
              <p className="padding-top-1em">Last week I also participated in an interesting workshop for my Cornell Tech Product Studio program. The <a href="https://www.tectonictheaterproject.org/" target="_blank" rel="noopener noreferrer">Tectonic Theater Project</a> are a company based in New York who are known for their trademarked theater-making method, Moment Work™. Alongside developing new plays, the company works with educational institutions such as Cornell Tech to improve student's public speaking skills. We were given an engaging lecture by Scott Barrow, who explained how to make our ideas ‘leap forward’ when presenting an important pitch. He mentioned the importance of the presentation when selling an idea, noting that the ‘bowl’ is often more memorable than what’s in it (the content). He also talked about the common responses to public speaking - grouping them as ‘emotional, cerebral, physiological, and behavioral’ - which I thought was interesting.</p>
          </div>
          <img className="padding-top-3em" src={Blog20190930TectonicLecture} alt="Tectonic Theater Project lecture at Cornell Tech" />  
          <div className="project-text padding-top-2em">          
              <p className="padding-top-1em">After the lecture we broke into groups and practised 4 steps to communicating effectively: 1. Connecting with your audience (eye contact), 2. Using your body to communicate effectively and demonstrate ease (open arms, non-defensive body language), 3. Using your vocal presence to convey your message (speaking clearly, maybe using humour and humility), 4. Finishing strong (offering a conclusion). Grant Varjas was the faculty member that led the workshop for my group; I managed to speak to him after the class and mentioned my thesis project, so hopefully I’ll be able to talk to him at some point about the Tectonic Theater Project and public speaking.</p>
          </div>
          <div className="project-text padding-top-2em">          
              <p className="padding-top-1em">As far as prototyping, I am continuing to experiment with different speech to text APIs to see which would be the most effective for my project. I had heard good things about Google Cloud Speech-To-Text so I created an account; it was definitely a lot more difficult to set up than IBM Watson, but that is probably due to me using node.js instead of Unity/C#. I managed to create a test app which allowed me to transcribe real-time voice streaming via Terminal.</p>
          </div>    
          <video className="padding-top-3em" playsinline="true" webkit-playsinline="true" autoPlay loop muted>
            <source src={Blog20190930CloudSpeechToText} alt="A video recording of my Google Cloud Speech to Text on Terminal" type='video/mp4' />
          </video>
          <div className="project-text padding-top-2em">          
              <p className="padding-top-1em">I’m still working on how to use this real-time voice streaming in a web browser to control other Javascript functions. I’d like to create an AR prototype similar to my last one, but using WebXR technologies instead of Unity, so I’m curious to see if the two API’s will play well together. I found out that Javascript actually has a free built-in Web Speech API which can do basic speech recognition for voice commands, so depending on the form that my project takes, that might be all I need. But for more accurate voice transcription (and maybe extra features like translation/punctuation/grammar stuff), a paid API from Google Cloud, IBM Watson or Microsoft Azure would be necessary.</p>
          </div>   
          <div className="project-text padding-top-2em">          
              <p className="padding-top-1em">Finally, I have been reflecting this weekend on the areas that I’ve explored so far as far as research and prototyping. Starting to form my concept statement has helped me to narrow down the direction in which I want to go, but there are definitely a few holes in the plan which I need to fill before the end of the semester. I tried to identify my key domains in this mind map, and highlighted in red the things which I am most unsure about.</p>
          </div>       
          <img className="padding-top-3em" src={Blog20190930QuestionsMindMap} alt="Mind mapping my questions and challenges for my project" />  
          <div className="project-text padding-top-2em">          
              <p className="padding-top-1em">My key challenge is probably going to be figuring out how to implement all the features that I want to include. I’m trying to get a head-start on the voice integration stuff, as I know that will be necessary for whatever my final product will be. I think that as long as I know exactly what technologies I want to use, I will be able to talk to my professors and get some tutoring over the next few months and learn how to fit everything together. I still don’t know how I’m going to integrate AR into my project though. Although I’m very interested in AR headsets like Magic Leap and Hololens, I really want whatever I make to be something that the everyday person can access without needing expensive technology. But I’m worried that AR might not be right for this project. I need to figure out quickly a useful way of implementing it, or just scrap the idea altogether.</p>
          </div>  
          <div className="project-text padding-top-2em">          
              <p className="padding-top-1em">As far as my weak spots, I know that I need to figure out a consistent schedule to balance my thesis work along with all my other classes. I need to ensure that I’m getting enough sleep and not working too late into the night which is a bad habit of mine. I also need to connect with other Design and Technology faculty and students who have experience in the areas that I need help with. As I was in Communication Design last year I really don’t know anybody from DT, so I’m going to have to try and reach out and get to know people more.</p>
          </div>
	        <br />
	        <br />
	        <br />
	    </div>
      </div>
    )
  }
}


export default Blog20190930;







