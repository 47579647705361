import React from "react";
import PropTypes from "prop-types";
import CKInsideMachine from '../img/coinkiller_insidemachine.jpg';
import CKInsideArcade from '../img/coinkiller_insidearcade.jpg';
import CKCode from '../img/coinkiller_code.jpg';
import CKUnity from '../img/coinkiller_unity.mp4';
import CKGame from '../img/coinkiller_game.mp4';







export default class Modal extends React.Component {
  /*
  componentWillMount() {
    // add event listener for clicks
    document.addEventListener('click', this.handleClick, false);
  }

  componentWillUnmount() {
    // make sure you remove the listener when the component is destroyed
    document.removeEventListener('click', this.handleClick, false);
  }

  // fat arrow function binds 'this' to class when called by the listener
  // otherwise you'd need 'this.handleClick.bind(this)' in the constructor
  handleClick = e => {
      document.getElementById("backdrop").onclick = function(){
        alert("Hey!");
      };
  }
  */

  onClose = e => {
    this.props.onClose && this.props.onClose(e);
    document.getElementById("backdrop").style.display = "none";
    document.getElementById("footer-light").style.display = "block";
  };
  render() {
    if (!this.props.show) {
      return null;
    }
    document.getElementById("backdrop").style.display = "block";
    document.getElementById("footer-light").style.display = "none";

    /*
      document.getElementById("backdrop").onclick = function(){
        alert("Hey!");
        //this.onClose();              
      };
    */
    
    return (
      /*
      document.getElementById("backdrop").onclick = function(){
        alert("Hey!");
        this.setState({ showCKModal: false });           
      },
      */
      //{this.state.show && <div id='modal' onClick={(e) => this.onClose(e)}>
      <div className="modal" id="modal">

        <div className="content">
        	<div className="project-text padding-top-2em">
        		<h2>A VR arcade game from an unusual point of view</h2>
            <a className="close" onClick={this.onClose}></a>
        	</div>
	        <div className="project-text">
		        <p className="padding-top-1em">Coin Killer is a mobile VR game for Google Cardboard, built in Unity with 3D machine components created in Blender. Based on traditional coin pushers found in amusement arcades around the world, Coin Killer allows the user to play from the perspective of a coin; trapped inside the slot machine, teetering on the ledge.</p>
		        <p className="padding-top-1em">From inside the coin pusher, the player coin can aim at the three ‘More Coins’ buttons using the direction of their gaze, and click the Cardboard button to instantiate new coins. The aim of the game is to score points by pushing as many coins off of the ledge as possible, but once the player coin falls off too, it’s game over. How long can you survive on the edge?</p>
	        </div>
	        <img className="padding-top-3em" src={CKInsideMachine} alt="Screenshot of the initial view inside the machine" />
          <img className="padding-top-3em" src={CKInsideArcade} alt="Screenshot of the arcade view around you from inside the machine" />
	        <img className="padding-top-3em" src={CKCode} alt="The C# script to control the behaviour of the player coin" />
	         <video className="padding-top-3em" playsinline="true" webkit-playsinline="true" autoPlay loop muted>
    			   <source src={CKUnity} alt="Screen recording of the Unity scene view" type='video/mp4' />
			     </video>
           <video className="padding-top-3em" playsinline="true" webkit-playsinline="true" autoPlay loop muted>
             <source src={CKGame} alt="Screen recording of the Unity game view" type='video/mp4' />
           </video>
	        <div className="project-text">
	           <br />
        		<br />
        		<a className="button" onClick={this.onClose}>Close</a>
        	</div>
        </div>
        <br />
        <br />
      </div>

    );
  }
}
Modal.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired
};


