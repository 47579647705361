import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from "react-router";

import ATVideo from '../img/actiontime_video.mp4';
import ATUI from '../img/actiontime_UI.jpg';
import ATBriefingNotes from '../img/actiontime_briefingnotes.jpg';
import ATValue from '../img/actiontime_valuepropositioncanvas.jpg';
import ATEmpathyMap from '../img/actiontime_empathymap.jpg';
import ATPersona from '../img/actiontime_personagraphic.jpg';
import ATUserFlow from '../img/actiontime_userflow.jpg';
import ATLowFidelity from '../img/actiontime_lowfidelity.jpg';
import ATHighFidelity from '../img/actiontime_highfidelity.jpg';
import ATClickable from '../img/actiontime_clickable.mp4';
import ATStyleGuideA from '../img/actiontime_styleguide_a.gif';
import ATStyleGuideB from '../img/actiontime_styleguide_b.gif';
import ATStyleGuideC from '../img/actiontime_styleguide_c.gif';









class ATProjectPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scrolled: 0
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollProgress);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollProgress);
  }

  scrollProgress = () => {
    const scrollPx = document.documentElement.scrollTop;
    const winHeightPx =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrolled = `${scrollPx / winHeightPx * 100}%`;

    this.setState({
      scrolled: scrolled
    });
  };

  render() {
    const progressContainerStyle = {
      background: "transparent",
      height: "2px",
      position: "relative",
      top: 0,
      left: 0,
      width: "100vw",
      zIndex: 99
    };

    const progressBarStyle = {
      height: "2px",
      background: "#000000",
      width: this.state.scrolled
    };

    return (
      <div>
      	<div className="project-title">
	        <div id="title">A Design Inception for Speakable</div>
	        <Link to="/"><div className="close-project"></div></Link>
	        <div className="progress-container" style={progressContainerStyle}>
	          <div className="progress-bar" style={progressBarStyle} />
	        </div>
	    </div>
        <div id="project-page">
	    	<div className="project-text padding-top-2em">
		        <h1 className="padding-top-1em">How might we inspire slacktivists to take action on causes they care about?</h1>
		    </div>
		    <div className="project-text padding-top-2em">
		        <div className="project-caption-half padding-top-1em">
			        <h3>Context</h3>
			        <h5 className="padding-top-1em">Action Time is a mobile app for people who wish to donate a little (or a lot) of time towards causes they're interested in. Designed for Speakable at Parsons.</h5>
		        </div
		        ><div className="project-caption-half padding-top-1em">
			        <h3>Role</h3>
			        <h5 className="padding-top-1em">UX research, branding, illustration and interaction design. Design team: Rebecca Gill Clarke, Lucie Bolé, Ang Li.</h5>
		        </div>
		    </div>
		    <video className="padding-top-3em" playsinline="true" webkit-playsinline="true" autoPlay loop muted>
    			<source src={ATVideo} alt="Four key screens from the Action Time app" type='video/mp4' />
			</video>		  
		    <div className="project-text padding-top-3em">
			    <h3>Design Brief</h3>
		        <h2 className="padding-top-05em">Time to take action</h2>
		        <p className="padding-top-1em">Action Time is an app which helps you dedicate time in your schedule, whether it be an hour a month or 12 hours a week, towards supporting the causes you care about. It was developed as the result of a mock inception for Speakable, a social action company using technology to enable smart and effective civic engagement.</p>
	        </div>
	        <img className="padding-top-3em" src={ATUI} alt="A birds eye view of some of the other screens" />  
	        <div className="project-text padding-top-3em">
			    <h3>Research</h3>
		        <h2 className="padding-top-05em">Brand discovery</h2>
		        <p className="padding-top-1em">We began by conducting stakeholder interviews with our clients Jordan Hewson and Robert Walport of Speakable, who gave us an overview of the company, their audience, and their first digital product, Action Button.</p>
		        <p className="padding-top-1em">I starting doing my own desktop research and thinking about future product opportunities for one of Speakable’s main user types; slacktivists. Slacktivists are defined as people who express support for causes online, but rarely mobilise in real-life.</p>
	        </div>
	        <img className="padding-top-3em" src={ATBriefingNotes} alt="Notes from our initial briefing with Speakable" />  
	        <div className="project-text padding-top-3em">
			    <h3>Research</h3>
		        <h2 className="padding-top-05em">Design sprint</h2>
		        <p className="padding-top-1em">We used IDEO brainstorming methods to create our Value Proposition Canvas, which helped us identify a number of pains and gains for the Speakable users five primary jobs; volunteering, attending events, voting, donating and contacting representatives.</p>
		        <p className="padding-top-1em">After various sprint exercises, my team settled on one main challenge; How can we encourage slacktivists to actually spend time volunteering and donating to causes they are interested in?</p>
	        </div>
	        <img className="padding-top-3em" src={ATValue} alt="A photograph of our completed Value Proposition Canvas" />
	        <div className="project-text padding-top-3em">
			    <h3>Research</h3>
		        <h2 className="padding-top-05em">Interviews</h2>
		        <p className="padding-top-1em">In order to assess our initial user assumptions we needed to do some qualitative research, so we conducted lean interviews with two users who had volunteered and donated in the past; Aggie and Shiloh.</p>
		        <p className="padding-top-1em">Our post-interview synthesis revealed a common want for personalised content and a stronger interest in local vs. national/global causes. This empathy map I designed for Aggie illustrates her issues with finding volunteering opportunities in her specific area.</p>
	        </div>
	        <img className="padding-top-3em" src={ATEmpathyMap} alt="An empathy map for one of our interviewees, Aggie" />
	        <div className="project-text padding-top-3em">
			    <h3>Research</h3>
		        <h2 className="padding-top-05em">Personas</h2>
		        <p className="padding-top-1em">Summarising our interviewees backgrounds and interests as UX personas helped us to really define who were designing for. Shiloh’s persona represents a common type of user who is passionate about specific issues and causes, but short on time due to work and family commitments.</p>	
	        </div>
	        <img className="padding-top-3em" src={ATPersona} alt="A persona graphic of one of our interviewees, Shiloh" />
	        <div className="project-text-half padding-top-3em">
			    <h3>Architecture</h3>
		        <h2 className="padding-top-05em">Mapping the process</h2>
		        <p className="padding-top-1em">Through our research we came to the understanding that time constraints and personalised content were the main issues we wanted to address, so we began to sketch out a low fidelity process map for our entire app.</p>
		        <p className="padding-top-1em">As the strategy of our ideation was quite complex, we each decided to focus on one specific feature. I worked on the onboarding, data collection and personalised dashboard sections highlighted here.</p>	
	        </div>
	        <img className="project-text-half padding-top-3em" src={ATUserFlow} alt="A low fidelity map of Action Time's system architecture" />
	        <div className="project-text padding-top-3em">
			    <h3>Prototyping</h3>
		        <h2 className="padding-top-05em">Low fidelity sketching</h2>
		        <p className="padding-top-1em">I began the prototyping phase by drawing low fidelity wireframes of my key screens, in order to illustrate the user flow of my specific feature. Sketching my ideas out on paper first allowed me to iterate quickly and easily without getting caught up in the specific details of visual fidelity.</p>
	        </div>
	        <img className="padding-top-3em" src={ATLowFidelity} alt="Low fidelity sketches of key screens" />
	        <div className="project-text padding-top-3em">
			    <h3>Prototyping</h3>
		        <h2 className="padding-top-05em">High fidelity wireframes</h2>
		        <p className="padding-top-1em">Next I brought my finalised user flow into Sketch, where I began to integrate further content and functionality into a set of high fidelity wireframes. Here are four of my key screens which show:</p>
		        <ol>
		        	<h4 className="padding-top-05em"><li>The onboarding introduction to the app</li></h4>
		        	<h4 className="padding-top-05em"><li>The option to personalise content by selecting issues you’re interested in</li></h4>
		        	<h4 className="padding-top-05em"><li>The option to dedicate time per week/month to making a change</li></h4>
		        	<h4 className="padding-top-05em"><li>A personalised feed of opportunities filtered to your specific interests and time constraints</li></h4>
		        </ol>
	        </div>
	        <img className="padding-top-3em" src={ATHighFidelity} alt="Mid fidelity wireframes of key screens" />
	        <div className="project-text-half padding-top-3em">
			    <h3>Prototyping</h3>
		        <h2 className="padding-top-05em">User testing</h2>
		        <p className="padding-top-1em">The next step was to create a <a href="https://projects.invisionapp.com/share/PHOF4G6587Q/#/screens" target="_blank" rel="noopener noreferrer">clickable InVision prototype</a> of the user flow for testing. The prototype illustrates additional features such as the option to localise content to either your current city or an area of your choice.</p>
		        <p className="padding-top-1em">From your personalised dashboard you are also able to filter opportunities by cause, sort opportunities by time, and separate your feed into volunteering or donating opportunities only.</p>	
		        <p className="padding-top-1em">By sitting down with 2 potential users and watching how they interacted with the prototype without instruction, I was able to witness moments of hesitation within their user journey and question them about what was confusing.</p>
		        <p className="padding-top-1em">As a result of this user testing, I ended up making a clearer distinction between volunteering and donating opportunities through a colour-coded system in my high fidelity designs.</p>	
	        </div>
		    <video className="project-text-half padding-top-3em" playsinline="true" webkit-playsinline="true" autoPlay loop muted>
    			<source src={ATClickable} alt="Screen recording of my clickable InVision prototype" type='video/mp4' />
			</video>
	        <div className="project-text padding-top-3em">
			    <h3>Visual design</h3>
		        <h2 className="padding-top-05em">Colour and typography</h2>
		        <p className="padding-top-1em">The final stages of the project were spent developing the user interface of our app, and we each created our own individual designs. I tried to maintain consistency by reusing the pink and blue colour palette and signature sans-serif font 'GT Walsheim', but I also introduced a secondary teal shade and an additional serif font ‘Lora’.</p>
		        <p className="padding-top-1em">My interface design guide for Action Time details all of the specific font weights and sizes used in the app. I also included the hex and RGB codes of the full five colour palette. I created text styles and symbols from my design guide and saved the document as a Sketch library, which made it easy to develop my finalised key screens.</p>
	        </div>
	        <img className="padding-top-3em" src={ATStyleGuideA} alt="The colour and typography pages from my style guide" />
	        <div className="project-text padding-top-3em">
			    <h3>Visual design</h3>
		        <h2 className="padding-top-05em">Imagery and iconography</h2>
		        <p className="padding-top-1em">I used icons instead of text labels to categorise Speakable’s main issue areas such as women’s rights, environment and politics. Some issues such as migration and poverty were quite tricky to illustrate sensitively, so I decided to take a more abstract approach.</p>
		        <p className="padding-top-1em">I also created illustrations for the app’s initial onboarding screens to visualise three mantras from Action Time's elevator pitch; ‘Pick your passions’, ‘Prioritize your time’ and ‘Make a difference’.</p>
	        </div>
	        <img className="padding-top-3em" src={ATStyleGuideB} alt="The imagery and iconography pages from my style guide" />
	        <div className="project-text padding-top-3em">
			    <h3>Visual design</h3>
		        <h2 className="padding-top-05em">UI elements and components</h2>
		        <p className="padding-top-1em">Also included in the design guide are various UI elements and components such as buttons, inputs, forms and menus. One element I used consistently as a visual cue for user interaction was the solid pink line, which serves as both the baseline for a text input field as well as the track for a range input slider.</p>
	        </div>
	        <img className="padding-top-3em" src={ATStyleGuideC} alt="The UI elements and components pages from my style guide" />
	        <div className="project-text padding-top-3em">
			    <h3>Reflection</h3>
		        <h2 className="padding-top-05em">Fresh insights</h2>
		        <p className="padding-top-1em">Insights gained from our user interviews proved many of our initial theories about Speakable’s audience wrong, particularly the assumed importance of attending events and protests. This demonstrates the value of UX research when forming a product vision.</p>
		        <p className="padding-top-1em">For future iterations I would like to incorporate more meaningful microinteractions into my design. Speakable were happy with the outcome of our mock inception, and I have since begun working with them directly on <Link to="/project-slider-quiz"><a>several design projects</a></Link> for Action Button.</p>
	        </div>
	        <br />
	        <br />
	        <br />
      	</div>
      	<div className="footer-next">
  			<div className="row">
	  			<div className="column">
	  				<h6>Previous project</h6>
	      			<h4 className="padding-top-05em">
		      			<Link to="/project-perfect-weekend">My Perfect FT Weekend</Link>
	      			</h4>
	      		</div>
	  			<div className="column">
	      			<h6>Next project</h6>
	      		    <h4 className="padding-top-05em">
		      			<Link to="/project-slider-quiz">The Radial Dial</Link>
	      		    </h4>
	      		</div>
      		</div>
      	</div>
      </div>
    )
  }
}



export default withRouter(ATProjectPage);







