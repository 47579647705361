import React from "react";
import PropTypes from "prop-types";
import MAp5 from '../img/mariah_p5.jpg';
import MARecording from '../img/mariah_recording.mp4';






export default class Modal extends React.Component {
  onClose = e => {
    this.props.onClose && this.props.onClose(e);
    document.getElementById("backdrop").style.display = "none";
    document.getElementById("footer-light").style.display = "block";
  };
  render() {
    if (!this.props.show) {
      return null;
    }
    document.getElementById("backdrop").style.display = "block";
    document.getElementById("footer-light").style.display = "none";
    return (
      <div className="modal" id="modal">
        <div className="content">
        	<div className="project-text padding-top-2em">
        		<h2>Festive voice analysis using p5.js</h2>
            <a className="close" onClick={this.onClose}></a>
        	</div>
	        <div className="project-text">
		        <p className="padding-top-1em">Can you sing like Mariah? This fun and festive data visualisation gives users the chance to listen to a sound clip from Mariah Carey's 'All I Want for Christmas is You', and attempt to match her vocal range. Users can click 'Listen' to hear the clip, then 'Sing' to record and play their own version. The visualisation combines ml5.js Pitch Detection machine learning model with Javascript's built in Web Speech API.</p>
            <br />
            <br />
            <a className="button" href="https://editor.p5js.org/madspesh/sketches/Ut2WSmX94" target="_blank" rel="noopener noreferrer">Try it now</a>
	        </div>
	         <video className="padding-top-3em" playsinline="true" webkit-playsinline="true" autoPlay loop muted>
    			   <source src={MARecording} alt="The website functionality" type='video/mp4' />
			     </video>
          <img className="padding-top-3em" src={MAp5} alt="Testing in the p5 Web Editor" />
	        <div className="project-text">
	            <br />
        		<br />
        		<a className="button" onClick={this.onClose}>Close</a>
        	</div>
        </div>
        <br />
        <br />
      </div>
    );
  }
}
Modal.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired
};


