import React from 'react';
import Blog20180827Slide from '../img/blog_20180827_slide.jpg';
import Blog20180827Sketch from '../img/blog_20180827_sketch.jpg';
import Blog20180827PaperPrototype from '../img/blog_20180827_paperprototype.jpg';









class Blog2018082 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scrolled: 0
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollProgress);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollProgress);
  }

  scrollProgress = () => {
    const scrollPx = document.documentElement.scrollTop;
    const winHeightPx =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrolled = `${scrollPx / winHeightPx * 100}%`;

    this.setState({
      scrolled: scrolled
    });
  };

  render() {
    const progressContainerStyle = {
      background: "transparent",
      height: "2px",
      position: "relative",
      top: 0,
      left: 0,
      width: "100vw",
      zIndex: 99
    };

    const progressBarStyle = {
      height: "2px",
      background: "#000000",
      width: this.state.scrolled
    };

    return (
      <div>
        <div className="project-title">
	        <div id="title">First Paper Prototype</div>
	        <div className="progress-container" style={progressContainerStyle}>
	          <div className="progress-bar" style={progressBarStyle} />
	        </div>
	    </div>
        <div id="project-page">
	        <div className="project-text padding-top-3em">
	        <br />
	        <br />
	        	<h3>2019-08-27</h3>
		        <p className="padding-top-1em">This first week of class was spent clarifying the area I want to concentrate on for my thesis. For our getting-to-know-you <a href="https://docs.google.com/presentation/d/19RViC9GsvPa6NRHOeDKfh08FWthqHF_mHQ_dQLFaL6Q/edit?usp=sharing" target="_blank" rel="noopener noreferrer">class presentation</a>, I created this slide illustrating a few of my interests.</p>
	        </div>
	        <img className="padding-top-3em" src={Blog20180827Slide} alt="Slide showing my ideas for thesis" />  
	        <div className="project-text padding-top-3em">
		        <p className="padding-top-1em">I’m still not 100% sure about my direction, as there are a couple of other avenues which I would be interested in exploring. But for now, I am settling on glossophobia. Glossophobia is a type of speech anxiety, defined by a fear of public speaking. The term is derived from the Greek word glōssa, meaning tongue.</p>
		        <p className="padding-top-1em">I would describe myself as an introvert who likes being around people, but when it comes to work-related situations (interviews, presentations) or social environments where I feel pressured to speak, I clam up. This is something that has affected me for as long as I can remember. In these types of situations, I have a tendency to ‘go red’, my chest starts pounding, and sometimes I find it difficult to control my voice. Even just thinking about these encounters can trigger me.</p>
		        <p className="padding-top-1em">Concentrating on glossophobia for my thesis could act as a form of therapy for me, as although I’ve looked into coping mechanisms in the past, I’ve never found something that actually works. I think this is an issue that a lot of my peers suffer with too, so there would be lots of opportunity for user research and testing.</p>
	        </div>
	        <div className="project-text-half padding-top-3em">
		        <p className="padding-top-1em">It’s also a subject which can be interpreted in a number of ways, whether it be a digital or physical coping mechanism, an art piece triggered by the issue, or even an immersive experience demonstrating how glossophobia feels.</p>
		        <p className="padding-top-1em">I’m not sure which direction my project will take, but I have some initial ideas right now. I did some sketches illustrating a presentation device using augmented reality. The user can take advantage of two views; the front-facing ‘selfie’ mode and the rear camera mode.</p>
		        <p className="padding-top-1em">Using selfie mode, the user can review their speaker notes as well as the slides they need to present. The camera will analyse their facial movements and a microphone will record their voice. The user can receive feedback on the speed of their sentences, the number of times they say certain words (eg. ‘like’), how often they smiled, etc.</p>
	        </div>
	        <img className="project-text-half padding-top-3em" src={Blog20180827Sketch} alt="A sketch of my AR presentation software idea" />
	        <div className="project-text padding-top-3em">
		        <p className="padding-top-1em">Using the rear camera mode, users can treat the device like an AR teleprompter. The screen will display speaker notes karaoke-style, and augmented reality can be used to recreate a social scene for the user to practice in. I created the paper prototype below to show how the selfie mode might work on a tablet.</p>
	        </div>	   
	        <img className="padding-top-3em" src={Blog20180827PaperPrototype} alt="A paper prototype of the selfie mode" />  
	        <div className="project-text padding-top-3em">
		        <p className="padding-top-1em">I would like to get some feedback on whether this is an interesting route to continue with, and if so, what kind of features should or shouldn’t be included.</p>
	        </div>		        
	        <br />
	        <br />
	        <br />
	    </div>
      </div>
    )
  }
}


export default Blog2018082;







