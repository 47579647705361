import React from 'react';
import Blog20191016Glitch from '../img/blog_20191016_glitch.jpg';
import Blog20191016Tinkercad from '../img/blog_20191016_tinkercad.jpg';
import Blog20191016WebXR from '../img/blog_20191016_webxr.jpg';
import Blog20191016Meetup from '../img/blog_20191016_meetup.jpg';










class Blog20191016 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scrolled: 0
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollProgress);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollProgress);
  }

  scrollProgress = () => {
    const scrollPx = document.documentElement.scrollTop;
    const winHeightPx =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrolled = `${scrollPx / winHeightPx * 100}%`;

    this.setState({
      scrolled: scrolled
    });
  };

  render() {
    const progressContainerStyle = {
      background: "transparent",
      height: "2px",
      position: "relative",
      top: 0,
      left: 0,
      width: "100vw",
      zIndex: 99
    };

    const progressBarStyle = {
      height: "2px",
      background: "#000000",
      width: this.state.scrolled
    };

    return (
      <div>
        <div className="project-title">
	        <div id="title">Midterm Presentations + WebXR</div>
	        <div className="progress-container" style={progressContainerStyle}>
	          <div className="progress-bar" style={progressBarStyle} />
	        </div>
	    </div>
        <div id="project-page">
	        <div className="project-text padding-top-3em">
  	        <br />
  	        <br />
  	        	<h3>2019-10-16</h3>
  		        <p className="padding-top-1em">Last week I presented my <a href="https://docs.google.com/presentation/d/1msb9TBAYYvYoR1dxglIRhwKs8chpdWERV1MSPtvp2_I/edit?usp=sharing" target="_blank" rel="noopener noreferrer">midterm thesis proposal</a> to two guest critiques, my professors, and my classmates. I feel that my research was thorough but the actual presentation could have gone better; I was nervous as always, and one of my prototypes failed to load (I think the GIF was too large) which was a big fail, as I would have liked to have gotten some more feedback on this particular direction. It just goes to show that you should always have a backup plan! (or a backup video). Below is my reflection on the feedback I received.</p>
          </div>
          <div className="project-text padding-top-2em">          
              <h4 className="padding-top-1em">Prioritize - what is the most important item (or two items) of feedback?</h4>   
              <p className="padding-top-1em">The most important critique I got was to reexamine my audience and try to zero in on specific user needs, rather than generalising and trying to cater to everybody. By identifying a variety of precise use cases and designing something explicitly for them, I might find that this very specific problem could actually prove to be helpful to a broader audience.</p>
              <p className="padding-top-1em">The other important piece of feedback I received was to think about areas that are completely unrelated (and possibly the complete opposite) to what I envision my project to be. Right now I am pretty firm on XR most likely being my final form, but by trying other things maybe I’ll discover something unique, rather than building upon AR/VR things that people have already tried. One very different point of comparison could be looking into physical training, done by athletes, dancers and musicians - could their methods of focusing/concentrating on their goals be applied to public speaking in some way?</p>
              <h4 className="padding-top-1em">Compare to feedback from earlier in the semester - are there patterns that emerge?</h4>   
              <p className="padding-top-1em">My guest critique Barbara Morris commented on bringing a humorous element into my project. I received a similar piece of feedback earlier in the semester, and although I did initially try the game prototype, this funny and playful element is obviously still missing from my current work.</p>               
              <h4 className="padding-top-1em">Do you have any follow-up questions? How do you plan to respond to the feedback?</h4>   
              <p className="padding-top-1em">I would really like to have gotten some feedback on the ‘AI’ prototype that I created. As the GIF wasn’t working during the presentation, I might try to share my blog post about this prototype with my guest critiques, to see what they think of this approach vs. the heart rate sensor one that they saw.</p>
              <p className="padding-top-1em">I’d also like to try and meet with Justin Bakse regarding some of the precedents that he mentioned I look into (specifically papers on the Apple earpods). I’m planning to get in contact with someone from the undergrad’s Game Club, to see if they have a Nintendo Switch that I can play with (I’d like to try out the 1-2 Switch game that Justin mentioned).</p>
          </div>
          <img className="padding-top-3em" src={Blog20191016Meetup} alt="The WebXR Meetup at RLab" />
          <div className="project-text padding-top-1em">          
              <p className="padding-top-1em">Now that midterms are over, I’m concentrating on reaching out to people in my community of practice. I’ve emailed a number of experts in the field of psychology, computer science, theater and voice training, so hopefully I’ll be able to arrange some in-person and over-the-phone interviews during the next few weeks. I also attended a WebXR Meetup on Tuesday evening at the RLab in Brooklyn which was very insightful. I chatted with a number of developers, designers and students from various companies and schools (I was the only person from Parsons there), and I’d definitely like to keep in contact with the people I spoke to.</p>
          </div>
          <img className="padding-top-3em" src={Blog20191016Tinkercad} alt="Building a simple glTF model in Tinkercad" />
          <div className="project-text padding-top-1em">          
              <p className="padding-top-1em">During the event we learnt about the possibilities of WebXR, and it’s accessibility advantages over traditional VR headsets due to being able to work within the web browser. I learned that although the technology is still evolving all the time, WebXR is currently very close to becoming stabilised, so it's definitely something that developers should consider switching to (particularly those coming from a WebGL background). I also found out that the API supports 6 degrees of freedom (6DOF) and works with frameworks such as three.js, A-Frame, Babyon.js and React.js.</p>
          </div>
          <img className="padding-top-3em" src={Blog20191016Glitch} alt="Coding in Glitch" />  
          <div className="project-text padding-top-2em">          
              <p className="padding-top-1em">The final part of the meetup was a quick ‘code-along’ tutorial to making a simple WebXR scene using A-Frame, Tinkercad and Glitch. I was amazed at how fast and easy it was to get a glTF model uploaded and working in 3D in the browser - the whole tutorial including modelling took less than 20 minutes! Above is the code we tweaked in Glitch, and below is how the scene renders in WebXR. Although I’ve kind of been ‘against’ VR up until this point due to there being so many VR public speaking tools out there already, it’s definitely a form that I’d like to play with a bit. One of the critiques that I received from Justin Bakse was that low-budget VR headsets like Google Cardboard are actually cheaper than smartphones. Now that I’ve learnt a bit about WebXR and experienced how easy A-Frame is to work with, one of my next steps is to buy a Google Cardboard and create some quick VR prototypes to test with specific users.</p>
          </div>
          <img className="padding-top-3em" src={Blog20191016WebXR} alt="The WebXR experience, live in the browser!" />            
	        <br />
	        <br />
	        <br />
	    </div>
      </div>
    )
  }
}


export default Blog20191016;







