import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './App.css';
//import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { HashRouter } from 'react-router-dom';
import ScrollIntoView from "./ScrollIntoView";






ReactDOM.render(
	<HashRouter>
		<ScrollIntoView>
			<App />
		</ScrollIntoView>
	</HashRouter>

	, document.getElementById('root'));



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
