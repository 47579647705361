import React from 'react';
import { Link } from 'react-router-dom';
import SQDesktop from '../img/sliderquiz_desktop_.mp4';
import SQSecondaryActions from '../img/sliderquiz_secondaryactions.jpg';
import SQLowFidelity from '../img/sliderquiz_lowfidelity.jpg';
import SQSketch from '../img/sliderquiz_sketch.jpg';
import SQUserFlow from '../img/sliderquiz_userflow.png';
import SQCloseGuess from '../img/sliderquiz_closeguess.gif';
import SQSmallIncorrectGuess from '../img/sliderquiz_smallincorrectguess.gif';
import SQLargeIncorrectGuess from '../img/sliderquiz_largeincorrectguess.gif';
import SQCorrectGuess from '../img/sliderquiz_correctguess.gif';
import SQMobile from '../img/sliderquiz_mobile.mp4';









class SQProjectPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scrolled: 0
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollProgress);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollProgress);
  }

  scrollProgress = () => {
    const scrollPx = document.documentElement.scrollTop;
    const winHeightPx =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrolled = `${scrollPx / winHeightPx * 100}%`;

    this.setState({
      scrolled: scrolled
    });
  };

  render() {
    const progressContainerStyle = {
      background: "transparent",
      height: "2px",
      position: "relative",
      top: 0,
      left: 0,
      width: "100vw",
      zIndex: 99
    };

    const progressBarStyle = {
      height: "2px",
      background: "#000000",
      width: this.state.scrolled
    };

    return (
      <div>
      	<div className="project-title">
	        <div id="title">A Playful New Interaction for Action Button</div>
	        <Link to="/"><div className="close-project"></div></Link>
	        <div className="progress-container" style={progressContainerStyle}>
	          <div className="progress-bar" style={progressBarStyle} />
	        </div>
	    </div>
        <div id="project-page">
	    	<div className="project-text padding-top-2em">
		        <h1 className="padding-top-1em">How might we engage and educate users through playful interaction design?</h1>
		    </div>
		    <div className="project-text padding-top-2em">
		        <div className="project-caption-half padding-top-1em">
			        <h3>Context</h3>
			        <h5 className="padding-top-1em">A brand new quiz interaction aimed at engaging and educating users on social causes, designed for Action Button at Speakable.</h5>
		        </div
		        ><div className="project-caption-half padding-top-1em">
			        <h3>Role</h3>
			        <h5 className="padding-top-1em">UX research and interaction design. Team: Rebecca Gill Clarke, Aaron Cohen, Elizabeth Kilson, Robert Walport, Alexis Romanoff.</h5>
		        </div>
		    </div>
		    <video className="padding-top-3em" playsinline="true" webkit-playsinline="true" autoPlay loop muted>
    			<source src={SQDesktop} alt="The new interaction shown on a Time magazine article on a laptop" type='video/mp4' />
			</video>
		    <div className="project-text padding-top-3em">
			    <h3>Background</h3>
		        <h2 className="padding-top-05em">Enabling civic engagement</h2>
		        <p className="padding-top-1em">Action Button is an innovative technology which powers instant social action directly from inspiring content. Embedded into editorial articles from publishers such as TIME, Vice and PAPER Magazine, the product enables users to take action on stories they’ve just read about by volunteering, signing petitions, donating money or contacting elected officials in support of their causes.</p>
		        <p className="padding-top-1em">Action Button works with non-profits such as Amnesty International, Save The Children and Direct Relief, as well as socially conscious brands like Tommy Hilfiger, Citi and NBC Universal to boost digital campaigns and spread awareness of important issues worldwide. Below are some examples of volunteering and petition actions which I worked on during my time at Speakable.</p>
	        </div>
	        <img className="padding-top-3em" src={SQSecondaryActions} alt="Volunteering and Petition actions I'd previously redesigned" />
	        <div className="project-text padding-top-3em">
			    <h3>Opportunity</h3>
		        <h2 className="padding-top-05em">The power of touch</h2>
		        <p className="padding-top-1em">Depending on the nature of the article, Action Button uses various ‘primary’ actions to initially engage users and guide them into taking a secondary action. These primary actions include quizzes, surveys and polls about the subject at hand, and they tend to produce high engagement due to their interactive affordances.</p>
		        <p className="padding-top-1em">One of the most popular primary actions is the sentiment poll, which allows users to express how they feel about a particular issue by dragging a slider button to the left or right of an x-axis. The interaction is particularly popular on mobile, and we wondered whether this method of playful touch could be employed in other areas.</p>
	        </div>
	        <img className="padding-top-3em" src={SQLowFidelity} alt="Low fidelity sketches of initial ideas" />
	        <div className="project-text padding-top-3em">
			    <h3>Prototyping</h3>
		        <h2 className="padding-top-05em">Low to high fidelity</h2>
		        <p className="padding-top-1em">The design process began with initial sketches and user flows, thinking of ways to merge the educational aspect of our quiz actions with the interactivity of our popular sentiment polls. I thought about using a circular rather than a horizontal slider to enable a different kind of sliding interaction. The text on the left hand side would respond to the user’s input; first asking them a question, then informing them of the answer. The text in the centre of the slider would highlight the user’s selection.</p>
	        </div>
	        <div className="project-text padding-top-3em">
	        	<h4>Task flow</h4>
	        </div>
	        	<img src={SQUserFlow} alt="The user flow from initial engagement to secondary action" />
	        <div className="project-text padding-top-3em">
		        <p>Using Sketch, I mocked up a few versions of this circular slider idea and shared the designs with the team. These initial options included UI elements such as pivot lines and arrows showing the direction in which users should slide. After testing with users, I received some feedback about the confusing start position of the slider and the redundancy of the arrows, so I played with the rotation a bit and came up with a final ’horseshoe’-like design.</p>
	        </div>
	        <img className="padding-top-3em" src={SQSketch} alt="Various design mockups in Sketch" />
	        <div className="project-text padding-top-3em">
			    <h3>Prototyping</h3>
		        <h2 className="padding-top-05em">Interaction design</h2>
		        <p className="padding-top-1em">Once the static visuals had been approved, I began creating animated prototypes in After Effects to demonstrate how the interactions would work. Although seemingly quite simplistic, the results animation took quite a few iterations to get right. Fading the Action Button icon into a pink tick icon for correct answers was fairly straightforward, but incorrect or ‘close' answers involved a layering transition in which the blue cross icon would move from the background to the foreground.</p>        
	        </div>
	        <div className="padding-top-3em" style={{paddingLeft: 2.5 + "em", paddingRight: 2.5 + "em"}}>
	        	<img className="project-text-half" style={{padding: 0}} src={SQCloseGuess} alt="Animated prototype of a close guess, created in After Effects" />
	        	<img className="project-text-half" style={{padding: 0}} src={SQSmallIncorrectGuess} alt="Animated prototype of a smaller incorrect guess, created in After Effects" />
	        	<img className="project-text-half" style={{padding: 0}} src={SQLargeIncorrectGuess} alt="Animated prototype of a larger incorrect guess, created in After Effects" />
	        	<img className="project-text-half" style={{padding: 0}} src={SQCorrectGuess} alt="Animated prototype of a correct guess, created in After Effects" />
	        </div>
	        <div className="project-text padding-top-3em">
		        <p>I worked directly alongside the software engineering team who did a great job in ensuring the visuals and interactions matched the initial designs as closely as possible. We collaborated together on adapting colour schemes for brand sponsored as well as Action Button themed content.</p>
	        </div>
	        <div className="project-text-half padding-top-3em">
			    <h3>Reflection</h3>
		        <h2 className="padding-top-05em">Mobile-friendly</h2>
		        <p className="padding-top-1em">One of the main issues with the sentiment poll interaction is its lack of screen real-estate on smaller devices. The full-width horizontal design is easily scaleable for mobile, but the smaller slider makes it less impactful to scroll from one edge to another.</p>
		        <p className="padding-top-1em">The circular design of the slider quiz allows the layout to stack rather than shrink to fit smaller screens, and also supports longer titles and more textual information about the issue or cause, which was a big plus for our clients.</p>		        
	        </div>
		    <video className="project-text-half padding-top-3em" playsinline="true" webkit-playsinline="true" autoPlay loop muted>
    			<source src={SQMobile} alt="Mobile screen recording of the interaction" type='video/mp4' />
			</video>
	        <div className="project-text padding-top-3em">
			    <h3>Reflection</h3>
		        <h2 className="padding-top-05em">Impactful results</h2>
		        <p className="padding-top-1em">The final iteration is now live and implemented in a number of Action Button campaigns, educating readers on social issues such as gun control, immigration and global warming. The new design delivered a boost of 5%+ engagement in just its first week of launch, and is now one of Action Button’s most popular interactions.</p>
	        </div>
	        <br />
	        <br />
	        <br />
	        <div className="project-text">
	        	<a className="button" href="https://embed.actionbutton.co/iframely/index?widgetId=SPK-QkFARA==" target="_blank" rel="noopener noreferrer">Try it now</a>
	        </div>
	        <br />
	        <br />
	        <br />
      	</div>
      	<div className="footer-next">
  			<div className="row">
	  			<div className="column">
	  				<h6>Previous project</h6>
	      			<h4 className="padding-top-05em">
		      			<Link to="/project-action-time">Action Time</Link>
	      			</h4>
	      		</div>
	  			<div className="column">
	      			<h6>Next project</h6>
	      		    <h4 className="padding-top-05em">
		      			<Link to="/project-big-talk">Big Talk</Link>
	      		    </h4>
	      		</div>
      		</div>
      	</div>
      </div>
    )
  }
}


export default SQProjectPage;







