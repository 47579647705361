import React from "react";
import PropTypes from "prop-types";
import TPSketch from '../img/toiletprojection_sketch.png';
import TPMadMapper from '../img/toiletprojection_madmapper.jpg';
import TPAnimation from '../img/toiletprojection_animation.mp4';
import TPInstallation from '../img/toiletprojection_installation.mp4';
import TPVideoPoster from '../img/toiletprojection_video.jpg';
import TPDocumentation1 from '../img/toiletprojection_documentation1.jpg';
import TPDocumentation2 from '../img/toiletprojection_documentation2.jpg';





export default class Modal extends React.Component {
  /*
  componentWillMount() {
    // add event listener for clicks
    document.addEventListener('click', this.handleClick, false);
  }

  componentWillUnmount() {
    // make sure you remove the listener when the component is destroyed
    document.removeEventListener('click', this.handleClick, false);
  }

  // fat arrow function binds 'this' to class when called by the listener
  // otherwise you'd need 'this.handleClick.bind(this)' in the constructor
  handleClick = e => {
      document.getElementById("backdrop").onclick = function(){
        alert("Hey!");
      };
  }
  */

  onClose = e => {
    this.props.onClose && this.props.onClose(e);
    document.getElementById("backdrop").style.display = "none";
    document.getElementById("footer-light").style.display = "block";
  };
  render() {
    if (!this.props.show) {
      return null;
    }
    document.getElementById("backdrop").style.display = "block";
    document.getElementById("footer-light").style.display = "none";

    /*
      document.getElementById("backdrop").onclick = function(){
        alert("Hey!");
        //this.onClose();              
      };
    */
    
    return (
      /*
      document.getElementById("backdrop").onclick = function(){
        alert("Hey!");
        this.setState({ showCKModal: false });           
      },
      */
      //{this.state.show && <div id='modal' onClick={(e) => this.onClose(e)}>
      <div className="modal" id="modal">

        <div className="content">
        	<div className="project-text padding-top-2em">
        		<h2>Projection mapping my bathroom</h2>
            <a className="close" onClick={this.onClose}></a>
        	</div>
	        <div className="project-text">
		        <p className="padding-top-1em">An indoor projection mapping featuring my toilet (and a few floor tiles). Inspired by the work of animator Andrew Vucko, I wanted to try and create a surreal dream-like scene using my bathroom as a backdrop. The animation was created in After Effects and mapped to the interior using MadMapper. Music: 'Yu City Kamata' by Dan Boden.</p>
	        </div>
	        <img className="padding-top-3em" src={TPSketch} alt="Sketches showing my initial concept" />
          <img className="padding-top-3em" src={TPMadMapper} alt="A screenshot of the projection mapping setup in MadMapper" />
	         <video className="project-text-half padding-top-3em" playsinline="true" webkit-playsinline="true" autoPlay loop muted>
    			   <source src={TPAnimation} alt="A screen recording of the After Effects animation" type='video/mp4' />
			     </video>
           <video className="project-text-half padding-top-3em" style={{cursor: "pointer"}} playsinline="true" webkit-playsinline="true" controls loop poster={TPVideoPoster}>
             <source src={TPInstallation} alt="A video of the actual interior mapping" type='video/mp4' />
           </video>
          <img className="project-text-half padding-top-3em" src={TPDocumentation1} alt="A photo documening the process" />
          <img className="project-text-half padding-top-3em" src={TPDocumentation2} alt="A screenshot of the projection mapping setup in MadMapper" />
	        <div className="project-text">
	           <br />
        		<br />
        		<a className="button" onClick={this.onClose}>Close</a>
        	</div>
        </div>
        <br />
        <br />
      </div>

    );
  }
}
Modal.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired
};


