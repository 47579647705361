import React from 'react';
import { Link } from 'react-router-dom';
import HRTopView from '../img/houserules_topview.jpg';
import HROverview from '../img/houserules_overview_.mp4';
import HRLogo from '../img/houserules_logo.png';
import HRInterview from '../img/houserules_interview.jpg';
import HRInterviewMap from '../img/houserules_interviewnotes.jpg';
import HRPainsGainsMap from '../img/houserules_painsgainsmap.png';
import HRSketchSessions from '../img/houserules_sketchsessions.jpg';
import HRPolarisingPrototypes from '../img/houserules_polarisingprototypes.jpg';
import HRKeynote from '../img/houserules_keynote.jpg';
import HRLowFidelityNotes from '../img/houserules_lowfidelitynotes_.jpg';
import HRMidFidelityNotes from '../img/houserules_midfidelitynotes_.jpg';
import HRPaperPrototypes from '../img/houserules_paperprototypes.jpg';
import HRLinearFlow from '../img/houserules_linearflow.jpg';
import HRSystemDiagram from '../img/houserules_systemdiagram.jpg';
import HRStyleTiles from '../img/houserules_styletiles.gif';
import HRBranding from '../img/houserules_branding.jpg';
import HRApprovingRulesInteraction from '../img/houserules_approvingrules.mp4';
import HRSuggestingTasksInteraction from '../img/houserules_suggestingtasks.mp4';
import HRReviewingPlansInteraction from '../img/houserules_reviewingplans.mp4';
import HRApprovingRulesFlow from '../img/houserules_approvingrules.png';
import HRSuggestingTasksFlow from '../img/houserules_suggestingtasks.png';
import HRReviewingPlansFlow from '../img/houserules_reviewingplans.png';









class HRProjectPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scrolled: 0
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollProgress);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollProgress);
  }

  scrollProgress = () => {
    const scrollPx = document.documentElement.scrollTop;
    const winHeightPx =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrolled = `${scrollPx / winHeightPx * 100}%`;

    this.setState({
      scrolled: scrolled
    });
  };

  render() {
    const progressContainerStyle = {
      background: "transparent",
      height: "2px",
      position: "relative",
      top: 0,
      left: 0,
      width: "100vw",
      zIndex: 99
    };

    const progressBarStyle = {
      height: "2px",
      background: "#000000",
      width: this.state.scrolled
    };

    return (
      <div>
      	<div className="project-title">
	        <div id="title">A Productivity App for Shared Households</div>
	        <Link to="/"><div className="close-project"></div></Link>
	        <div className="progress-container" style={progressContainerStyle}>
	          <div className="progress-bar" style={progressBarStyle} />
	        </div>
	    </div>
        <div id="project-page">
	    	<div className="project-text padding-top-2em">
		        <h1 className="padding-top-1em">How might we help roommates work together to plan their day to day communal living?</h1>
		    </div>
		    <div className="project-text padding-top-2em">
		        <div className="project-caption-half padding-top-1em">
			        <h3>Context</h3>
			        <h5 className="padding-top-1em">House Rules is a communal home management system which helps roommates work together to plan their day-to-day living. Designed during the Communication Design: Major Studio II program at Parsons.</h5>
		        </div
		        ><div className="project-caption-half padding-top-1em">
			        <h3>Role</h3>
			        <h5 className="padding-top-1em">UX research including interviews, field observations, journey mapping and usability testing. UX/UI development including information architecture, branding and interaction design.</h5>
		        </div>
		    </div>
	        <img className="padding-top-3em" src={HRTopView} alt="A top view of screens from the House Rules mobile app" />		    
		    <div className="project-text padding-top-3em">
		        <h2>The problem</h2>
		        <p className="padding-top-1em">For older generations, sharing a living space with strangers is something that only happens in college. However in this day and age, particularly for those living in large metropolitan cities, cohabiting with roommates has almost become the norm. A recent study reported that around 40 percent of renters in New York are living in what Zillow calls “doubled up households”, where at least two working-age, unmarried or un-partnered adults are residing harmoniously (or inharmoniously) together.</p>
		        <p className="padding-top-1em">Communal living can be tough, especially when the roommates in question aren’t already familiar with each other’s habits and quirks. A lack of communication about household issues can lead to quiet resentment, or even full-blown meltdowns. With so many mobile apps geared toward helping people find the perfect roommate, could there be a digital solution to improving relations with the roommates you already have?</p>
	        </div>
		    <div className="project-text padding-top-3em">
		        <h2>The solution</h2>
	        </div>
	        <img className="project-text-fourth padding-top-2em" src={HRLogo} alt="The House Rules logo" />		    
	        <div className="project-text-two-thirds">
	        	<h4 className="padding-top-1em">House Rules is a communal home management system which helps roommates work together to plan their day-to-day living.</h4>
		        <p className="padding-top-1em">Rules are collaborative and flexible, allowing everyone to voice their opinion. Tasks and events are transparent and easily organised using a shared house agenda. You’ll wonder how you ever lived (communally) without it!</p>
		        <p className="padding-top-1em">The key screens below are the result of several rounds of research, prototyping and user testing. Although there are some features that have yet to be integrated, this MVP is a framework for further experimentation, based on evidence, data and real insights.</p>
	        </div>
	        <video className="padding-top-3em" playsinline="true" webkit-playsinline="true" autoPlay loop muted>
    			<source src={HROverview} alt="An overview of the app's key features" type='video/mp4' />
			</video>
	        <div className="project-text-half padding-top-3em">
		        <h2>Understanding cohabitation</h2>
		        <p className="padding-top-1em">In order to come up with a solution, I first needed to unpack the problem. Initial desk research into primary and analogous domains revealed that, whilst there were many apps and websites dedicated to helping people find a roommate, there were very few that offered people support past the moving-in stage. The lack of competitors in the market spurred on my investigation, and I decided to focus on qualitative research in order to discover the ‘why’ behind people’s actions.</p>
		        <p className="padding-top-1em">Interviewing potential users who were currently living or had previously lived with roommates would help me dig deeper into people's behaviour and aspirations. To help steer conversations in the right direction, I wrote an interview guide with open-ended questions about user's living environments, their social needs, and their relationship with their roommates. I spoke to 7 users in total; 4 students and 3 working professionals between the ages of 25 to 55, from various cities and cultural backgrounds.</p>
	        </div>
	        <img className="project-text-half padding-top-3em" src={HRInterview} alt="Interviewing a potential user about their roommate experiences" />
	        <div className="project-text padding-top-3em">
		        <h2>Identifying actionable insights</h2>
		        <p className="padding-top-1em">The next step was to synthesise and make sense of everything I’d heard during the interview process. Below are a few soundbites from the interviews I conducted. I was able to talk to 2 of my users in their own homes with their roommates present, which allowed me to contextually observe their living situations first hand. However, people tended to be less candid speaking whilst their roommates were present, and I learned the most about my user’s specific pain points when interviewing outside of the home.</p>
	        </div>
		    <br />	
	        <div className="project-text-half">
		    	<h4 className="padding-top-2em">“Suggesting house rules halfway through wasn’t smart - we should have established them from the start.”</h4>
		    	<h3 className="padding-top-1em">Lucie, student, 3 roommates</h3>
		    </div>
		    <div className="project-text-half">
		    	<h4 className="padding-top-2em">“Every so often we’ll say ‘Alright, this Saturday nobody’s doing anything - let’s all pitch in and clean together’.”</h4>
		    	<h3 className="padding-top-1em">Paul, consultant, 3 roommates</h3>
		    </div>
		    <div className="project-text-half">
		    	<h4 className="padding-top-2em">“One of my roommates bought a really expensive couch and wanted us all to split the cost, but not everyone agreed with that.”</h4>
		    	<h3 className="padding-top-1em">Ryan, engineer, 2 roommates</h3>
		    </div>
		    <div className="project-text-half">
		    	<h4 className="padding-top-2em">“One of the things we like doing is cooking together, it’s just finding the time to dedicate in our schedules to do it.”</h4>
		    	<h3 className="padding-top-1em">Nethra, student, 2 roommates</h3>
		    </div>
		    <br />
		    <br />		    
	        <img className="padding-top-3em" src={HRInterviewMap} alt="Arranging notes from interviews into a journey map" /> 
	        <div className="project-text padding-top-3em">
		        <p>Affinity mapping helped me to categorise quotes into specific areas, and made it easier to step back and summarise the general sentiment of my interviewees. Using a chronological 12 stage structure beginning with finding the perfect roommate and ending with the final moving-out phase, I created a user journey map illustrating the pains and gains of each phase of cohabitation. The most contentious issue areas were focused around establishing household protocols and rules, and maintaining household cleanliness and item replenishments.</p>
	        </div>
	        <img className="padding-top-3em" src={HRPainsGainsMap} alt="Mapping out pains and potential gains at each stage of the journey" /> 
	        <div className="project-text padding-top-3em">
		        <p>I then summarised the main issues I’d identified into small, specific insights. This led to the grouping of similar insights into larger thematic categories, along with written descriptions for each group. The entire synthesis process led me to uncover these four main opportunity areas, which I decided to hone in on for the following ideation phase:</p>
	        </div>
	        <div className="project-text-half padding-top-1em">
	        	<h3 className="padding-top-2em">Insight #1</h3>
		    	<h4 className="padding-top-1em">House rules should be set from the start, but flexible enough to adapt as the household grows.﻿</h4>
		    	<h5 className="padding-top-1em">- Roommates should be able to renegotiate house rules that they think are unfair</h5>
		    	<h5>- Unspoken rules and verbal agreements are harder to reference than written ones</h5>
		    </div>
		    <div className="project-text-half padding-top-1em">
	        	<h3 className="padding-top-2em">Insight #2</h3>
		    	<h4 className="padding-top-1em">Discussing and tracking household items and tasks help roommates to settle any potential disputes.</h4>
		    	<h5 className="padding-top-1em">- Nobody likes chores, but keeping a record encourages everyone be more responsible</h5>
		    	<h5>- There should ideally be a full house consensus when splitting the cost of house purchases</h5>
		    </div>
		    <div className="project-text-half padding-top-1em">
	        	<h3 className="padding-top-2em">Insight #3</h3>
		    	<h4 className="padding-top-1em">Roommates should be kept aware of household plans for courtesy and accountability.</h4>
		    	<h5 className="padding-top-1em">- It can be unsettling when roommates are unaware that there are guests in the house</h5>
		    	<h5>- Plans to host long-term guests (such as family visiting) should be clearly communicated</h5>
		    </div>
		    <div className="project-text-half padding-top-1em">
	        	<h3 className="padding-top-2em">Insight #4</h3>
		    	<h4 className="padding-top-1em">Dedicating time to socialise and share common interests is the key to building roommate bonds.</h4>
		    	<h5 className="padding-top-1em">- Roommates find it easier to get along when they’re aware of each other’s preferences</h5>
		    	<h5>- Roommates often bond over shared activities such as cooking or eating out together</h5>
		    </div>
		    <div className="project-text padding-top-3em">
		        <h2>The ideation stage</h2>
		    </div>
	        <div className="project-text-half padding-top-2em">
		        <p>Using my actionable insights as ‘How Might We’ ideation prompts, I decided to hold sketch sessions with a few potential users in order to get their take on features they might find helpful. Despite most users not being from a creative background, I received positive feedback about how much fun it was to participate, and the sessions generated lots of interesting ideas which I later used to kickstart my prototyping process.</p>
		        <p className="padding-top-1em">These sketches shown are responses to the prompt: 'How might we allow roommates to discuss and track their household purchases? What would be the best way for roommates to log existing and propose new shared purchases, big or small?'</p>		        
	        </div>
	        <img className="project-text-half padding-top-3em" src={HRSketchSessions} alt="Sketch responses to an ideation prompt about discussing household purchases" />
	        <div className="project-text-half padding-top-3em">
		        <p>My first round of low-fidelity prototypes were simple hand-drawn sketches of some of the most radical ideas we’d generated. For each opportunity area, I tested a minimum of two ‘polarising’ prototypes with 3 potential users. Polarising prototypes are a good way of figuring out if something is ‘good' or ‘bad', as their divisive nature inspires instinctive feedback.</p>	        
		        <p className="padding-top-1em">These sketches shown are two very different ways of discussing and tracking household purchases, inspired by ideas generated in the sketch sessions. The winning option was a Tinder-style app where users could upload images of shared items they wanted to purchase, and swipe left or right on other items that their roommates had suggested.</p>	 		        
	        </div>
	        <img className="project-text-half padding-top-3em" src={HRPolarisingPrototypes} alt="Getting user feedback on two polarising prototypes about household purchases" />
	        <div className="project-text-half padding-top-3em">
		        <p>Following this initial feedback I did another round of low fidelity prototyping, this time digitising my ideas using Keynote instead of pen and paper. Designing in presentation software such as Keynote or Powerpoint restricts you from adding too many unecessary design details, which may end up distracting users from the overall concept.</p>
		        <p className="padding-top-1em">For the second round of testing with 4 potential users, I created a form for them to jot down any notes or thoughts about the prototypes I showed them.  The questions I asked were much more specific, as I wanted to make sure they understood the interface.</p>	 		        
	        </div>
	        <img className="project-text-half padding-top-3em" src={HRKeynote} alt="Testing Keynote prototypes with a potential user" />
	        <img className="padding-top-3em" src={HRLowFidelityNotes} alt="Notes from my low fidelity user testing" />     
	        <br />
		    <div className="project-text padding-top-3em">
		        <h2>Testing, iterating, testing, iterating…</h2>
		    </div>
	        <div className="project-text-half padding-top-2em">
		        <p>The next phase of mid fidelity prototypes were created in Sketch, incorporating more detailed UI elements and specific user interaction ideas. I began to think about the way users would navigate between different screens and how information would be displayed on tap or swipe.  Whilst still limiting colour, I added more precision to the user interface; testing out different icons and user inputs such as sliders and tabs.</p>
		        <p className="padding-top-1em">I followed a highly iterative design process, sharing each new user flow with at least one potential user and synthesising their feedback in order to improve upon the previous design. I wanted to minimise prep work by keeping things as low tech as possible until the high fidelity stage, so I stuck with paper prototypes for my mid fidelity user testing.</p>		        
	        </div>
	        <img className="project-text-half padding-top-3em" src={HRPaperPrototypes} alt="Testing paper prototypes with a potential user" />	        
	        <div className="project-text padding-top-3em">
		        <p>One important feature I wanted to trial at this stage was the idea of having an infinite scroll of household-related content, similar to a social media feed. I represented this idea on paper by printing the screens in full and cutting out a ‘phone’ frame to demonstrate how the content would load continuously and what would be visible above the fold.</p>
		        <p className="padding-top-1em">These mid fidelity prototypes mostly gave me UX-focused navigational feedback, which helped me to figure out the overall architecture of the app whilst working in tandem on the specifics of the screens. Users seemed to like the division of household tasks into separate rooms, but many found the names of the navigational sections at the bottom somewhat confusing. There was also some debate about classifying household ‘supplies' and furniture 'items' as separate categories, so as a result I decided to merge the two into a general item category. </p>	
	        </div>
	        <img className="padding-top-3em" src={HRMidFidelityNotes} alt="Notes from my mid fidelity user testing" />     
		    <div className="project-text padding-top-3em">
		        <h2>System architecture</h2>
		    </div>
	        <div className="project-text-half padding-top-2em">
		        <p>Working in parallel with my mid fidelity prototypes, I developed the task flows of the app; illustrating how the user could navigate their way through the application, from the moment of download onwards. Similarly to my prototyping process, I began illustrating the journey with initial low fidelity sketches; jotting down the user’s linear actions using sketches and basic descriptions.</p>
		        <p className="padding-top-1em">I initially grouped the user’s actions into three sections; Onboarding, Planning and Living.  ‘Onboarding’ referred to the process of creating an account and profile, ‘Planning’ referred to the creation of rules and tasks, and ‘Living’ referred to day-to-day activity like monitoring your calendar, chatting with roommates, etc.</p>		        
	        </div>
	        <img className="project-text-half padding-top-3em" src={HRLinearFlow} alt="Sketching out a linear user flow" />	  
	        <div className="project-text padding-top-3em">
		        <p>I shared these sketches with 2 potential users and as I previously mentioned, the navigational sections were not very easily understood. I ended up reorganising the categories into a Rules section, an Agenda section and a Mates section.</p>
		        <p className="padding-top-1em">In the ‘Rules' section, users can propose new household rules, tasks and items to buy, and review their other roommates suggestions. In the ‘Agenda' section, users can take a look at the household’s shared to-do list by day or week, as well as theirs and other roommates individual calendars. In the ‘Mates' section, users can plan social events together, post messages in specific household channels, and access roommate profiles with health information, sleep habits and likes/dislikes.</p>	
	        </div>
	        <img className="padding-top-3em" src={HRSystemDiagram} alt="A system diagram illustrating how screens link together" />     
		    <div className="project-text padding-top-3em">
		        <h2>Visual experimentation</h2>
		    </div>
	        <div className="project-text-half padding-top-2em">
		        <p>The final stage was to develop and implement a visual system to take the app from medium to high fidelity. From the very beginning of the project, I had the idea of naming the app ‘House Rules’ and emphasising the gaming reference with rounded playing cards and fun bright colours.</p>
		        <p className="padding-top-1em">However during a brand workshop I began to explore other visual themes, and I came up with an alternative concept named ‘Blueprint’ which would have a more architectural look and feel, darker colours and sharper corners.</p>		        
	        </div>
	        <img className="project-text-half padding-top-3em" src={HRStyleTiles} alt="Polarising style tiles to test with users" />	  
	        <div className="project-text padding-top-3em">
		        <p>I shared the ’House Rules’ and ‘Blueprint' style tiles with potential users for feedback, and the final result was a hybrid of both; a two-toned card based design with hard edges and rounded buttons. Each communal room in the household has its own colour scheme, making it easy to see at a glance which tasks need doing in what room.</p>        
	        </div>
	        <img className="padding-top-3em" src={HRBranding} alt="House Rules visual identity and design guide" />	  
	        <div className="project-text padding-top-3em">
		        <h2>The hero flows</h2>
		    </div>
		    <div className="project-text-half">
		    	<h3 className="padding-top-2em">Approving Rules</h3>
		    	<h4 className="padding-top-1em">House rules should be set from the start, but flexible enough to adapt as the household grows.</h4>
		        <p className="padding-top-2em">House Rules allows roommates to decide collaboratively on what the rules of the house should be. Whether you’re new to the game or a seasoned player, everyone gets the chance to voice their opinions and challenge old customs that might be outdated or unfair.</p>
		        <p className="padding-top-1em">Roommates can swipe right to agree with a suggested rule, or swipe left to disagree. Rules remain pending until the entire household agrees with the decision, which helps to keep everyone involved and accountable.</p>		        
	        </div>
		    <video className="project-text-half padding-top-3em" playsinline="true" webkit-playsinline="true" autoPlay loop muted>
    			<source src={HRApprovingRulesInteraction} alt="A recording of the rules approval interaction" type='video/mp4' />
			</video>
	        <img className="padding-top-2em" src={HRApprovingRulesFlow} alt="Showing the user flow of the rules approval process" />     
	        <div className="project-text-half">
		    	<h3 className="padding-top-2em">Suggesting Tasks</h3>
		    	<h4 className="padding-top-1em">Discussing and tracking household items and tasks help roommates to settle any potential disputes.</h4>
		        <p className="padding-top-2em">Just like house rules, regular chores like cleaning the bathroom or emptying the trash need to be universally agreed upon, as conflicts are likely to occur when things are left undone. Household purchases should also be recorded so that costs are split equally, whether it's dish soap for the kitchen or a new couch for the living room.</p>
		        <p className="padding-top-1em">House Rules allows roommates to suggest new tasks to complete or new items to purchase, allocate who is responsible for getting it done (including ‘all pitch in’ and ‘alternating’ options), and set a time/date for the job to be completed (either weekly, monthly or one-off events).</p>		        
	        </div>
		    <video className="project-text-half padding-top-3em" playsinline="true" webkit-playsinline="true" autoPlay loop muted>
    			<source src={HRSuggestingTasksInteraction} alt="A recording of the task suggestion interaction" type='video/mp4' />
			</video>
	        <img className="padding-top-2em" src={HRSuggestingTasksFlow} alt="Showing the user flow of the task suggestion process" /> 
	        <div className="project-text-half">
		    	<h3 className="padding-top-2em">Reviewing Plans</h3>
		    	<h4 className="padding-top-1em">Roommates should be kept aware of household plans for courtesy and accountability.</h4>
		        <p className="padding-top-2em">House Rules automatically creates a shared agenda for the household so that everyone knows what’s happening in their home. Roommates can view their own, someone else’s, or the entire house's agenda for the day or week. Plans can be filtered by task, item or event, and roommates can choose to receive reminders of when things are due.</p>
		        <p className="padding-top-1em">Whether roommate X has her girlfriend staying over on Friday, or roommate Y needs to pay the gas bill on Monday, everyone in the house is made aware and accountable. Completed tasks, items and events are always available for future reference, helping to negate arguments over who did what.</p>		        
	        </div>
		    <video className="project-text-half padding-top-3em" playsinline="true" webkit-playsinline="true" autoPlay loop muted>
    			<source src={HRReviewingPlansInteraction} alt="A recording of the agenda review interaction" type='video/mp4' />
			</video>
	        <img className="padding-top-2em" src={HRReviewingPlansFlow} alt="Showing the user flow of the agenda review process" /> 
	        <div className="project-text padding-top-3em">
		        <h2>Future iterations</h2>
		        <p className="padding-top-1em">My next step is to continue working on task flows for the Mates section; including creating and viewing roommate profiles, private and group messaging in household forums, and event suggestions for group activities based on roommates shared interests.</p>
		        <p className="padding-top-1em">During a presentation of my high fidelity designs to a group of designers from Redesign Health, I received some critique about the separation of tasks and items into individual rooms. For future iterations, I am considering merging the sections into a general tab and testing to see the response.</p>	
	        </div>
	        <br />
	        <br />
	        <br />
      	</div>
      </div>
    )
  }
}


export default HRProjectPage;







