import React from 'react';
import Blog20200225StyleTile1 from '../img/blog_20200225_styletile1.jpg';
import Blog20200225StyleTile2 from '../img/blog_20200225_styletile2.jpg';











class Blog20200225 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scrolled: 0
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollProgress);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollProgress);
  }

  scrollProgress = () => {
    const scrollPx = document.documentElement.scrollTop;
    const winHeightPx =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrolled = `${scrollPx / winHeightPx * 100}%`;

    this.setState({
      scrolled: scrolled
    });
  };

  render() {
    const progressContainerStyle = {
      background: "transparent",
      height: "2px",
      position: "relative",
      top: 0,
      left: 0,
      width: "100vw",
      zIndex: 99
    };

    const progressBarStyle = {
      height: "2px",
      background: "#000000",
      width: this.state.scrolled
    };

    return (
      <div>
        <div className="project-title">
          <div id="title">User Testing Style Tiles</div>
          <div className="progress-container" style={progressContainerStyle}>
            <div className="progress-bar" style={progressBarStyle} />
          </div>
      </div>
        <div id="project-page">
          <div className="project-text padding-top-3em">
            <br />
            <br />
              <h3>2020-02-25</h3>
              <p className="padding-top-1em">Before jumping right into high fidelity designs, I first wanted to test a couple of polarising prototypes to see which visual direction would be best suited for the app. I created these two style tiles illustrating different colour schemes, icon styles and font choices, and I asked three potential users to give me their feedback on what they liked/didn't like.</p>
          </div>      
          <img className="padding-top-3em" src={Blog20200225StyleTile1} alt="The first style tile with a mindmap theme" />
          <div className="project-text padding-top-2em">
              <p className="padding-top-1em">This first style tile has a 'mindmap' sort of vibe. The idea is that these sausage-shaped talking point bubbles can easily be linked with other talking points to form a cohesive thought process, which would then become a Big Talk. I used a deep blue/purple gradient as a background, as it felt quite dreamy and inspirational. The typography uses a mix of sans-serif 'Didact Gothic' for body copy and slab-serif 'Scope One' for headings. One of the features I want to include in the app is the ability to switch between light and dark modes, for when you're practising your talk during the day or late at night. The light mode in this style tile simply fades the dark gradient background to 5% opacity and inverts the colours of the bubbles.</p>           
          </div>
          <img className="padding-top-3em" src={Blog20200225StyleTile2} alt="The second style tile with a modular theme" />
          <div className="project-text padding-top-2em">
              <p className="padding-top-1em">My alternative style tile has a much more modular and functional tone. I chose a neutral shade of greyish-blue as the primary colour scheme, and decided to stick to just the one sans-serif font, 'IBM Plex Sans', in varying weights and character cases. Instead of 'mindmap'-style thought bubbles, this option has a 'pill'-like design. Each Big Talk circle expands when tapped, into segments which represent the different talking points within that talk. As the primary greyish-blue colour is a sort of medium-toned hue, the distinction between light and dark modes is less apparent than in the previous style tile.</p>           
          </div>
          <div className="project-text padding-top-2em">
              <p className="padding-top-1em">The main feedback that I got from these polarising prototypes was that the colour schemes were not exciting or calming enough to help prepare people for the act of public speaking. Out of the two styles of talking point bubbles, the second 'pill'-like option was most popular due to the expanding interaction being the easiest to understand. The users I tested with seemed to like the 'progress' stroke that I added around the bubbles in option one, which are designed to indicate how much you've practised that particular talk. For next week's midterm review, I'm planning to decide on a final style of branding to apply to the high fidelity prototype that I'll be presenting to my professors. Wish me luck!</p>           
          </div>
          <br />
          <br />
          <br />
      </div>
      </div>
    )
  }
}


export default Blog20200225;







