import React from 'react';
import Blog20191006IncorrectBPM from '../img/blog_20191006_incorrectbpm.mp4';
import Blog20191006ARjsWebRecording from '../img/blog_20191006_arjswebrecording.mp4';
import Blog20191006PulseSensor from '../img/blog_20191006_pulsesensor.jpg';
import Blog20191006ARjsCode from '../img/blog_20191006_arjscode.jpg';









class Blog20191006 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scrolled: 0
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollProgress);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollProgress);
  }

  scrollProgress = () => {
    const scrollPx = document.documentElement.scrollTop;
    const winHeightPx =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrolled = `${scrollPx / winHeightPx * 100}%`;

    this.setState({
      scrolled: scrolled
    });
  };

  render() {
    const progressContainerStyle = {
      background: "transparent",
      height: "2px",
      position: "relative",
      top: 0,
      left: 0,
      width: "100vw",
      zIndex: 99
    };

    const progressBarStyle = {
      height: "2px",
      background: "#000000",
      width: this.state.scrolled
    };

    return (
      <div>
        <div className="project-title">
	        <div id="title">Fourth Technical Prototype</div>
	        <div className="progress-container" style={progressContainerStyle}>
	          <div className="progress-bar" style={progressBarStyle} />
	        </div>
	    </div>
        <div id="project-page">
	        <div className="project-text padding-top-3em">
  	        <br />
  	        <br />
  	        	<h3>2019-10-06</h3>
  		        <p className="padding-top-1em">Last Thursday I had to present an idea in front of two crit judges for one of my class projects. Myself and my team had completely changed the theme/slides of our presentation 30 mins before, and as a result, I was VERY nervous. All I had to do was introduce our team and give a brief introduction to the idea, but my heart was pounding so fast that I could look down and see the fabric of my shirt quivering. I knew that the reason for these extreme nerves was my lack of preparation, but at that point what can you do! We obviously should’ve prepared more thoroughly, but it was too late for second chances.</p>
          </div>
          <div className="project-text padding-top-1em">
              <p className="padding-top-1em">While my heart was thumping I started thinking about this thesis project for obvious reasons, and it actually eased the pounding a bit to concentrate on something different. I also tried breathing deeply, which is my usual go-to method. I think it helped a bit. But as soon as I started thinking about the presentation again the crazy thumping returned, and as our turn neared (unfortunately we were last to present) it was just out of control and felt ridiculous; I was convinced that as I approached the front everyone would notice my chest thumping and my body shaking. In that period of time before standing up to present, I really wished that I had something that could take the edge off somehow.</p>              
          </div>
          <div className="project-text padding-top-1em">          
              <p className="padding-top-1em">On Saturday, I decided to begin making a prototype to test how someone might calm their nerves by visualising their heart rate decreasing. This is not a novel invention - many people have created digital products to help reduce anxiety such as Healium and Solace by Oscar de la Hera. However, I couldn’t find any augmented reality products which specifically target those glossophobia nerves that you tend to get right before a presentation. I think this prototype could possibly be developed further, and integrated into a preparatory public speaking ‘system’.</p>
          </div>
          <img className="padding-top-3em" src={Blog20191006PulseSensor} alt="The Arduino pulse sensor" />            
          <div className="project-text padding-top-2em">          
              <h2 className="padding-top-1em">What is it?</h2>   
              <p className="padding-top-1em">A technical prototype to test connecting a physical pulse sensor with an augmented reality object. I am interested in whether being able to visualise your heart-rate reducing can in turn reduce anxiety.</p>   
              <h2 className="padding-top-1em">Why should people care?</h2>   
              <p className="padding-top-1em">Studies show that biofeedback, the idea of becoming more aware of your body's responses when you're stressed and anxious, can promote relaxation. Biofeedback types can include brain waves, sweat gland activity and heart-rate.</p>               
              <h2 className="padding-top-1em">What goals does this prototype serve?</h2>   
              <p className="padding-top-1em">- Gives the user their heart-rate in beats per minute</p>
              <p className="padding-top-1em">- Allows the user to see that data visualised in augmented reality</p>
              <h2 className="padding-top-1em">What should the audience know?</h2>   
              <p className="padding-top-1em">This is a feature that would be best used before a big event, such as a speech or job interview, to help ease the user’s nerves. As it can be accessed quickly through any web browser, it is perfect for last-minute stress relief.</p>     
              <h2 className="padding-top-1em">What are the constraints and/or specifications?</h2>   
              <p className="padding-top-1em">The prototype requires a marker to activate the AR experience. This is something that could perhaps be incorporated into a preparatory public speaking ‘kit’, containing physical items such as cue cards, stress balls, etc.</p>              
          </div>
          <video className="padding-top-3em" playsinline="true" webkit-playsinline="true" autoPlay loop muted>
            <source src={Blog20191006IncorrectBPM} alt="Showing the incorrect BPM reading from the Arduino pulse sensor" type='video/mp4' />
          </video>
          <div className="project-text padding-top-2em">          
              <p className="padding-top-1em">As you can see above, the prototype uses an Arduino pulse sensor to detect my pulse in beats per minute (which is obviously very wrong - I’m still trying to configure this!) and sends the data to a local server using Johnny-Five, socket.io and express. I was then able to style the page (nothing crazy for now, just white text on a black background) using HTML/CSS. For the next part of this prototype idea, I incorporated AR.js into my HTML/CSS page. AR.js is an efficient and highly accessible solution that uses A-Frame to render augmented reality on the web. It runs 100% in your web browser with no separate app to install. You can’t do as much with it as you can with Unity/Vuforia, but I think it’s pretty cool that it works without having to download anything extra.</p>
          </div>
          <video className="padding-top-3em" playsinline="true" webkit-playsinline="true" autoPlay loop muted>
            <source src={Blog20191006ARjsWebRecording} alt="A video recording of the AR.js prototype" type='video/mp4' />
          </video>
          <div className="project-text padding-top-2em">          
              <p className="padding-top-1em">I created a simple sphere shape with the beats per minute rate in the centre to show the basic mechanism of my idea. Ideally instead of a sphere, the 3D entity could be a beating heart or some other model that animates depending on the heart rate. Unfortunately, I still haven’t managed to connect the code from the Arduino to the AR.js model - the BPM rate is currently showing in the console but not rendering in the a-frame. Hopefully in time I’ll be able to figure out a solution. For now, I would like to share this concept with other people and see if this might be an interesting route to continue with.</p>
          </div>
          <img className="padding-top-3em" src={Blog20191006ARjsCode} alt="The AR.js and Arduino code together, but not as one :(" />  
	        <br />
	        <br />
	        <br />
	    </div>
      </div>
    )
  }
}


export default Blog20191006;







