import React from 'react';
import { Link } from 'react-router-dom';
import SPSelfie from '../img/sony_ghosts.jpg';
import SPSingapore from '../img/sony_singapore.jpg';
import SPSketches from '../img/sony_sketches.jpg';
import SPFilters from '../img/sony_filters.jpg';
import SPGif1 from '../img/sony_gif_1.gif';
import SPGif2 from '../img/sony_gif_2.gif';
import SPGif3 from '../img/sony_gif_3.gif';
import SPGif4 from '../img/sony_gif_4.gif';
import SPGif5 from '../img/sony_gif_5.gif';
import SPGif6 from '../img/sony_gif_6.gif';









class SPProjectPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scrolled: 0
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollProgress);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollProgress);
  }

  scrollProgress = () => {
    const scrollPx = document.documentElement.scrollTop;
    const winHeightPx =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrolled = `${scrollPx / winHeightPx * 100}%`;

    this.setState({
      scrolled: scrolled
    });
  };

  render() {
    const progressContainerStyle = {
      background: "transparent",
      height: "2px",
      position: "relative",
      top: 0,
      left: 0,
      width: "100vw",
      zIndex: 99
    };

    const progressBarStyle = {
      height: "2px",
      background: "#000000",
      width: this.state.scrolled
    };

    return (
      <div>
      	<div className="project-title">
	        <div id="title">GIFs and Filters for Sony Pictures</div>
	        <Link to="/"><div className="close-project"></div></Link>
	        <div className="progress-container" style={progressContainerStyle}>
	          <div className="progress-bar" style={progressBarStyle} />
	        </div>
	    </div>
        <div id="project-page">
        	<div className="project-text-half">
		    	<div className="padding-top-2em">
			        <h1 className="padding-top-1em">How might we utilise the power of social media for movie promotion?</h1>
			    </div>
			    <div className="padding-top-2em">
			        <div className="project-caption padding-top-1em">
				        <h3>Context</h3>
				        <h5 className="padding-top-1em">Promotional social media assets optimised for Snapchat and Giphy, designed for Sony Pictures at Battenhall.</h5>
			        </div>
			        <div className="project-caption padding-top-2em">
				        <h3>Role</h3>
				        <h5 className="padding-top-1em">Graphic and motion design. Design team: Rebecca Gill Clarke, Aaron Griffiths, Andrea Esteban Martín.</h5>
			        </div>
			    </div>
			</div> 
			<img className="project-text-half padding-top-3em" src={SPSelfie} alt="Aerial view of the Ghostbusters Snapchat filter" />	  
		    <div className="project-text padding-top-3em">
		        <h2>Ghostbusters: Social marketing</h2>
		        <p className="padding-top-1em">Recognising the impact of viral marketing and PR, Sony Pictures came to us in search of a way to strategically promote their upcoming movie releases using social media as a medium. The message was delivered in the form of Snapchat filters, animated GIFs and memes ready to be shared. We ideated, designed and promoted social campaigns for several films, including the all-female reboot of Ghostbusters and animated comedy Sausage Party.</p>
	        </div>
	        <img className="padding-top-3em" src={SPSingapore} alt="An aerial photograph of the Ghostbusters event in Singapore" />  
	        <div className="project-text padding-top-3em">
		        <h2>Influencer outreach</h2>
		        <p className="padding-top-1em">Three upcoming movies Ghostbusters, Inferno and The Magnificent Seven were already gaining a lot of buzz online, and Sony wanted to step things up a notch by staging a three-day international press event in Singapore’s Marina Sands hotel.</p>
		        <p className="padding-top-1em">Along with the usual journalists and PR people, Sony also invited 15 influencers from the social media world, who they expected to capture the goings-on live via Snapchat, Instagram and Youtube. Sony wanted to launch three Snapchat geofilters during the event to help the influencers create branded content.</p>
	        </div>
	        <div className="project-text-half padding-top-3em">
		        <h2>Snapchat Geofilters</h2>
		        <p className="padding-top-1em">We began by sketching several basic ideas for the filters, later developing them into finalised graphics after feedback from the client. The marketing team shared movie assets with us such as fonts, backgrounds and character art, which we then used as a base to construct the final versions of the filters.</p>
		        <p className="padding-top-1em">It was important to bear in mind the less-than-ideal scenarios that the influencers might be snapping, such as people/things with really dark or super bright backgrounds. We also had to ensure that the text and imagery in the filter didn’t take up too much screen real estate, so as not to block the actual content.</p>
	        </div>
	        <img className="project-text-half padding-top-3em" src={SPSketches} alt="Initial sketches of filter ideas" />
	        <img className="padding-top-3em" src={SPFilters} alt="Three screenshots of the filters being used by influencers" />  
	        <div className="project-text padding-top-3em">
		        <h2>Record-breaking results</h2>
		        <p className="padding-top-1em">The conference was a huge success, with the Ghostbusters event breaking a new Guiness World Record for the 'Largest Gathering of People Dressed as Ghosts at a Single Venue’. The Snapchat filter was featured on Mashable, Buzzfeed and across many prominent influencer channels.</p>
		        <p className="padding-top-1em">The influencer’s online content generated much more audience growth than traditional movie marketing, particularly amongst Millennial and Gen Z audiences, and the entire event marked an important shift in the way movies would be launched in the future.</p>
	        </div>
	        <br />
	        <br />
	        <br />
	        <div className="project-text">
	        	<a className="button" href="https://mashable.com/2016/06/14/sony-youtubers-s/" target="_blank" rel="noopener noreferrer">See the press</a>
	        </div>
	        <br />
	        <br />
	        <div className="project-text padding-top-3em">
		        <h2>Sausage Party: Shareable content</h2>
		        <p className="padding-top-1em">We worked with Sony Pictures again a few months later for the launch of their animated comedy Sausage Party. They were looking for an interesting way to promote the movie’s upcoming release online, once again targeting young and social media savvy Gen Z audiences.</p>
		        <p className="padding-top-1em">The content had to be funny, relevant and topical, and in line with current events happening at the time. It also needed to be presented in a format that could be instantly shareable amongst friends.</p>
	        </div>
	        <img className="project-text-half padding-top-3em" src={SPGif1} alt="A GIF of carrots dancing to Diplo" />
	        <img className="project-text-half padding-top-3em" src={SPGif2} alt="A GIF of a potato being peeled whilst cursing" />
	        <div className="project-text padding-top-3em">
		        <h2>3D GIFs</h2>
		        <p className="padding-top-1em">We produced a series of 3D GIFs using clips from the movie intertwined with dynamic typography. The topics we focused on were relevant to the audience we were trying to capture, such as the presidential election and popular electronic music DJ Diplo.</p>	
		        <p className="padding-top-1em">We also based a few of the GIFs on popular memes, unconventional public holidays, and general ‘reaction' posts. All graphics were branded with the #sausagepartymovie hashtag to tie in with other promo material, and were uploaded to Giphy ready to be discovered and shared.</p>	
	        </div>
	        <img className="project-text-half padding-top-3em" src={SPGif3} alt="A GIF of a potato telling another character to stay away from her sausage, skank" />
	        <img className="project-text-half padding-top-3em" src={SPGif4} alt="A GIF of various food characters practicing archery" />
	        <div className="project-text padding-top-3em">
		        <h2>Infusing humour</h2>
		        <p className="padding-top-1em">As the GIFs were designed to go viral, and also due to the explicit nature of the movie itself, we were able to be as risque as we wanted with the content we produced. We created bold 3D lettering highlighting some of the most memorable phrases from the movie, animating the words in time with the character’s gestures.</p>
		        <p className="padding-top-1em">A few of the scenes, such as the Canada GIF, were composed from scratch using backgrounds and character art provided to us by Sony's marketing team. We used After Effects to put the movements together and created additional elements such as Frank’s wooden sign using Photoshop.</p>	
	        </div>
	        <img className="project-text-half padding-top-3em" src={SPGif5} alt="A GIF of food characters running to Canada after President Trump's election" />
	        <img className="project-text-half padding-top-3em" src={SPGif6} alt="A GIF of a mustard jar wishing everyone a happy salami day" />
	        <br />
	        <br />
	        <br />
      	</div>
      </div>
    )
  }
}


export default SPProjectPage;







